import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Layout from "../../components/layout/V2/Layout";
import AdCampaignsTab from "../../components/V2/AdCampaignsTab";
import OffersTab from "../../components/V2/OffersTab";

export default function PromotionsPage() {
  const location = useLocation();

  const [activeTab, setActiveTab] = useState("ads");
  const [showShareDialogue, setShowShareDialogue] = useState(false);
  const [operation, setOperation] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const tab = params.get("tab") || "ads";

    setActiveTab(tab);
  }, [location]);

  const showDialogueHandler = (bool, type) => {
    setShowShareDialogue(bool);
    setOperation(type);
  };
  return (
    <Layout
      headerTitle="Promotions"
      showDialogueHandler={showDialogueHandler}
      operation={operation}
      show={showShareDialogue}
    >
      <div className="relative overflow-y-auto min-h-screen scroll-smooth pt-[60px] pb-[120px] max-w-screen-xl container mx-auto flex flex-col">
        <ul
          className="nav nav-tabs secondary nav-justified flex flex-row list-none border-b-0 pl-0"
          id="tabs-tabJustify"
          role="tablist"
        >
          <li className="nav-item flex-grow text-start" role="presentation">
            <a
              href="#ads-tab"
              className={`nav-link w-full block font-bold text-sm leading-tight border-x-0 border-t-0 border-b-2 border-transparent px-6 py-5 hover:border-transparent hover:bg-gray-100 focus:border-transparent ${
                activeTab === "ads" ? "active" : ""
              }`}
              id="tabs-home-tabJustify"
              data-bs-toggle="pill"
              data-bs-target="#ads-tab"
              role="tab"
              aria-controls="ads-tab"
              aria-selected={activeTab === "ads"}
            >
              Ads
            </a>
          </li>
          <li className="nav-item flex-grow text-start" role="presentation">
            <a
              href="#offers-tab"
              className={`nav-link w-full block font-bold text-sm leading-tight border-x-0 border-t-0 border-b-2 border-transparent px-6 py-5 hover:border-transparent hover:bg-gray-100 focus:border-transparent ${
                activeTab === "offers" ? "active" : ""
              }`}
              id="tabs-profile-tabJustify"
              data-bs-toggle="pill"
              data-bs-target="#offers-tab"
              role="tab"
              aria-controls="offers-tab"
              aria-selected={activeTab === "offers"}
            >
              Offers
            </a>
          </li>
        </ul>
        <div
          className="tab-content flex-grow flex h-full"
          id="tabs-tabContentJustify"
        >
          <div
            className={`tab-pane fade mx-auto w-full ${
              activeTab === "ads" ? " show active" : ""
            } w-full`}
            id="ads-tab"
            role="tabpanel"
            aria-labelledby="tabs-home-tabJustify"
          >
            <AdCampaignsTab />
          </div>
          <div
            className={`tab-pane fade mx-auto w-full ${
              activeTab === "offers" ? " show active" : ""
            }`}
            id="offers-tab"
            role="tabpanel"
            aria-labelledby="tabs-offers-tabJustify"
          >
            <OffersTab />
          </div>
        </div>
      </div>
    </Layout>
  );
}
