import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useStateMachine } from "little-state-machine";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import LabelledInput from "../../components/forms/LabelledInput";
import Button from "../../components/forms/Button";
import {
  usePaymentContext,
  PAYMENT_REASONS,
} from "../../utils/Contexts/V2/PaymentContext2";
import { useSubscriptionContext } from "../../utils/Contexts/V2/SubscriptionContext";
import { resetStoreValue } from "../../utils/stateMachine";
import { updateSubscriptionPackage } from "../../utils/requests-v2";

export default function PromoCodePaymentsPage() {
  const [promoCode, setPromoCode] = useState("");

  const {
    handlePromoCodeAdsPayment,
    paymentFor,
    handleSubscriptionPromoCodePayment,
  } = usePaymentContext();

  const { selectedPlan, subscriptionPackages } = useSubscriptionContext();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { actions, state } = useStateMachine({ resetStoreValue });

  const [paymentReason, setPaymentReason] = useState(null);

  useEffect(() => {
    switch (paymentFor) {
      case PAYMENT_REASONS.ADS:
        setPaymentReason("Create Ad");
        break;
      case PAYMENT_REASONS.SUBSCRIPTION:
        setPaymentReason("Pay For Your Subscription");
        break;
      case PAYMENT_REASONS.CREDITS:
        setPaymentReason("Pay For Your Credits");
        break;
      default:
        setPaymentReason("Make Payment");
        break;
    }
  }, [paymentFor]);

  const navigate = useNavigate();

  const redirectTimeout = () =>
    setTimeout(() => navigate(`/payments/success?for=${paymentFor}`), 1000);

  useEffect(() => () => clearTimeout(redirectTimeout), []);

  async function handleSubmit(e) {
    e.preventDefault();

    setIsSubmitting(true);

    if (paymentFor === PAYMENT_REASONS.ADS) {
      handlePromoCodeAdsPayment({ promoCode })
        .then(async () => {
          actions.resetStoreValue("adJourney");
          actions.resetStoreValue("paymentInfo");

          toast.success("Ad created successfully");
          redirectTimeout();
        })
        .catch((error) => {
          console.error("Could not validate promo code", error);
          if (error.response?.data?.detail) {
            toast.error(error.response.data.detail);
          } else {
            toast.error("Something went wrong. Please try again");
          }
        })
        .finally(() => setIsSubmitting(false));
    } else if (paymentFor === PAYMENT_REASONS.SUBSCRIPTION) {
      const packageObject = subscriptionPackages.find(
        (p) => p.name === selectedPlan.plan
      );

      handleSubscriptionPromoCodePayment({ promoCode })
        .then(() =>
          updateSubscriptionPackage({
            packageId: packageObject.id,
            interval: selectedPlan.rate,
          })
            .then(() => {
              toast.success("Your subscription was successfully purchased");
              setTimeout(() => {
                navigate("/account/subscription");
              }, 2000);
            })
            .catch(() => {
              toast.error(
                "Could not purchase your subscription. Please try again"
              );
            })
        )
        .catch((error) => {
          console.log("Could not validate promo code", error);
          toast.error(error.response?.data?.detail || "Error");
        })
        .finally(() => setIsSubmitting(false));
    }
  }

  useEffect(() => {
    if (paymentFor === PAYMENT_REASONS.ADS && state.adJourney.stepsDone !== 3) {
      navigate("/ads/create");
    }
  }, [paymentFor]);

  return (
    <Layout
      header
      headerTitle={
        <div className="flex flex-col items-center justify-center">
          <p className="text-xs font-normal text-gray-500 text-center">
            {paymentReason}
          </p>
          <h1 className="text-base">Apply Promo Code</h1>
        </div>
      }
    >
      <div className="pt-20 h-screen max-w-screen-xl container mx-auto px-4">
        <h2>Promo Code</h2>

        <form className="my-4" onSubmit={handleSubmit}>
          <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
            <LabelledInput
              placeholder="Enter Promo Code"
              label="Enter Promo Code"
              name="promo_code"
              id="promoCodeInput"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
              rules={{ required: true }}
            />

            <Button type="submit">Apply</Button>
          </fieldset>
        </form>
      </div>
    </Layout>
  );
}
