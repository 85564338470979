import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { useOffer } from "../../utils/Contexts/V2/OfferContext";

export default function OfferOptions({
  offerId,
  setShowOfferOption,
  setShowDeleteConfirmationPrompt,
}) {
  const { offers } = useOffer();
  const deleteActionHandler = () => {
    setShowOfferOption(false);
    setShowDeleteConfirmationPrompt(true);
  };

  const selectedOffer = offers.filter(
    (item) => item.id.toString() === offerId.toString()
  );

  return (
    <div className="relative h-screen w-full flex flex-col justify-center items-center">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className="absolute h-screen w-full bg-blur" />
      <div className="absolute w-full bg-white pt-[1.62rem] px-5 py-3 w-[90%]">
        <h1 className="pb-6 font-semibold text-base">Offer Options</h1>
        <div className="flex flex-col justify-start items-start space-y-7 pb-4">
          <NavLink
            to={
              selectedOffer[0].is_active
                ? `/create-offers/select-customers/${offerId}`
                : `/edit-offers/${offerId}`
            }
            className="text-sm"
          >
            Edit
          </NavLink>
          <button
            type="button"
            className="text-sm"
            onClick={deleteActionHandler}
          >
            Delete
          </button>
          <button
            type="button"
            className="text-sm"
            onClick={() => setShowOfferOption(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

OfferOptions.defaultProps = {
  offerId: null,
};

OfferOptions.propTypes = {
  offerId: PropTypes.string,
  setShowDeleteConfirmationPrompt: PropTypes.func.isRequired,
  setShowOfferOption: PropTypes.func.isRequired,
};
