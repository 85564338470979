/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import InfoIcon from "../assets/icons/info.svg";
import LeftArrow from "../assets/icons/left-arrow.png";
import Banks from "../utils/NigerianBanks.json";

export default function NigerianBankSetup() {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      bankName: "",
      bankAccNo: "",
      bankHolderName: "",
    },
  });

  const navigate = useNavigate();

  function onSubmit(data) {
    console.log(data);

    navigate("/");
  }

  function goBack() {
    navigate(-1);
  }

  return (
    <div className="flex flex-col items-center h-screen container max-w-[400px] mx-auto md:py-12 px-4">
      <div className="flex items-center justify-start w-full py-4">
        <button type="button" className="mr-8" onClick={goBack}>
          <img src={LeftArrow} alt="Go Back" width={16} />
        </button>
        <h1 className="py-2 px-4">My Bank Details</h1>
      </div>
      <hr className="mb-4 w-full" />
      <div
        className="bg-[#F8F6FB] rounded-lg py-4 px-4 text-base text-blue-700 flex items-start my-3 mx-auto"
        role="alert"
      >
        <img
          src={InfoIcon}
          alt="info"
          height={16}
          width={16}
          className="mr-2"
        />
        <p className="text-[#566370] text-sm">
          Your details are only used to receive payment from your customers. It
          won&lsquo;t be used for anything else.
        </p>
      </div>

      <form
        className="py-4 md:pb-12 flex flex-col justify-start flex-grow w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <label htmlFor="bankNameInput">Bank Name</label>
        <select
          className="form-select appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
          aria-label="Select A Bank"
          {...register("bankName")}
          id="bankNameInput"
        >
          <option value="">Choose A Bank</option>
          {/* Sort alphabetically by name */}
          {Banks.sort((a, b) => {
            if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase())
              return -1;
            if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase())
              return 1;
            return 0;
          }).map((bank) => (
            <option value={bank.code} key={bank.name}>
              {bank.name}
            </option>
          ))}
        </select>

        <div className="flex flex-col space-y-2 my-4">
          <label htmlFor="bankAccountNumberInput">Account Number</label>
          <input
            type="text"
            id="bankAccountNumberInput"
            className="form-control border border-gray-300 px-3 py-1.5 rounded focus:border-0"
            placeholder="NUBAN 10 digits"
            required
            {...register("bankAccNo")}
            maxLength={12}
          />
        </div>

        <div className="flex flex-col space-y-2 my-4 flex-grow">
          <label htmlFor="bankHolderNameInput">
            Account Holder&apos;s Name
          </label>
          <input
            type="text"
            id="bankHolderNameInput"
            className="form-control border border-gray-300 px-3 py-1.5 rounded focus:border-0"
            placeholder="e.g. Amaka Odus"
            required
            {...register("bankHolderName")}
          />
        </div>

        <div className="text-center lg:text-left">
          <button
            type="submit"
            className="inline-block
                      px-7 py-3
                      bg-purple-50 text-white
                      font-medium text-sm leading-snug uppercase
                      rounded-lg
                      shadow-md
                      hover:bg-purple-70 hover:shadow-lg
                      active:bg-purple-70 active:bg-purple-70 active:shadow-lg
                      focus:shadow-lg focus:outline-none focus:ring-0
                      transition duration-150 ease-in-out
                      w-full"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
