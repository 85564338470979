/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";

import AddIcon from "../assets/icons/folder-icon.png";

export default function CustomDropZone({
  acceptedFiles,
  getRootProps,
  getInputProps,
  icon,
  label,
  className,
  showSelectedFiles,
}) {
  const files = acceptedFiles.map((file) => (
    <li key={file.path} className="text-xs">
      {file.path}
    </li>
  ));
  return (
    <div>
      <div {...getRootProps({ className })}>
        <input {...getInputProps()} />
        <img
          src={icon}
          alt="Add"
          style={{ height: 20, width: 20, marginBottom: 12 }}
        />
        <p className="text-[#566370] text-sm">{label}</p>
      </div>
      {showSelectedFiles && acceptedFiles?.length ? (
        <aside>
          <ul className="list-disc pl-4">{files}</ul>
        </aside>
      ) : null}
    </div>
  );
}

CustomDropZone.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  acceptedFiles: PropTypes.arrayOf(PropTypes.object),
  getRootProps: PropTypes.func.isRequired,
  getInputProps: PropTypes.func.isRequired,
  label: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  showSelectedFiles: PropTypes.bool,
};

CustomDropZone.defaultProps = {
  acceptedFiles: [],
  label: "Drag n drop some files here, or click to select files",
  icon: AddIcon,
  className: "dropzone",
  showSelectedFiles: false,
};
