/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import ReactSlider from "react-slider";
import classNames from "classnames";
import PropTypes from "prop-types";

export default function RangeSlider({ max, ...sliderProps }) {
  return (
    <ReactSlider
      max={max}
      {...sliderProps}
      renderThumb={(props, state) => (
        <div
          {...props}
          className="w-8 aspect-square rounded-full bg-primary-50 text-xs text-white flex items-center justify-center cursor-grab"
        >
          {state.valueNow < max ? state.valueNow : `${state.valueNow}+`}
        </div>
      )}
      renderTrack={(props, state) => {
        const points = Array.isArray(state.value) ? state.value.length : null;
        const isMulti = points && points > 0;
        const isLast = isMulti ? state.index === points : state.index !== 0;
        const isFirst = state.index === 0;
        return (
          <div
            {...props}
            className={classNames({
              "h-1/4 top-1/2 -translate-y-1/2": true,
              "rounded-full": true,
              "bg-grey-40": isMulti ? isFirst || isLast : isLast,
              "bg-primary-50": isMulti ? !isFirst || !isLast : isFirst,
            })}
          />
        );
      }}
      renderMark={(props) => (
        <div
          {...props}
          className={classNames({
            "top-1/2 -translate-y-1/2": true,
            "h-1 w-1": true,
            "rounded-full bg-primary-50": true,
          })}
        />
      )}
    />
  );
}

RangeSlider.propTypes = {
  max: PropTypes.number,
};

RangeSlider.defaultProps = {
  max: 100,
};
