import React from "react";
import { Transition } from "@headlessui/react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import Button from "../forms/Button";

function AirtimePaymentPrompt({
  close,
  confirmPaymentHandler,
  checkingStatus,
  errorMessage,
}) {
  const navigate = useNavigate();

  return (
    <div className="relative h-screen w-full flex flex-col items-center">
      <Transition
        className="fixed left-0 right-0 -bottom-4 z-20 w-full flex justify-center items-center shadow-xl mt-20"
        show={!!errorMessage}
        enterFrom=" translate-y-0"
        enterTo=" -translate-y-12"
        leaveFrom=" -translate-y-12"
        leaveTo=" translate-y-0"
      >
        <p className="py-2.5 px-4 text-red-50 text-sm font-bold w-64 text-center bg-white rounded-[6.25rem] mt-72">
          {errorMessage}
        </p>
      </Transition>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className="absolute h-screen w-full bg-blur" />
      <div className="absolute bottom-0 w-full rounded-t-2xl bg-white pt-[1.62rem] pb-6">
        <div className="w-full flex flex-row justify-between items-center pb-[1.12rem] px-4 border-b-[0.069rem] border-[#E7E7E7]">
          <h1 className="text-lg text-[#1A1A1A] font-semibold">
            Confirm Airtime Payment
          </h1>
          <button type="button" onClick={() => close(false)}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.0079 18.5383L4.7651 6.29552C4.34681 5.87722 4.34681 5.18347 4.7651 4.76517C5.1834 4.34688 5.87715 4.34688 6.29545 4.76517L18.5382 17.0079C18.9565 17.4262 18.9565 18.12 18.5382 18.5383C18.1199 18.9566 17.4262 18.9566 17.0079 18.5383Z"
                fill="#1A1A1A"
              />
              <path
                d="M4.76551 18.5383C4.34721 18.12 4.34721 17.4262 4.76551 17.0079L17.0083 4.76517C17.4266 4.34688 18.1203 4.34688 18.5386 4.76517C18.9569 5.18347 18.9569 5.87722 18.5386 6.29552L6.29585 18.5383C5.87756 18.9566 5.1838 18.9566 4.76551 18.5383Z"
                fill="#1A1A1A"
              />
            </svg>
          </button>
        </div>
        <div className="w-full flex flex-col space-y-[0.69rem] items-center px-4 py-4 border-b-[0.069rem] border-[#E7E7E7]">
          <p className="text-[#1A1A1A] font-normal text-[0.94rem]">
            You should receive a USSD prompt to complete payment. Please click
            the button below after payment is made to complete the wallet topup.
          </p>
        </div>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className="px-4 pt-3">
          <Button onClick={() => confirmPaymentHandler(navigate)}>
            <p className="font-semibold text-center pt-1">
              {checkingStatus ? "Confirming payment..." : "Confirm payment"}
            </p>
          </Button>
        </div>
      </div>
    </div>
  );
}

AirtimePaymentPrompt.defaultProps = {
  errorMessage: null,
};

AirtimePaymentPrompt.propTypes = {
  close: PropTypes.func.isRequired,
  confirmPaymentHandler: PropTypes.func.isRequired,
  checkingStatus: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
};

export default AirtimePaymentPrompt;
