import React, { useEffect } from "react";
import { useStateMachine } from "little-state-machine";

import Layout from "../../components/layout/V2/Layout";
import AdCampaignsTab from "../../components/V2/AdCampaignsTab";
import { resetStoreValue } from "../../utils/stateMachine";

export default function AdCampaignsTabs() {
  const { actions } = useStateMachine({ resetStoreValue });

  // Whenever this page loads, we clear any existing ads information
  // This way, users always start creating ads from scratch
  useEffect(() => {
    actions.resetStoreValue("adCampaigns");
  }, []);

  return (
    <Layout headerTitle="Ad Campaigns">
      <div className="relative overflow-y-auto min-h-screen scroll-smooth pt-16 pb-28 max-w-screen-xl container mx-auto flex flex-col">
        <div className="h-full mt-4">
          <AdCampaignsTab />
        </div>
      </div>
    </Layout>
  );
}
