import React from "react";
import * as Recharts from "recharts";
import PropTypes from "prop-types";

const { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, Legend } =
  Recharts;

function SpendChart({ data }) {
  return (
    <ResponsiveContainer height={480}>
      <BarChart
        data={data}
        layout="vertical"
        barCategoryGap={1}
        margin={{ top: 0, right: 0, left: 25, bottom: 0 }}
        width="100vw"
        height="200px"
      >
        <Legend verticalAlign="bottom" align="center" height={36} />
        <Tooltip cursor={{ stroke: "white", strokeWidth: 1 }} />
        <XAxis type="number" />
        <YAxis type="category" dataKey="date" />

        <Bar
          dataKey="Weekly Transactions"
          fill="#4B1D8F"
          label={{
            fill: "white",
            position: "insideRight",
          }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default SpendChart;

SpendChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};
