import moment from "moment/moment";

export function updateState(state, payload) {
  return {
    ...state,
    ...payload,
  };
}

export function updatePaymentInfo(state, payload) {
  return {
    ...state,
    paymentInfo: {
      ...state.paymentInfo,
      ...payload,
    },
  };
}

export function updateAdJourney(state, payload) {
  return {
    ...state,
    adJourney: {
      ...state.adJourney,
      ...payload,
    },
  };
}

export const DEFAULT_STORE_VALUE = {
  registrationForm: {},
  adJourney: {
    description: "",
    headline: "",
    image: "",
    is_draft: "True",
    url: "",
    ad_type: "AWARENESS",
    audienceData: {
      startAge: 18,
      endAge: 35,
      gender: "All",
      location: {
        key: "1218312",
        name: "Parklands",
      },
      goal: "",
      interests: "",
    },
    durationData: {
      startDate: moment(),
      endDate: moment().add(2, "days"),
      days: 3,
    },
    facebookPageId: null,
    stepsDone: 0,
  },
  paymentInfo: {
    paymentFor: null,
    paymentAmount: 0,
    paymentMethod: null,
  },
};

export function resetStoreValue(state, value) {
  return {
    ...state,
    [value]: DEFAULT_STORE_VALUE[value],
  };
}

export function clearStore(reset = true) {
  if (reset) {
    return DEFAULT_STORE_VALUE;
  }
  return {};
}
