/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

import { useQuery } from "@tanstack/react-query";
import moment from "moment/moment";
import { useForm, useWatch } from "react-hook-form";

import { API_PATHS, getMpesaSpend } from "../utils/requests";
import Layout from "../components/layout/Layout";
import SpendChart from "../components/SpendChart";
import { useAuth } from "../utils/Contexts/AuthenticationContext";
import Button from "../components/forms/Button";
import useDebounce from "../utils/useDebounce";
import Spinner from "../components/Spinner";

export default function Insights() {
  const { user } = useAuth();
  const toDate = moment().format("YYYY-MM-DD");

  const { register, control } = useForm({
    defaultValues: {
      fromDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
    },
  });

  const fromDateValue = useWatch({ control, name: "fromDate" });

  const debouncedDate = useDebounce(fromDateValue, 1000);

  const queryParams = new URLSearchParams({
    from: debouncedDate,
    to: toDate,
    storeNumber: user?.merchant?.store_number,
  });

  const { data: transactions, isFetching } = useQuery(
    [
      API_PATHS.mpesaSpend,
      queryParams.toString(),
      user?.merchant?.store_number,
      debouncedDate,
    ],
    async () => {
      try {
        const response = await getMpesaSpend({ queryParams }).then((res) => {
          const list = res.Data.weeklyTransactions.map((transaction) => ({
            date: moment(transaction.date).format("MMM D"),
            "Weekly Transactions": transaction.totalAmount,
          }));

          return {
            totalAmount: new Intl.NumberFormat("en-UK", {
              style: "currency",
              currency: "KES",
            }).format(res.Data.totalAmount),
            weeklyTransactions: list,
          };
        });
        return response;
      } catch (error) {
        console.log(error);
        return Promise.reject(error);
      }
    },
    {
      enabled: !!user?.merchant?.store_number,
    }
  );

  return (
    <Layout headerTitle="Spend">
      <div className="h-screen w-full pt-20">
        {isFetching ? (
          <div className="h-full w-full flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className="h-full">
            {user?.merchant && !user.merchant.store_number ? (
              <div className="h-full w-full flex flex-col justify-center items-center px-2 text-center">
                <h1 className="text-1xl font-semibold">
                  No Data. Please Configure Your M-Pesa To View Customer Spend
                </h1>
                <Button
                  href="/account/profile"
                  className="w-auto mx-auto flex items-center my-4"
                >
                  <span>Profile</span>
                </Button>
              </div>
            ) : (
              <>
                <div className="px-2 w-full">
                  <form className="w-max mx-auto mb-4">
                    <select
                      className="form-select appearance-none block w-40 h-[58px] px-3 py-1.5 text-sm font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded-lg transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-purple-40 focus:outline-none focus:shadow "
                      aria-label="Select Date Range"
                      required
                      name="to"
                      {...register("fromDate")}
                    >
                      <option
                        value={moment()
                          .subtract(7, "days")
                          .format("YYYY-MM-DD")}
                      >
                        Last 7 Days
                      </option>
                      <option
                        value={moment()
                          .subtract(14, "days")
                          .format("YYYY-MM-DD")}
                      >
                        Last 14 Days
                      </option>
                      <option
                        value={moment()
                          .subtract(30, "days")
                          .format("YYYY-MM-DD")}
                      >
                        Last 30 Days
                      </option>
                      <option
                        value={moment()
                          .subtract(60, "days")
                          .format("YYYY-MM-DD")}
                      >
                        Last 60 Days
                      </option>
                    </select>
                  </form>
                  <div className="my-4">
                    <h3 className="text-base font-semibold">
                      {transactions?.totalAmount || 0}
                    </h3>
                    <p>Total Transactions</p>
                  </div>
                </div>
                <div className="container h-full px-2 pb-28">
                  <SpendChart data={transactions.weeklyTransactions} />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
}
