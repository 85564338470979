import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import { NavLink, useParams } from "react-router-dom";
import Button from "../../../components/forms/Button";
import Layout from "../../../components/layout/V2/Layout";
import { useOffer } from "../../../utils/Contexts/V2/OfferContext";
import { useAuth } from "../../../utils/Contexts/V2/AuthenticationContext";

import SuccessScreen from "../../../components/V2/SuccessScreen";
import { useAppContext } from "../../../utils/Contexts/AppContext";

export default function ReviewOffer() {
  const {
    offers,
    selectedCustomersForOffer,
    isSendingOffer,
    sendOfferHandler,
    errorMessage,
  } = useOffer();
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const { user } = useAuth();
  const { id } = useParams();
  const { appTheme } = useAppContext();

  const webpage = () => {
    if (appTheme === "tappi") {
      return `https://${user?.subdomain}.tappi.ke`;
    }
    return `https://thryve.ng/${user?.id}`;
  };

  const newOffer = offers?.filter(
    (offer) => offer.id.toString() === id.toString()
  )[0];

  return (
    <Layout headerTitle="Create Offers" header>
      <div className="relative w-full h-screen scroll-smooth pt-20 flex flex-col items-center justify-between">
        <Transition
          className="fixed -bottom-14 left-0 z-20 w-full"
          show={showSuccessScreen}
          enterFrom=" translate-y-0"
          enterTo=" -translate-y-12"
          leaveFrom=" -translate-y-12"
          leaveTo=" translate-y-0"
        >
          <SuccessScreen
            redirectTo="/customers"
            message={
              selectedCustomersForOffer.length > 1
                ? `Your offer has been sent to ${
                    selectedCustomersForOffer[0]?.full_name
                  } and ${selectedCustomersForOffer.length - 1} others`
                : `Your offer has been sent to ${selectedCustomersForOffer[0]?.full_name}`
            }
          />
        </Transition>
        <Transition
          className="fixed left-0 right-0 -bottom-4 z-20 w-full flex justify-center items-center shadow-xl mt-20"
          show={!!errorMessage}
          enterFrom=" translate-y-0"
          enterTo=" -translate-y-12"
          leaveFrom=" -translate-y-12"
          leaveTo=" translate-y-0"
        >
          <p className="py-2.5 px-2 text-red-50 text-sm font-bold w-64 text-center bg-white rounded-[6.25rem] mt-72">
            {errorMessage}
          </p>
        </Transition>
        <div className="px-4 flex flex-col justify-center items-center space-y-4">
          <div className="relative w-[15.56rem] h-1 rounded-lg bg-primary-20">
            <div className="w-[75%] bg-primary-50 h-1 rounded-lg" />
          </div>
        </div>
        <div className="w-full h-full p-4">
          <div className="w-full px-4 py-2 flex flex-col justify-start items-start border border-solid rounded-lg">
            <div className="w-full flex flex-row justify-between items-center">
              <h1 className="text-base font-bold">Offer Details</h1>
              <NavLink
                to={
                  newOffer?.is_active
                    ? `/create-offers/select-customers/${id}`
                    : `/edit-offers/${id}`
                }
              >
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="16"
                    cy="16"
                    r="16"
                    fill="#DBD8D4"
                    fillOpacity="0.5"
                  />
                  <g clipPath="url(#clip0_1456_8701)">
                    <path
                      d="M16.6 12.5721L19.428 15.4007L12.828 22.0001H10V19.1714L16.6 12.5714V12.5721ZM17.5427 11.6294L18.9567 10.2147C19.0817 10.0897 19.2512 10.0195 19.428 10.0195C19.6048 10.0195 19.7743 10.0897 19.8993 10.2147L21.7853 12.1007C21.9103 12.2257 21.9805 12.3953 21.9805 12.5721C21.9805 12.7488 21.9103 12.9184 21.7853 13.0434L20.3707 14.4574L17.5427 11.6294Z"
                      fill="#1A1A1A"
                      fillOpacity="0.5"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1456_8701">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(8 8)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </NavLink>
            </div>
            <div className="w-full flex flex-col justify-start items-center space-y-2">
              <div className="w-full flex flex-row justify-start items-start gap-4">
                <p className="text-xs font-bold text-[#566370] w-max">
                  Offer name:
                </p>
                <p className="text-xs font-bold">{newOffer?.title}</p>
              </div>
              <div className="w-full flex flex-row justify-start items-start gap-4">
                <p className="text-xs font-bold text-[#566370] w-max">
                  Description:
                </p>
                <p className="text-xs font-bold">{newOffer?.description}</p>
              </div>
              <div className="w-full flex flex-row justify-start items-start gap-4">
                <p className="text-xs font-bold text-[#566370] w-max">
                  Webpage:
                </p>
                <p className="text-xs font-bold">{webpage()}</p>
              </div>
            </div>
          </div>
          <div className="w-full px-4 py-2 flex flex-col justify-start items-start border border-solid rounded-lg mt-4">
            <div className="w-full flex flex-row justify-between items-center">
              <h1 className="text-base font-bold">Send to</h1>
              <NavLink to={`/create-offers/select-customers/${id}`}>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="16"
                    cy="16"
                    r="16"
                    fill="#DBD8D4"
                    fillOpacity="0.5"
                  />
                  <g clipPath="url(#clip0_1456_8701)">
                    <path
                      d="M16.6 12.5721L19.428 15.4007L12.828 22.0001H10V19.1714L16.6 12.5714V12.5721ZM17.5427 11.6294L18.9567 10.2147C19.0817 10.0897 19.2512 10.0195 19.428 10.0195C19.6048 10.0195 19.7743 10.0897 19.8993 10.2147L21.7853 12.1007C21.9103 12.2257 21.9805 12.3953 21.9805 12.5721C21.9805 12.7488 21.9103 12.9184 21.7853 13.0434L20.3707 14.4574L17.5427 11.6294Z"
                      fill="#1A1A1A"
                      fillOpacity="0.5"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1456_8701">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(8 8)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </NavLink>
            </div>
            <div className="w-full flex flex-row justify-start items-start flex-wrap mt-2">
              {selectedCustomersForOffer.length > 6 ? (
                <div className="w-full flex flex-row justify-start items-start flex-wrap">
                  {selectedCustomersForOffer?.slice(0, 5).map((item) => (
                    <div
                      key={Math.random()}
                      className="flex flex-row justify-between items-center  p-1.5 border border-solid rounded-[1.25rem] mr-2 mb-1 space-x-[0.33rem]"
                    >
                      <p className="text-xs font-bold text-grey-60">
                        {item.full_name}
                      </p>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_1456_8727)">
                          <path
                            d="M8.00065 14.6668C4.31865 14.6668 1.33398 11.6822 1.33398 8.00016C1.33398 4.31816 4.31865 1.3335 8.00065 1.3335C11.6827 1.3335 14.6673 4.31816 14.6673 8.00016C14.6673 11.6822 11.6827 14.6668 8.00065 14.6668ZM8.00065 7.0575L6.11532 5.1715L5.17198 6.11483L7.05798 8.00016L5.17198 9.8855L6.11532 10.8288L8.00065 8.94283L9.88598 10.8288L10.8293 9.8855L8.94332 8.00016L10.8293 6.11483L9.88598 5.1715L8.00065 7.0575Z"
                            fill="#1A1A1A"
                            fillOpacity="0.5"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1456_8727">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  ))}
                  <div
                    key={Math.random()}
                    className="flex flex-row justify-between items-center  p-1.5 border border-solid rounded-[1.25rem] mr-2 mb-1 space-x-[0.33rem]"
                  >
                    <p className="text-xs font-bold text-grey-60">
                      +{selectedCustomersForOffer.length - 5} more
                    </p>
                  </div>
                </div>
              ) : (
                selectedCustomersForOffer.map((item) => (
                  <div
                    key={Math.random()}
                    className="flex flex-row justify-between items-center  p-1.5 border border-solid rounded-[1.25rem] mr-2 mb-1 space-x-[0.33rem]"
                  >
                    <p className="text-xs font-bold text-grey-60">
                      {item.full_name}
                    </p>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_1456_8727)">
                        <path
                          d="M8.00065 14.6668C4.31865 14.6668 1.33398 11.6822 1.33398 8.00016C1.33398 4.31816 4.31865 1.3335 8.00065 1.3335C11.6827 1.3335 14.6673 4.31816 14.6673 8.00016C14.6673 11.6822 11.6827 14.6668 8.00065 14.6668ZM8.00065 7.0575L6.11532 5.1715L5.17198 6.11483L7.05798 8.00016L5.17198 9.8855L6.11532 10.8288L8.00065 8.94283L9.88598 10.8288L10.8293 9.8855L8.94332 8.00016L10.8293 6.11483L9.88598 5.1715L8.00065 7.0575Z"
                          fill="#1A1A1A"
                          fillOpacity="0.5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1456_8727">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
        <div className="relative p-4 w-full shadow-[0_-2px_8px_rgba(0, 0, 0, 0.3)] border border-t-solid border-t-[#D1D9DD]">
          <Button
            className="font-semibold flex justify-center items-center"
            onClick={() => sendOfferHandler(setShowSuccessScreen, id)}
          >
            {isSendingOffer ? "Sending Offer" : "Continue"}
          </Button>
        </div>
      </div>
    </Layout>
  );
}
