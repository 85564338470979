import React from "react";
import { Link } from "react-router-dom";
import ForgotPasswordForm from "../../components/V2/ForgotPasswordForm";

export default function ForgotPasswordPage() {
  return (
    <div className="h-screen w-screen">
      <div className="flex flex-col">
        <div className="header h-24 p-4 md:h-32 flex gap-8 items-center border border-[#D9D9D9]">
          <Link to="/join">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.828 7.00017H16V9.00017H3.828L9.192 14.3642L7.778 15.7782L0 8.00017L7.778 0.222168L9.192 1.63617L3.828 7.00017Z"
                fill="#1A1A1A"
              />
            </svg>
          </Link>
          <div className="w-full">
            <h1 className="font-semibold text-center -ml-4">
              Reset Your Password
            </h1>
          </div>
        </div>

        <div className="m-auto container flex flex-col p-4">
          <p className="font-medium text-center">
            Where would you like to receive a Verification Code ?
          </p>
          <ForgotPasswordForm />
        </div>
      </div>
    </div>
  );
}
