import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import { useQueryClient } from "@tanstack/react-query";
import { useStateMachine } from "little-state-machine";

import authenticationService, {
  decodeTokenFromLocalStorage,
} from "./authenticationService";
import { clearStore } from "../../stateMachine";
import { listAccessibleMerchantAccounts } from "../../requests-v2";

const AuthContext = createContext(null);

function useAuth() {
  return useContext(AuthContext);
}

function AuthProvider({ children }) {
  // User can be `undefined`, `null`, or an object
  // Undefined means user data is still fetching, not yet loaded
  const [user, setUser] = useState(undefined);
  const [merchantId, setMerchantId] = useState(undefined);

  const [accessToken, setAccessToken] = useState(null);
  const [clientId, setClientId] = useState(null);

  // Initial state obtained by calling `getCurrentUser`
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);

  const { actions } = useStateMachine({ clearStore });

  const [accessibleMerchantAccounts, setAccessibleMerchantAccounts] =
    useState(null);

  const [isAuthStateLoading, setIsAuthStateLoading] = useState(true);

  const getUserToken = () => {
    if (accessToken) {
      return accessToken;
    }

    const encryptedToken = localStorage.getItem("A");

    if (encryptedToken) {
      const token = decodeTokenFromLocalStorage(encryptedToken);
      setAccessToken(token);

      return token;
    }

    return null;
  };

  const getRefreshToken = () => {
    const token = authenticationService.getRefreshToken();
    return token;
  };

  const getClientId = () => {
    if (clientId) {
      return clientId;
    }

    const id = authenticationService.getClientId();
    setClientId(id);
    return id;
  };

  const updateUser = (userData) => {
    setUser(userData);
  };

  // This effect runs only once, and it checks if there is an existing user and sets them
  // It should only run once as it sets the user on page load
  useEffect(() => {
    setIsAuthStateLoading(true);
    authenticationService
      .getCurrentUser()
      .then((userData) => {
        if (userData) {
          setIsAuthenticated(true);
          updateUser(userData);
          setMerchantId(userData.id);
          listAccessibleMerchantAccounts()
            .then((res) => {
              setAccessibleMerchantAccounts(res.data);
            })
            .catch(() => {
              setAccessibleMerchantAccounts(null);
            });
        } else {
          updateUser(null);
          setIsAuthenticated(false);
          authenticationService.logout();
        }
      })
      .catch(() => {
        updateUser(null);
        setIsAuthenticated(false);
        authenticationService.logout();
      })
      .finally(() => setIsAuthStateLoading(false));
  }, []);

  const login = async ({ username, password }) => {
    setIsAuthStateLoading(true);
    return authenticationService
      .login({ username, password })
      .then((res) => {
        updateUser(res.userData);
        setAccessibleMerchantAccounts(res.merchants);
        setIsAuthenticated(true);
        return Promise.resolve(res);
      })
      .catch((error) => {
        updateUser(null);
        setIsAuthenticated(false);
        return Promise.reject(error);
      })
      .finally(() => setIsAuthStateLoading(false));
  };

  const clearAuthContext = () => {
    setMerchantId(null);
    setAccessToken(null);
    setAccessibleMerchantAccounts(null);
  };

  const switchAccount = async ({ id, token }) => {
    setIsAuthStateLoading(true);
    return authenticationService
      .switchAccount({ merchantId: id, refreshToken: token })
      .then((res) => {
        clearAuthContext();
        updateUser(res.userData);
        setAccessibleMerchantAccounts(res.merchants);
        setMerchantId(res.userData.id);
        setIsAuthenticated(true);

        return Promise.resolve(res);
      })
      .catch((error) => {
        updateUser(null);
        setIsAuthenticated(false);
        return Promise.reject(error);
      })
      .finally(() => setIsAuthStateLoading(false));
  };

  const queryClient = useQueryClient();

  const logout = async (callback) => {
    authenticationService.logout();
    updateUser(null);
    setIsAuthenticated(false);
    clearAuthContext();
    actions.clearStore();

    // Clear all cached queries after user logs out
    queryClient.clear();
    if (typeof callback === "function") callback();
  };

  const updateMerchantId = (id) => {
    setMerchantId(parseInt(id, 10));
    authenticationService.updateMerchantId(parseInt(id, 10));
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      isAuthenticated,
      updateMerchantId,
      getUserToken,
      merchantId,
      updateUser,
      accessibleMerchantAccounts,
      getRefreshToken,
      getClientId,
      switchAccount,
      isAuthStateLoading,
    }),
    [user, accessibleMerchantAccounts, isAuthStateLoading]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export { useAuth, AuthProvider };

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
