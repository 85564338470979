/* eslint-disable camelcase */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import {
  getAllSubscriptionHistory,
  getSubscriptionPackages,
} from "../../requests-v2";
import { usePaymentContext } from "./PaymentContext";
import {
  usePaymentContext as usePaymentContext2,
  PAYMENT_REASONS,
} from "./PaymentContext2";
import { useAuth } from "./AuthenticationContext";

const SubscriptionContext = createContext(null);

function useSubscriptionContext() {
  return useContext(SubscriptionContext);
}

function SubscriptionProvider({ children }) {
  const {
    country,
    selectedPayment,
    mpesaPayPhoneNumber,
    tathminiId,
    flutterwavePaymentDetails,
  } = usePaymentContext();
  const { user, isAuthenticated } = useAuth();
  const [subscriptionHistory, setSubscriptionHistory] = useState(null);
  const [subscriptionPackages, setSubscriptionPackages] = useState(null);
  const { setActivePaymentFor, setActivePaymentAmount } = usePaymentContext2();
  const [isLoadingFlutterwavePaymentDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(
    JSON.parse(localStorage.getItem("SELECTED_PLAN"))
  );

  const [subscriptionData, setSubscriptionData] = useState(null);

  useEffect(() => {
    if (user) {
      setSubscriptionData({
        subscriptionPackage: user?.subscription_package?.name || "None",
        subscriptionAmount: user?.subscription_amount || 0,
        subscriptionExpiryDate: user?.subscription_expiry_date,
        subscriptionStatus: user?.subscription_status || false,
        subscriptionInterval: user?.package_interval,
        country: user?.country_name,
      });
    }
  }, [user]);

  const selectedPlanHandler = (plan, callback) => {
    setSelectedPlan(plan);
    setActivePaymentFor(PAYMENT_REASONS.SUBSCRIPTION);
    setActivePaymentAmount(plan.amount);
    callback("/account/subscription/payment");
  };

  const paymentNavigationHandler = (callback) => {
    if (selectedPayment.mpesa) {
      callback("/account/subscription/payment/mpesa");
    } else {
      callback("/account/subscription/payment/promo_code");
    }
  };

  const [activePlans, setActivePlans] = useState(null);

  // Get subscription data ... This should only run if we have user data
  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      getSubscriptionPackages()
        .then((res) => {
          setSubscriptionPackages(res.data);

          const formattedPlans = res.data
            .filter((item) => !["Free"].includes(item.name))
            .map((item) => {
              if (item.name !== "Web") {
                return {
                  id: item.id,
                  name: item.name,
                  price: {
                    Monthly: {
                      Kenya: item.price,
                      Nigeria: item.price,
                    },
                    Quarterly: {
                      Kenya: item.price * 3,
                      Nigeria: item.price * 3,
                    },
                    Annually: {
                      Kenya: item.price * 12 * 0.75,
                      Nigeria: item.price * 12 * 0.75,
                    },
                  },
                  benefits: {
                    recommended: item.name === "Gold",
                    benefitsDetails: [
                      {
                        name: "A professional business website",
                        availability: true,
                      },
                      {
                        name: "1 social media Ad (10,000 views)",
                        availability: ["Gold", "Silver"].includes(item.name),
                      },
                      {
                        name: "Reviews from customers online",
                        availability: ["Gold", "Silver"].includes(item.name),
                      },
                      {
                        name: "350 Verified reviews",
                        availability: ["Gold", "Silver"].includes(item.name),
                      },
                      {
                        name: "Be found on google",
                        availability: ["Gold", "Silver"].includes(item.name),
                      },
                      {
                        name: "Insights on payments",
                        availability: ["Gold", "Silver"].includes(item.name),
                      },
                      {
                        name: "Insights on Ads",
                        availability: ["Gold", "Silver"].includes(item.name),
                      },
                      {
                        name: "Customer engagement insights",
                        availability: ["Gold", "Silver"].includes(item.name),
                      },
                    ],
                  },
                };
              }
              return {
                id: item.id,
                name: item.name,
                price: {
                  Annually: {
                    Kenya: item.price,
                    Nigeria: item.price,
                  },
                },
                benefits: {
                  recommended: false,
                  benefitsDetails: [
                    {
                      name: "A professional business website",
                      availability: true,
                    },
                    {
                      name: "1 social media Ad (10,000 views)",
                      availability: false,
                    },
                    {
                      name: "Reviews from customers online",
                      availability: false,
                    },
                    {
                      name: "350 Verified reviews",
                      availability: false,
                    },
                    {
                      name: "Be found on google",
                      availability: false,
                    },
                    {
                      name: "Insights on payments",
                      availability: false,
                    },
                    {
                      name: "Insights on Ads",
                      availability: false,
                    },
                    {
                      name: "Customer engagement insights",
                      availability: false,
                    },
                  ],
                },
              };
            });

          setActivePlans(formattedPlans);
        })
        .catch(() => {
          setSubscriptionPackages(null);
          setActivePlans(null);
        });
      getAllSubscriptionHistory()
        .then((res) => {
          setSubscriptionHistory(res.data);
        })
        .catch(() => {
          setSubscriptionHistory(null);
        })
        .finally(() => setIsLoading(false));
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    localStorage.setItem("SELECTED_PLAN", JSON.stringify(selectedPlan));
  }, [selectedPlan]);

  const value = useMemo(() => ({
    subscriptionData,
    isLoading,
    subscriptionPackages,
    country,
    selectedPlan,
    selectedPlanHandler,
    mpesaPayPhoneNumber,
    subscriptionHistory,
    isLoadingFlutterwavePaymentDetails,
    flutterwavePaymentDetails,
    tathminiId,
    paymentNavigationHandler,
    activePlans,
  }));

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
}

export { useSubscriptionContext, SubscriptionProvider };

SubscriptionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
