function resolveSubdomain(appVendor, user) {
  if (!appVendor || !user) return "";

  switch (appVendor) {
    case "thryve":
      return `https://thryve.ng/${user.id}`;
    case "tappi":
      return `https://${user.subdomain}.tappi.ke`;
    default:
      return `https://thryve.ng${user.id}`;
  }
}

export default resolveSubdomain;
