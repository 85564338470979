import React from "react";
import moment from "moment/moment";
import PropTypes from "prop-types";

export default function OfferDetails({
  offers,
  offersContacts,
  showOfferOptionHandler,
}) {
  const getCustomerCountForOffer = (id) =>
    offersContacts.filter((item) => item.id === id)[0]?.count;

  const getOfferStatus = (offer) => {
    if (offer.is_active) {
      if (moment(offer.end_date).isAfter(moment())) {
        return "Live";
      }
      return "Completed";
    }
    return "Draft";
  };

  return (
    <div className="w-full">
      {offers.map((item) => (
        <div
          className="flex flex-col justify-start items-center p-4 border border-solid rounded-xl w-full space-y-2 mb-4"
          key={item.id}
        >
          <div className="flex flex-row justify-between items-center w-full">
            <h1 className="font-bold text-sm text-[#1A1A1A]">{item.title}</h1>
            <button
              className="flex justify-center items-center w-6 h-6 rounded-full bg-[#EFEDEC]"
              type="button"
              onClick={() => showOfferOptionHandler(item.id)}
            >
              <svg
                width="2"
                height="12"
                viewBox="0 0 2 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 0C0.45 0 0 0.45 0 1C0 1.55 0.45 2 1 2C1.55 2 2 1.55 2 1C2 0.45 1.55 0 1 0ZM1 10C0.45 10 0 10.45 0 11C0 11.55 0.45 12 1 12C1.55 12 2 11.55 2 11C2 10.45 1.55 10 1 10ZM1 5C0.45 5 0 5.45 0 6C0 6.55 0.45 7 1 7C1.55 7 2 6.55 2 6C2 5.45 1.55 5 1 5Z"
                  fill="#1A1A1A"
                />
              </svg>
            </button>
          </div>
          <div className="flex justify-start items-start w-full pr-[2.4rem]">
            <p className="text-[#566370] text-xs">{item.description}</p>
          </div>
          <div className="flex flex-row justify-between items-center w-full">
            <div className="flex flex-col justify-start items-start">
              <div className="flex flex-row space-x-1.5 justify-start items-center">
                <div
                  className={`h-2.5 w-2.5 ${
                    getOfferStatus(item) === "Live"
                      ? "bg-green-500"
                      : "bg-[#D1CDCD]"
                  }  rounded-full`}
                />
                <p className="font-bold text-sm text-[#1A1A1A]">
                  {getOfferStatus(item)}
                </p>
              </div>
              <p className="text-[0.63rem] font-normal text-[#566370]">
                Status
              </p>
            </div>
            <div className="flex flex-col justify-start items-start border-l border-solid pl-2">
              <div className="flex flex-row space-x-1.5 justify-start items-center">
                <p className="font-bold text-sm text-[#1A1A1A]">
                  {moment(item.end_date).format("DD/MM/YYYY")}
                </p>
              </div>
              <p className="text-[0.63rem] font-normal text-[#566370]">Date</p>
            </div>
            <div className="flex flex-col justify-start items-start border-l border-solid pl-2">
              <div className="flex flex-row space-x-1.5 justify-start items-center">
                <p className="font-bold text-sm text-[#1A1A1A]">
                  {getCustomerCountForOffer(item.id)} customers
                </p>
              </div>
              <p className="text-[0.63rem] font-normal text-[#566370]">
                Delivered to
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

OfferDetails.propTypes = {
  offers: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  offersContacts: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))
    .isRequired,
  showOfferOptionHandler: PropTypes.func.isRequired,
};
