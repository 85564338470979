import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/layout/V2/Layout";
import SubscriptionPlanSwitch from "../components/SubscriptionPlanSwitch";
import SubscriptionCards from "../components/SubscriptionCards";
import { useSubscriptionContext } from "../utils/Contexts/V2/SubscriptionContext";

function SubscriptionPlans() {
  const navigate = useNavigate();
  const { subscriptionData } = useSubscriptionContext();
  const subscriptionPackage = subscriptionData?.subscriptionPackage;

  const [billingRate, setBillingRate] = useState("Monthly");
  return (
    <Layout headerTitle="Unlock more features" header>
      <div className="flex flex-col justify-start items-center w-full px-4 pt-20">
        <p className="font-semibold text-center">
          {subscriptionPackage ? (
            <p>
              You are currently on the{" "}
              <span className="brand font-semibold">{subscriptionPackage}</span>{" "}
              Plan
            </p>
          ) : (
            <p>You are not subscribed to any package</p>
          )}
        </p>
        <div className="mx-auto mt-6">
          <div>
            <SubscriptionPlanSwitch
              billingRateSwitch={setBillingRate}
              billingRate={billingRate}
            />
          </div>
          <SubscriptionCards
            billingRate={billingRate}
            navigateHandler={navigate}
          />
        </div>
      </div>
    </Layout>
  );
}

export default SubscriptionPlans;
