import React from "react";
import moment from "moment";
import { Transition } from "@headlessui/react";
import Layout from "../../components/layout/V2/Layout";
import WalletTopUp from "../../components/V2/wallet/WallletTopUp";
// import verifiedFeedbackSpend from "../../assets/icons/verifiedFeedbackSpend.svg";
import creditReversal from "../../assets/icons/creditReversal.svg";
import walletTopUp from "../../assets/icons/walletTopUp.svg";
import bulkSMSspend from "../../assets/icons/bulkSMSspend.svg";
import facebookSpend from "../../assets/icons/facebookAdsSpend.svg";
import { useWalletContext } from "../../utils/Contexts/V2/WalletContext";
import Spinner from "../../components/Spinner";
import {
  PAYMENT_REASONS,
  usePaymentContext,
} from "../../utils/Contexts/V2/PaymentContext2";

function Wallet() {
  const {
    isLoadingWalletData,
    walletDetails,
    walletTransactionHistory,
    setShowWalletTop,
    showWalletTopUp,
  } = useWalletContext();

  const { setActivePaymentFor } = usePaymentContext();

  // eslint-disable-next-line consistent-return
  const iconSelectorHandler = (type) => {
    if (type.toLowerCase().includes("ads")) {
      return facebookSpend;
    }
    if (type.toLowerCase().includes("sms")) {
      return bulkSMSspend;
    }

    if (
      type.toLowerCase().includes("refund") ||
      type.toLowerCase().includes("credit")
    ) {
      return creditReversal;
    }

    return walletTopUp;
  };

  return (
    <Layout wallet>
      {isLoadingWalletData ? (
        <div className="h-screen w-screen flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className="relative w-full min-h-screen scroll-smooth pt-[5.9rem] flex flex-col justify-between pt-20 pb-28">
          <Transition
            className="fixed -bottom-10 left-0 z-20 w-full"
            show={showWalletTopUp}
            enterFrom=" translate-y-0"
            enterTo=" -translate-y-12"
            leaveFrom=" -translate-y-12"
            leaveTo=" translate-y-0"
          >
            <WalletTopUp />
          </Transition>
          <div className="flex flex-col justify-start items-center pt-9 px-4">
            <p className="text-[#1A1A1A] text-[0.94rem] font-semibold">
              My Balance
            </p>
            <p className="text-[#1A1A1A] text-[0.94rem] font-semibold pt-4">
              <span className="text-[3.12rem] pr-1">
                {walletDetails?.credit_balance}
              </span>
              credits
            </p>
            <button
              type="button"
              onClick={() => {
                setActivePaymentFor(PAYMENT_REASONS.CREDITS);
                setShowWalletTop(true);
              }}
            >
              <div className="flex flex-row space-x-[0.53rem] items-center px-5 py-3 bg-[#E9E3F1] thryve:bg-primary-50 rounded-lg mt-4">
                <svg
                  width="19"
                  height="20"
                  viewBox="0 0 19 20"
                  className="fill-primary-50 thryve:fill-base"
                >
                  <path d="M11.356 1.595C11.3771 1.56847 11.4034 1.5465 11.4332 1.53043C11.4631 1.51436 11.4959 1.50453 11.5296 1.50154C11.5634 1.49855 11.5974 1.50245 11.6296 1.51302C11.6618 1.52358 11.6916 1.54059 11.717 1.563L12.638 2.375L9.739 6H11.66L13.766 3.368L15.418 4.825C15.4668 4.86817 15.4969 4.92871 15.5018 4.99372C15.5066 5.05872 15.4859 5.12306 15.444 5.173L14.754 6H16.698C16.9388 5.64748 17.0424 5.21909 16.9894 4.79549C16.9364 4.37188 16.7303 3.98227 16.41 3.7L12.71 0.437004C12.5315 0.279807 12.3228 0.160645 12.0968 0.0867963C11.8707 0.0129474 11.6319 -0.0140386 11.395 0.00748656C11.1581 0.0290117 10.9282 0.0985961 10.7191 0.21199C10.51 0.325384 10.3262 0.480207 10.179 0.667004L5.976 6H7.886L11.356 1.595V1.595ZM2.25 5.5C2.05109 5.5 1.86032 5.57902 1.71967 5.71967C1.57902 5.86033 1.5 6.05109 1.5 6.25C1.5 6.44892 1.57902 6.63968 1.71967 6.78033C1.86032 6.92099 2.05109 7 2.25 7H15.25C16.112 7 16.9386 7.34241 17.5481 7.95191C18.1576 8.5614 18.5 9.38805 18.5 10.25V16.75C18.5 17.612 18.1576 18.4386 17.5481 19.0481C16.9386 19.6576 16.112 20 15.25 20H3.25C2.38805 20 1.5614 19.6576 0.951903 19.0481C0.34241 18.4386 0 17.612 0 16.75V6.25C0 5.65327 0.237053 5.08097 0.65901 4.65901C1.08097 4.23706 1.65326 4 2.25 4H6.57L5.378 5.5H2.25ZM12.5 13.75C12.5 14.164 12.836 14.5 13.25 14.5H15.25C15.4489 14.5 15.6397 14.421 15.7803 14.2803C15.921 14.1397 16 13.9489 16 13.75C16 13.5511 15.921 13.3603 15.7803 13.2197C15.6397 13.079 15.4489 13 15.25 13H13.25C13.0511 13 12.8603 13.079 12.7197 13.2197C12.579 13.3603 12.5 13.5511 12.5 13.75V13.75Z" />
                </svg>
                <p className="text-primary-50 thryve:text-base text-[0.94rem] font-semibold">
                  Buy More
                </p>
              </div>
            </button>
            <div className="w-full flex flex-col justify-start items-start mt-[2.93rem]">
              <p className="text-[#1A1A1A] text-base font-semibold">
                Recent Activities
              </p>
              {walletTransactionHistory !== null ? (
                <div className="w-full flex flex-col justify-start items-start mt-[1.56rem] space-y-3">
                  {walletTransactionHistory.map((item) => (
                    <div
                      key={item.id}
                      className="w-full flex flex-row justify-between items-start pb-4 border-b-[0.09rem] border-[#F0F0F0]"
                    >
                      <div className="flex flex-row space-x-2.5 items-start justify-start">
                        <img
                          src={iconSelectorHandler(item.type)}
                          alt={item.name}
                          className="pt-px"
                        />
                        <div className="flex flex-col space-y-2 items-start justify-start">
                          <p className="text-[#0D253F] text-[0.94rem] font-semibold capitalize">
                            {item.type}
                          </p>
                          <p className="text-[#4B5762] text-[0.8rem] font-normal">
                            {moment(item.created_at).format(
                              "Do MMM YY; h:mm a"
                            )}
                          </p>
                        </div>
                      </div>
                      <p
                        className={`${
                          item.destination === "inbound"
                            ? "text-[#08AB34] "
                            : "text-red-50"
                        } text-sm font-semibold`}
                      >
                        {item.destination === "inbound"
                          ? `+${item.amount}`
                          : `-${item.amount}`}
                      </p>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="w-full flex flex-col justify-center items-center mt-[8.81em]">
                  <p className="text-[#6D7789] text-sm font-semibold">
                    You have not performed any transaction.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}

export default Wallet;
