import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import Layout from "../../../components/layout/V2/Layout";
import Button from "../../../components/forms/Button";
import { useRequestPaymentContext } from "../../../utils/Contexts/V2/RequestPaymentContext";
import displayPicHandler from "../../../utils/displayPicNameHandler";
import PaymentLink from "./payment-link";

export default function EnterAmount() {
  const {
    validation,
    validationHandler,
    contactDetailsHandler,
    selectContact,
    showPaymentLinkPrompt,
    generatePaymentLink,
    isGeneratingPaymentLink,
  } = useRequestPaymentContext();

  const [showShareDialogue, setShowShareDialogue] = useState(false);
  const [operation, setOperation] = useState(null);

  const showDialogueHandler = (bool, type) => {
    setShowShareDialogue(bool);
    setOperation(type);
  };

  return (
    <Layout
      header
      headerTitle="Request Money"
      showDialogueHandler={showDialogueHandler}
      operation={operation}
      show={showShareDialogue}
    >
      <div className="relative h-screen pt-20 w-full flex flex-col justify-between items-center">
        <Transition
          className="fixed -bottom-12 left-0 z-40 w-full h-screen "
          show={showPaymentLinkPrompt}
          enterFrom=" translate-y-0"
          enterTo=" -translate-y-12"
          leaveFrom="-translate-y-12"
          leaveTo=" translate-y-0"
        >
          <PaymentLink dialogueTrigger={showDialogueHandler} />
        </Transition>
        <div className="flex flex-col items-center w-full px-4">
          {selectContact === null && (
            <p className="text-[0.9375rem] text-[#1A1A1A] text-center px-4 leading-[1.25rem]">
              Enter your customer’s number and the amount you are requesting.
            </p>
          )}
          <div className="flex flex-col justify-start items-center w-full py-6 space-y-5">
            {selectContact !== null ? (
              <div className="flex flex-col justify-center items-center w-full mb-[1rem]">
                <div className="relative flex justify-center items-center w-full">
                  <p className="w-[4rem] h-[4rem] bg-[#D9D9D9] rounded-full p-4 flex justify-center text-[1rem] items-center font-bold">
                    {displayPicHandler(selectContact?.full_name)}
                  </p>
                  <p className="px-[0.75rem] py-[0.38rem] bg-primary-10 thryve:bg-[#EEF1F2] absolute -bottom-4 text-[0.75rem] thryve:text-secondary-50">
                    {selectContact?.phone_number}
                  </p>
                </div>
                <p className="mt-[1.31rem] text-[1rem] font-bold">
                  {selectContact?.full_name}
                </p>
              </div>
            ) : (
              <div className="flex flex-col justify-start items-start w-full">
                <input
                  name="phone_number"
                  placeholder="Customer’s phone number"
                  type="text"
                  className={`h-14 w-full px-3 border-2 ${
                    validation?.phone_number ? "border-red-50" : "border-solid "
                  }  rounded-lg outline-none text-sm font-bold text-[#566370]`}
                  onBlur={validationHandler}
                  onChange={contactDetailsHandler}
                  id="phone_number"
                />
                {validation?.phone_number && (
                  <p className="text-red-50 text-sm font-normal mt-0 ">
                    {validation?.phone_number}
                  </p>
                )}
              </div>
            )}
            <div className="flex flex-col justify-start items-start  w-full">
              <input
                name="amount"
                placeholder="Enter amount"
                type="tel"
                className={`h-14 w-full px-3 border-2 ${
                  validation?.amount ? "border-red-50" : "border-solid "
                }  rounded-lg outline-none text-sm font-bold text-[#566370]`}
                onBlur={validationHandler}
                onChange={contactDetailsHandler}
                id="amount"
              />
              {validation?.amount && (
                <p className="text-red-50 text-sm font-normal mt-0">
                  {validation.amount}
                </p>
              )}
            </div>
            {/* <div className="flex flex-col justify-start items-start w-full"> */}
            {/*  <input */}
            {/*    name="reason" */}
            {/*    placeholder="What is for?" */}
            {/*    type="text" */}
            {/*    className={`h-14 w-full px-3 border-2 ${ */}
            {/*      validation?.reason ? "border-red-50" : "border-solid " */}
            {/*    }  rounded-lg outline-none text-sm font-bold text-[#566370]`} */}
            {/*    onBlur={validationHandler} */}
            {/*    onChange={contactDetailsHandler} */}
            {/*    id="reason" */}
            {/*  /> */}
            {/*  {validation?.reason && ( */}
            {/*    <p className="text-red-50 text-sm font-normal mt-0"> */}
            {/*      {validation.reason} */}
            {/*    </p> */}
            {/*  )} */}
            {/* </div> */}
            <div className="flex flex-col justify-start items-start w-full">
              <input
                name="email"
                placeholder="Customer’s email (Optional)"
                type="text"
                className="h-14 w-full px-3 border-2  border-solid
                 rounded-lg outline-none text-sm font-bold text-[#566370]"
                onChange={contactDetailsHandler}
                id="email"
              />
              {validation?.reason && (
                <p className="text-red-50 text-sm font-normal mt-0">
                  {validation.reason}
                </p>
              )}
            </div>
            <div className="flex flex-row item-start justify-start w-full h-[1.25rem">
              <input
                name="request_review"
                type="checkbox"
                className="checked:bg-primary-50 h-[1.25rem] border border-[#F8F6FB] accent-primary-50 "
                onBlur={validationHandler}
                onChange={contactDetailsHandler}
              />
              <p className="pl-2.5 text-sm font-normal text-[#1A1A1A] h-full">
                Request a review from your customer
              </p>
            </div>
          </div>
        </div>
        <div className="w-full shadow-t-lg p-4 border-t border-solid">
          <Button className="font-bold" onClick={generatePaymentLink}>
            {isGeneratingPaymentLink ? "Generating link..." : "Continue"}
          </Button>
        </div>
      </div>
    </Layout>
  );
}
