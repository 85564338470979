/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */

/**
 * Validate International Phone Numbers
 * @argument {string} phoneNumber - The phone number to validate
 * @argument {string} countryCode - The country code of the phone number
 * @returns {boolean} - Returns true if the phone number is valid or false otherwise
 * */
export function validatePhoneNumber(phoneNumber, countryCode = "KE") {
  if (!countryCode || !phoneNumber) {
    return false;
  }

  let parsedPhoneNumber = phoneNumber;

  if (!parsedPhoneNumber.startsWith("+")) {
    parsedPhoneNumber = `+${parsedPhoneNumber}`;
  }

  switch (countryCode.toUpperCase()) {
    // Kenyan Phone Numbers
    case "KE":
      if (parsedPhoneNumber.length > 13 || parsedPhoneNumber.length < 11) {
        return false;
      }
      return true;

    // Nigerian Phone Numbers
    case "NG":
      if (parsedPhoneNumber.length > 14 || parsedPhoneNumber.length < 10) {
        return false;
      }
      return true;

    default:
      return false;
  }
}

// Validates that every character in the string is a number
const validateOnlyNumbersInString = (value) => /^\d+$/.test(value);

export function validateMpesaTillNumber(tillNumber) {
  if (!tillNumber) {
    return false;
  }
  if (!validateOnlyNumbersInString(tillNumber)) {
    return false;
  }
  if (tillNumber.length < 5 || tillNumber.length > 7) {
    return false;
  }
  return true;
}

export function validateMpesaStoreNumber(storeNumber) {
  if (!storeNumber) {
    return false;
  }
  if (!validateOnlyNumbersInString(storeNumber)) {
    return false;
  }
  if (storeNumber.length < 5 || storeNumber.length > 10) {
    return false;
  }
  return true;
}
