/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import PropTypes from "prop-types";

import { v4 } from "uuid";

import LabelledInput from "./LabelledInput";

export default function PasswordInput({
  name,
  rules,
  type,
  className,
  id,
  register,
  placeholder,
  label,
  wrapperClassName,
  labelClassName,
  helperText,
  control,
  errorText,
  ...rest
}) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const uuid = v4();

  return (
    <div>
      <LabelledInput
        name={name}
        rules={rules}
        type={isPasswordVisible ? "text" : type}
        className={className}
        id={id}
        register={register}
        placeholder={placeholder}
        label={label}
        wrapperClassName={wrapperClassName}
        labelClassName={labelClassName}
        helperText={helperText}
        control={control}
        errorText={errorText}
        {...rest}
      />
      <fieldset className="flex items-center gap-4 px-4 -mb-4 text-sm">
        <label htmlFor={uuid}>Show Password</label>
        <input
          type="checkbox"
          id={uuid}
          checked={isPasswordVisible}
          onChange={() => setIsPasswordVisible(!isPasswordVisible)}
        />
      </fieldset>
    </div>
  );
}

PasswordInput.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.shape({ required: PropTypes.bool }),
  type: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  register: PropTypes.func,
  wrapperClassName: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  characterLimit: PropTypes.number,
  showCharacterCount: PropTypes.bool,
  control: PropTypes.object,
  errorText: PropTypes.string,
};

PasswordInput.defaultProps = {
  rules: {},
  type: "text",
  wrapperClassName: "form-floating w-full",
  className:
    "form-control block w-full px-3 py-1.5 text-sm font-medium text-base bg-white bg-clip-padding border border-solid border-[#D1D9DD] rounded-lg transition ease-in-out m-0 focus:bg-white focus:border-primary-50 thryve:focus:border-secondary-50 focus:outline-none focus:shadow thryve:focus:shadow-[#0061874D]",
  labelClassName: "text-gray-500",
  placeholder: "",
  register: () => {},
  helperText: "",
  characterLimit: 1000,
  showCharacterCount: false,
  control: null,
  errorText: "",
};
