/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState, useCallback } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import Layout from "../../components/layout/V2/Layout";
import LabelledInput from "../../components/forms/LabelledInput";
import Button from "../../components/forms/Button";
import { updateState } from "../../utils/stateMachine";
import FacebookAdPreview from "../../components/V2/FacebookAdPreview";
import {
  deleteFacebookAdImage,
  getFacebookAdDetail,
  updateFacebookAdImage,
  uploadFacebookAdImage,
} from "../../utils/requests-v2";
import { useAuth } from "../../utils/Contexts/V2/AuthenticationContext";
import { useAppContext } from "../../utils/Contexts/AppContext";
import resolveSubdomain from "../../utils/resolveSubdomain";

export default function AdDesignPage() {
  const { state, actions } = useStateMachine({ updateState });

  const { user } = useAuth();
  const { appVendor } = useAppContext();

  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      instagramUsername: state?.adJourney?.instagramUsername || "",
      description: state?.adJourney?.description || "",
      headline: state?.adJourney?.headline || "",
      url: state?.adJourney?.url || resolveSubdomain(appVendor, user),
    },
  });

  const [adImage, setAdImage] = useState("");

  const descriptionValue = useWatch({ control, name: "description" });
  const urlValue = useWatch({ control, name: "url" });

  const [imageUrls, setImageUrls] = useState([]);

  const [imageIdBeingUpdated, setImageIdBeingUpdated] = useState(null);

  const { data: adData } = useQuery(
    [`/insights/ads/${state.adJourney.id}`, state.adJourney.id],
    () => getFacebookAdDetail({ adId: state.adJourney.id }),
    { enabled: !!state.adJourney.isEditing && !!state.adJourney.id }
  );

  const [isLoading, setIsLoading] = useState(false);

  // Whenever we get back adData, we update our imageUrls and set the currently
  // Visible image to be the first on the list
  useEffect(() => {
    if (adData) {
      if (adData.images?.length) {
        setAdImage(adData.images[0]);
        setImageUrls(adData.images?.slice(0, 6));
        actions.updateState({
          adJourney: {
            ...state.adJourney,
            images: adData.images?.slice(0, 6),
          },
        });
      } else {
        setAdImage("");
        setImageUrls([]);
        actions.updateState({
          adJourney: {
            ...state.adJourney,
            images: [],
          },
        });
      }
    }
  }, [adData]);

  const queryClient = useQueryClient();

  const handleImageFileUpload = async (e) => {
    setIsLoading(true);
    const images = Array.from(e.target.files);

    const requests = [];

    let message = "";

    // Check if we are updating an image, or uploading a new one
    if (imageIdBeingUpdated && images[0] instanceof File) {
      requests.push(
        updateFacebookAdImage({
          imageId: imageIdBeingUpdated,
          image: images[0],
        })
          .then(async () => {
            await queryClient.invalidateQueries({
              queryKey: [
                `/insights/ads/${state.adJourney.id}`,
                state.adJourney.id,
              ],
              type: "all",
            });
            message = "Image was successfully updated";
          })
          .catch(() => {
            message = "Could not update your image. Please try again";
          })
      );
    } else {
      images.map((image) =>
        requests.push(
          uploadFacebookAdImage({ image, id: state.adJourney.id })
            .then(async () => {
              await queryClient.invalidateQueries({
                queryKey: [
                  `/insights/ads/${state.adJourney.id}`,
                  state.adJourney.id,
                ],
                type: "all",
              });

              message = "Image was successfully uploaded";
            })
            .catch(() => {
              message = "Coudl not upload your images. Please try again.";
            })
        )
      );
    }

    await Promise.all(requests);
    toast.success(message);
    setIsLoading(false);
  };

  const navigate = useNavigate();

  function onSubmit(data) {
    if (!adImage) {
      toast.error("You must upload an image");
      return;
    }
    actions.updateState({
      adJourney: {
        ...state.adJourney,
        stepsDone: 3,
        ...data,
        images: imageUrls,
      },
    });

    navigate("/ads/create/preview");
  }

  // Upload multpiple images sequentially
  const handleMoreImagesUpload = async (e) => {
    setIsLoading(true);
    if (imageUrls?.length < 6) {
      const files = Array.from(e.target.files);

      // We should only upload maximum of 6 images. So we check against the files the user has already uploaded
      const remainingAdCount = 6 - imageUrls.length;

      const requests = [];
      files
        .slice(0, remainingAdCount)
        .map((image) =>
          requests.push(
            uploadFacebookAdImage({ image, id: state.adJourney.id })
          )
        );

      await Promise.all(requests);

      queryClient.invalidateQueries({
        queryKey: [`/insights/ads/${state.adJourney.id}`, state.adJourney.id],
        type: "all",
      });

      toast.success("Your images were successfully uploaded");
    } else {
      toast.error("Maximum number (6) of images");
    }

    setIsLoading(false);
  };

  // Delete an ad image and update the react query cache
  const { mutateAsync: mutateDeleteAdImage } = useMutation(
    (id) => deleteFacebookAdImage({ imageId: id }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [`/insights/ads/${state.adJourney.id}`, state.adJourney.id],
          type: "all",
        });
        toast.success("Image successfully deleted");
      },
      onError: () => toast.error("Could not delete image. Please try again"),
    }
  );

  const handleDeleteAdImage = useCallback(async (imageId) => {
    setIsLoading(true);
    await mutateDeleteAdImage(imageId);
    setAdImage(imageUrls[0]);
    setIsLoading(false);
  }, []);

  return (
    <Layout
      header
      headerTitle={
        <div className="flex flex-col items-center justify-center">
          <p className="text-xs font-normal text-gray-500 text-center">
            Create Ad
          </p>
          <h2 className="text-base">Design Your Ad</h2>
        </div>
      }
    >
      <div className="pt-20 pb-4 max-w-screen-xl container mx-auto px-4 flex flex-col">
        <div className="my-4">
          <div className="h-1 w-[70vw] bg-gray-200 dark:bg-gray-300 mx-auto">
            <div
              className="h-1 bg-purple-900 thryve:bg-primary-50"
              style={{ width: "75%" }}
            />
          </div>
        </div>
        <p className="text-[#1A1A1A] text-sm text-center">
          This is a preview of your ad
        </p>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FacebookAdPreview
              description={descriptionValue}
              editable
              image={adImage}
              setAdImage={setAdImage}
              url={urlValue}
              onDeleteImage={handleDeleteAdImage}
              onChange={handleImageFileUpload}
              setImageIdBeingUpdated={setImageIdBeingUpdated}
            />

            <div>
              <input
                type="file"
                hidden
                id="moreAdImagesInput"
                multiple
                accept="image/*"
                onChange={(e) => handleMoreImagesUpload(e)}
                max={5}
                disabled={imageUrls.length >= 6}
              />
              <div className="flex flex-col p-2 bg-gray-200 mb-2 rounded-xl">
                <div className="text-center mb-2">
                  {imageUrls.length >= 6 ? (
                    <p className="text-xs text-red-500">
                      You&apos;ve hit the maximum limit for image uploads, which
                      is set at 6 images. If you&apos;d like to upload new
                      images, kindly remove one of your existing images.
                    </p>
                  ) : (
                    <p className="text-xs text-gray-500">
                      You can upload more images. A maximum of six images is
                      allowed. Each image must not exceed 5MB.
                    </p>
                  )}
                </div>
                <div className="flex">
                  <label
                    htmlFor="moreAdImagesInput"
                    className={`h-12 w-12 p-4 mr-2 bg-gray-400 block flex items-center justify-center rounded-full ${
                      imageUrls.length >= 6
                        ? "cursor-not-allowed"
                        : "cursor-pointer"
                    }`}
                  >
                    +
                  </label>

                  {imageUrls.length ? (
                    <div className="flex flex-wrap items-center space-x-1 space-y-1">
                      {imageUrls.map((image) => (
                        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
                        <img
                          src={image.image_file}
                          alt="ad"
                          key={crypto.randomUUID()}
                          className="h-14 w-14"
                          onClick={() => setAdImage(image)}
                        />
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <LabelledInput
              label="Instagram Username (optional)"
              placeholder="Instagram Username (optional)"
              name="instagramUsername"
              register={register}
              id="instagramUsernameInput"
              characterLimit={25}
              showCharacterCount
              control={control}
            />

            <LabelledInput
              label="Description e.g luxury shoes"
              placeholder="Description e.g luxury shoes"
              name="description"
              rules={{ required: true, maxLength: 200 }}
              register={register}
              id="descriptionInput"
              control={control}
              showCharacterCount
              characterLimit={200}
            />

            <LabelledInput
              label="Headline"
              placeholder="Headline"
              name="headline"
              register={register}
              id="headlineInput"
              rules={{ required: true, maxLength: 50 }}
            />

            <LabelledInput
              label="URL"
              placeholder="URL"
              readOnly
              disabled
              name="url"
              register={register}
              id="urlInput"
            />

            <Button
              type="submit"
              className="mt-6"
              disabled={!imageUrls.length || isLoading}
              isLoading={isLoading}
            >
              Continue
            </Button>
          </form>
        </div>
      </div>
    </Layout>
  );
}
