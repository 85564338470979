/* eslint-disable camelcase */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { toast } from "react-hot-toast";
import PropTypes from "prop-types";
import { collectPayment, getPaymentInfo } from "../../requests-v2";
import { useAuth } from "./AuthenticationContext";

const RequestPaymentContext = createContext(null);

function useRequestPaymentContext() {
  return useContext(RequestPaymentContext);
}

function RequestPaymentProvider({ children }) {
  const { user } = useAuth;
  const [selectContact, setSelectContact] = useState(null);
  const [contactDetails, setContactDetails] = useState({
    phone_number: "",
    amount: 0,
    request_review: false,
    email: "",
  });
  const [validation, setValidation] = useState({
    phone_number: "",
    amount: "",
  });
  const [paymentLink, setPaymentLink] = useState(null);
  const [isVerifyingBankDetails, setIsVerifyingBankDetails] = useState(false);
  const [isGeneratingPaymentLink, setIsGeneratingPaymentLink] = useState(false);
  const [bankValidationPrompt, setBankValidationPrompt] = useState(false);
  const [showPaymentLinkPrompt, setShowPaymentLinkPrompt] = useState(false);

  const phoneNumber_regex = /^(234\d{10}|254\d{9})$/;

  const selectContactHandler = (contact, callback) => {
    setSelectContact(contact);
    setContactDetails({
      ...contactDetails,
      phone_number: contact.phone_number,
    });
    callback("/request-payment/enter-amount");
  };

  const contactDetailsHandler = (event) => {
    if (event.target.name === "request_review") {
      setContactDetails({
        ...contactDetails,
        request_review: event.target.checked,
      });
    }
    if (event.target.name !== "request_review" && event.target.value) {
      setContactDetails({
        ...contactDetails,
        [event.target.name]: event.target.value,
      });
    }
  };

  const validationHandler = (event) => {
    if (!event.target.value) {
      setValidation({
        ...validation,
        [event.target.name]: `${event.target.name} is required`,
      });
    }

    if (event.target.value) {
      if (event.target.name === "phone_number") {
        if (!phoneNumber_regex.test(event.target.value)) {
          setValidation({
            ...validation,
            [event.target.name]: `Invalid phone number`,
          });
        } else {
          setValidation({
            ...validation,
            [event.target.name]: "",
          });
        }
      } else if (event.target.name === "amount" && event.target.value < 100) {
        setValidation({
          ...validation,
          [event.target.name]: "Amount must be greater than 100",
        });
      } else {
        setValidation({
          ...validation,
          [event.target.name]: "",
        });
      }
    }
  };

  const fetchPaymentInfo = async () => {
    setIsVerifyingBankDetails(true);
    try {
      const response = await getPaymentInfo();
      setIsVerifyingBankDetails(false);
      return Promise.resolve(response);
    } catch (e) {
      setIsVerifyingBankDetails(false);
      return Promise.reject(e);
    }
  };

  // eslint-disable-next-line consistent-return
  const generatePaymentLink = () => {
    if (
      !contactDetails.phone_number ||
      !contactDetails.amount.length ||
      validation.phone_number ||
      validation.amount
    ) {
      toast.error("Please ensure all the fields are filled");
      return null;
    }
    setIsGeneratingPaymentLink(true);
    collectPayment({
      customer_phone_number: contactDetails?.phone_number,
      amount: contactDetails?.amount,
      request_review: contactDetails?.request_review,
    })
      .then((res) => {
        setPaymentLink(res?.data);
        setIsGeneratingPaymentLink(false);
        setShowPaymentLinkPrompt(true);
        return Promise.resolve(res);
      })
      .catch((error) => {
        toast.error("Oops! An error occurred");
        setIsGeneratingPaymentLink(false);
        return Promise.reject(error);
      });
  };

  useEffect(() => {
    if (user) {
      fetchPaymentInfo()
        .then((res) => {
          if (!res?.data?.valid) {
            setBankValidationPrompt(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [user]);

  const value = useMemo(() => ({
    selectContact,
    validation,
    bankValidationPrompt,
    paymentLink,
    isVerifyingBankDetails,
    setBankValidationPrompt,
    showPaymentLinkPrompt,
    isGeneratingPaymentLink,
    contactDetails,
    selectContactHandler,
    validationHandler,
    contactDetailsHandler,
    fetchPaymentInfo,
    generatePaymentLink,
    setShowPaymentLinkPrompt,
  }));
  return (
    <RequestPaymentContext.Provider value={value}>
      {children}
    </RequestPaymentContext.Provider>
  );
}

export { useRequestPaymentContext, RequestPaymentProvider };

RequestPaymentProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
