import React from "react";

export default function Spinner() {
  return (
    <div
      className="spinner-border animate-spin inline-block w-12 h-12 border-8 rounded-full text-primary-50"
      role="status"
    >
      <span className="visually-hidden">Loading...</span>
    </div>
  );
}
