import React from "react";
import { useLocation } from "react-router-dom";
import Button from "../../components/forms/Button";
// import { useSubscriptionContext } from "../../utils/Contexts/SubscriptionContext";
import { useWalletContext } from "../../utils/Contexts/V2/WalletContext";
import { usePaymentContext } from "../../utils/Contexts/V2/PaymentContext2";

function PaymentSuccessful() {
  const { pathname } = useLocation();
  const { currency } = usePaymentContext();
  // const { selectedPlan } = useSubscriptionContext();
  const { topUpAmount } = useWalletContext();
  return (
    <div className="flex flex-col justify-around items-center w-full px-4 h-screen pt-20">
      <div className="flex flex-col justify-start items-center">
        <svg width="120" height="120" viewBox="0 0 120 120">
          <g filter="url(#filter0_d_1456_6402)">
            <circle cx="60" cy="48" r="40" className="fill-primary-50" />
            <g clipPath="url(#clip0_1456_6402)">
              <path d="M56.6672 53.2866L71.9872 37.9649L74.3455 40.3216L56.6672 57.9999L46.0605 47.3933L48.4172 45.0366L56.6672 53.2866Z" />
            </g>
          </g>
          <defs>
            <filter
              id="filter0_d_1456_6402"
              x="0"
              y="0"
              width="120"
              height="120"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="12" />
              <feGaussianBlur stdDeviation="10" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.294118 0 0 0 0 0.113725 0 0 0 0 0.560784 0 0 0 0.2 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1456_6402"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1456_6402"
                result="shape"
              />
            </filter>
            <clipPath id="clip0_1456_6402">
              <rect
                width="40"
                height="40"
                fill="white"
                transform="translate(40 28)"
              />
            </clipPath>
          </defs>
        </svg>
        <p className="text-xl font-semibold text-[#1A1A1A]">Success!</p>
        {pathname.includes("subscription") ? (
          <p className="text-base font-normal text-[#1A1A1A] pt-3.5 w-72 text-center">
            You have just subscribed to the plan
          </p>
        ) : (
          <p className="text-base font-normal text-[#1A1A1A] pt-3.5 w-72 text-center">
            You just topped up your wallet with{" "}
            <b>
              {currency}
              {topUpAmount}
            </b>
          </p>
        )}
      </div>

      <svg
        width="224"
        height="160"
        viewBox="0 0 224 160"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1456_6365)">
          <path
            d="M28.5919 25.4711C35.6314 25.4711 41.3381 19.7692 41.3381 12.7355C41.3381 5.7019 35.6314 0 28.5919 0C21.5524 0 15.8457 5.7019 15.8457 12.7355C15.8457 19.7692 21.5524 25.4711 28.5919 25.4711Z"
            fill="url(#paint0_linear_1456_6365)"
          />
          <path
            d="M178.303 130.332C173.859 130.331 169.56 128.757 166.169 125.887C162.778 123.018 160.516 119.04 159.784 114.661L159.768 114.568L159.828 114.497C160.825 113.303 161.662 111.985 162.319 110.576C161.593 111.367 160.77 112.065 159.87 112.652L159.569 112.852L159.552 112.492C159.537 112.181 159.529 111.873 159.529 111.575C159.528 109.823 159.77 108.08 160.248 106.395C162.428 98.7407 162.8 90.5213 161.356 81.9651C161.109 80.498 160.985 79.0128 160.985 77.5252C160.987 71.2382 163.229 65.1573 167.31 60.3714C171.39 55.5854 177.042 52.407 183.254 51.4052C189.466 50.4034 195.831 51.6436 201.212 54.9039C206.592 58.1641 210.634 63.2314 212.616 69.1984L212.677 69.3803L212.499 69.4522C211.164 70.0167 209.749 70.3675 208.305 70.4919C209.831 70.8426 211.396 70.9929 212.961 70.9388L213.126 70.933L213.167 71.0935C213.691 73.1972 213.957 75.3571 213.956 77.5252L213.955 77.7599C213.95 79.4202 214.296 81.0629 214.969 82.5809C215.642 84.0989 216.627 85.4581 217.862 86.5699C219.867 88.3931 221.453 90.6287 222.511 93.1229C223.57 95.6171 224.074 98.3108 223.991 101.019C223.908 103.726 223.239 106.384 222.029 108.809C220.82 111.233 219.099 113.367 216.986 115.064L216.888 115.143L216.773 115.091C214.355 113.995 212.594 112.801 211.306 111.376C212.165 113.205 213.321 114.879 214.727 116.33L214.909 116.517L214.683 116.649C211.813 118.321 208.55 119.201 205.227 119.2L205.132 119.2C205.099 119.2 205.065 119.2 205.032 119.2C199.949 119.2 195.132 121.163 191.8 124.597C190.046 126.402 187.948 127.84 185.63 128.825C183.312 129.809 180.821 130.322 178.303 130.332Z"
            fill="#F2F2F2"
          />
          <path
            d="M171.659 159.886C171.578 159.886 171.499 159.857 171.437 159.803C171.376 159.75 171.336 159.676 171.324 159.596C171.31 159.494 169.9 149.334 171.166 135.831C172.336 123.36 176.091 105.397 187.341 90.6451C187.368 90.6097 187.402 90.58 187.44 90.5576C187.479 90.5353 187.521 90.5207 187.565 90.5148C187.609 90.5088 187.654 90.5116 187.697 90.523C187.74 90.5343 187.781 90.554 187.816 90.581C187.852 90.6079 187.881 90.6416 187.904 90.68C187.926 90.7184 187.941 90.7609 187.947 90.805C187.952 90.849 187.95 90.8938 187.938 90.9368C187.927 90.9798 187.907 91.0201 187.88 91.0555C176.727 105.68 173.002 123.511 171.841 135.894C170.582 149.319 171.981 159.4 171.995 159.5C172.002 159.548 171.998 159.597 171.984 159.644C171.971 159.69 171.947 159.733 171.915 159.77C171.883 159.806 171.844 159.836 171.8 159.856C171.756 159.876 171.708 159.886 171.659 159.886Z"
            fill="#3F3D56"
          />
          <path
            d="M177.282 110.152C177.211 110.152 177.141 110.129 177.083 110.087C177.025 110.045 176.982 109.986 176.96 109.918C176.938 109.85 176.938 109.777 176.96 109.709C176.982 109.641 177.025 109.581 177.083 109.539C180.289 107.371 183.752 105.61 187.392 104.296C193.08 102.215 201.729 100.329 210.633 103.161C210.676 103.174 210.715 103.196 210.749 103.224C210.783 103.253 210.811 103.288 210.832 103.328C210.852 103.367 210.865 103.41 210.868 103.454C210.872 103.499 210.867 103.543 210.854 103.586C210.84 103.628 210.818 103.667 210.79 103.701C210.761 103.735 210.726 103.763 210.686 103.784C210.647 103.804 210.604 103.817 210.559 103.82C210.515 103.824 210.47 103.819 210.428 103.806C201.706 101.032 193.214 102.887 187.625 104.932C184.043 106.223 180.636 107.955 177.481 110.087C177.424 110.129 177.354 110.152 177.282 110.152Z"
            fill="#3F3D56"
          />
          <path
            d="M39.627 134.745C36.4662 134.739 31.4899 134.994 28.7793 136.424C26.6963 137.523 24.7994 138.943 23.158 140.631C22.5253 141.284 21.9261 141.969 21.3626 142.683C19.8038 137.354 19.6223 131.718 20.8353 126.301C20.9205 125.936 20.8925 125.554 20.7549 125.206C20.6173 124.857 20.3767 124.559 20.0651 124.351C19.7535 124.143 19.3859 124.034 19.0111 124.04C18.6363 124.046 18.2723 124.166 17.9675 124.384C13.564 127.593 10.7487 133.031 10.064 138.531C9.19168 145.54 11.2458 152.603 14.1008 159.067L14.0999 159.07C14.0946 159.099 14.0866 159.131 14.0787 159.162C14.0681 159.21 14.055 159.257 14.0444 159.305C14.0258 159.387 14.0048 159.471 13.9862 159.553L14.4485 159.532C14.475 159.545 14.5014 159.556 14.5277 159.569H26.1059C26.2594 159.402 26.4023 159.226 26.5339 159.041C27.8151 157.286 28.346 154.908 28.9933 152.762C29.462 151.208 30.0484 149.692 30.7473 148.227C32.5072 144.548 34.9354 141.226 37.9087 138.432C38.0038 138.343 38.1016 138.253 38.1993 138.166C38.6973 137.708 39.4692 137.096 40.2235 136.522C40.3884 136.397 40.5098 136.223 40.5707 136.025C40.6316 135.827 40.6289 135.616 40.5631 135.419C40.4973 135.223 40.3715 135.053 40.2036 134.932C40.0357 134.811 39.834 134.745 39.627 134.745Z"
            fill="#F2F2F2"
          />
          <path
            d="M117.802 25.6669C118.075 26.4278 118.037 27.2652 117.697 27.9984C117.357 28.7317 116.742 29.3022 115.986 29.5868C115.818 29.6493 115.645 29.6968 115.47 29.7289L113.72 40.4338L109.615 36.6471L111.906 27.0621C111.821 26.2544 112.058 25.4458 112.565 24.8112C113.072 24.1765 113.809 23.7667 114.616 23.6705C115.295 23.5927 115.98 23.748 116.559 24.1108C117.138 24.4736 117.577 25.0223 117.802 25.6669L117.802 25.6669Z"
            fill="#9E616A"
          />
          <path
            d="M87.5098 49.9231C87.5098 49.9231 90.6954 45.7175 102.255 45.8327L108.817 43.4507L110.316 29.7163L115.565 30.9649L114.815 47.9456L103.568 54.4383L88.8228 57.4349L87.5098 49.9231Z"
            fill="#1B4A27"
          />
          <path
            d="M69.748 153.462L72.7764 154.752L79.1952 143.695L74.7258 141.792L69.748 153.462Z"
            fill="#9E616A"
          />
          <path
            d="M94.7722 156.326L98.0638 156.325L99.6298 143.639L94.7715 143.639L94.7722 156.326Z"
            fill="#9E616A"
          />
          <path
            d="M94.6564 159.756L104.779 159.756V159.628C104.779 158.584 104.364 157.583 103.625 156.845C102.886 156.106 101.884 155.692 100.839 155.692H100.839L98.9897 154.29L95.5399 155.692L94.6562 155.692L94.6564 159.756Z"
            fill="#2F2E41"
          />
          <path
            d="M67.9062 155.658L77.2183 159.623L77.2685 159.506C77.6781 158.545 77.6891 157.461 77.2991 156.493C76.9091 155.524 76.15 154.75 75.1888 154.341L75.1885 154.341L74.0376 152.327L70.3139 152.265L69.501 151.919L67.9062 155.658Z"
            fill="#2F2E41"
          />
          <path
            d="M98.0001 81.2446L103.003 111.681L101.641 116.217L103.428 119.429L100.804 147.64L101.869 149.982L98.8938 151.816H92.8391L92.5178 126.276L91.0222 122.527V120.46L87.9775 104.887L87.4169 122.701L86.5313 126.456L87.2752 129.13L86.3032 131.004L77.9729 148.876H71.1836L71.7087 144.834C70.4923 144.219 73.535 142.421 73.8274 141.09L72.8277 138.842L73.8274 134.597L74.744 126.302L73.7773 108.792L74.2322 85.2133L82.1744 79.145L98.0001 81.2446Z"
            fill="#2F2E41"
          />
          <path
            d="M85.8181 41.1614C89.6074 41.1614 92.6792 38.0922 92.6792 34.306C92.6792 30.5199 89.6074 27.4507 85.8181 27.4507C82.0288 27.4507 78.957 30.5199 78.957 34.306C78.957 38.0922 82.0288 41.1614 85.8181 41.1614Z"
            fill="#9E616A"
          />
          <path
            d="M73.8952 93.8695C74.0875 93.5251 74.2019 93.1428 74.2303 92.7494C74.2587 92.356 74.2004 91.9612 74.0596 91.5928C73.9187 91.2244 73.6988 90.8913 73.4152 90.617C73.1316 90.3427 72.7912 90.1338 72.4181 90.0052L72.4019 80.4185L68.2441 83.168L68.897 91.8502C68.7222 92.4908 68.7887 93.1733 69.0838 93.7682C69.379 94.3631 69.8823 94.8292 70.4984 95.0781C71.1145 95.327 71.8006 95.3415 72.4266 95.1187C73.0527 94.896 73.5752 94.4515 73.8952 93.8695Z"
            fill="#9E616A"
          />
          <path
            d="M73.5123 51.4515L71.6548 49.6626L70.2903 50.6466C70.2903 50.6466 68.9478 52.2563 67.8738 57.0852C66.7998 61.9142 67.8738 88.4733 67.8738 88.4733L73.7808 87.6685L72.9753 73.7182L75.3918 61.9141L73.5123 51.4515Z"
            fill="#1B4A27"
          />
          <path
            d="M70.7695 50.0989L77.0275 47.1122L81.2762 43.1746L89.0239 43.1167L91.5232 46.363L98.1212 48.6024L97.0215 71.3347L97.7713 73.0827C97.7713 73.0827 97.249 73.5542 97.0103 73.8179C96.7716 74.0816 97.3401 76.2892 97.3401 76.2892L97.6606 78.691L96.7716 79.3256L97.9273 80.6896L98.2142 82.8396C98.2142 82.8396 78.0414 77.8298 74.2298 86.1289C74.0284 86.5674 74.5243 82.2821 74.5243 82.2821L75.0202 79.1449L74.5283 76.329L73.7785 75.5799L75.036 74.3234L74.2298 68.3617L70.7695 50.0989Z"
            fill="#1B4A27"
          />
          <path
            d="M136.088 153.327H132.375L130.607 139.015H136.088V153.327Z"
            fill="#AF6544"
          />
          <path
            d="M138.048 159.756H135.385L134.91 157.244L133.692 159.756H126.629C126.29 159.756 125.961 159.648 125.688 159.448C125.415 159.247 125.214 158.965 125.113 158.642C125.012 158.319 125.017 157.972 125.128 157.652C125.238 157.333 125.448 157.057 125.727 156.864L131.367 152.972V150.432L137.3 150.786L138.048 159.756Z"
            fill="#2F2E41"
          />
          <path
            d="M172.205 145.965L169.01 147.858L160.184 136.449L164.898 133.655L172.205 145.965Z"
            fill="#AF6544"
          />
          <path
            d="M177.173 150.496L174.882 151.853L173.191 149.935L173.426 152.716L167.351 156.316C167.06 156.489 166.721 156.564 166.384 156.53C166.047 156.497 165.73 156.357 165.478 156.13C165.227 155.904 165.054 155.603 164.986 155.272C164.917 154.94 164.957 154.596 165.098 154.288L167.963 148.066L166.666 145.881L171.95 143.162L177.173 150.496Z"
            fill="#2F2E41"
          />
          <path
            d="M134.431 61.8174L132.627 73.3123L149.771 73.5376L147.515 62.4936L134.431 61.8174Z"
            fill="#AF6544"
          />
          <path
            d="M132.56 73.167L127.663 85.0327L127.307 116.764L131.485 147.062H136.11L138.004 117.258L142.664 96.8657L147.261 118.194L161.551 139.071H168.574L155.663 116.066L154.169 91.0055C154.169 91.0055 155.1 80.3744 150.334 74.1012L149.174 70.7523L132.56 73.167Z"
            fill="#2F2E41"
          />
          <path
            d="M156.546 81.8302C156.068 81.7002 155.625 81.4672 155.248 81.1477C154.87 80.8281 154.568 80.4298 154.361 79.9806C154.154 79.5315 154.048 79.0426 154.051 78.5482C154.054 78.0539 154.165 77.5662 154.377 77.1195L147.084 53.3612L152.449 52.2987L159.119 75.5868C159.847 75.9922 160.4 76.6502 160.674 77.436C160.949 78.2219 160.925 79.081 160.608 79.8505C160.291 80.6201 159.702 81.2467 158.953 81.6116C158.205 81.9764 157.348 82.0542 156.546 81.8302V81.8302Z"
            fill="#AF6544"
          />
          <path
            d="M147.894 70.1633L135.077 64.1919C134.739 64.1847 134.413 64.0671 134.148 63.8571C133.884 63.647 133.695 63.3562 133.612 63.029C132.979 60.5227 131.585 57.7368 132.324 51.2313C132.47 49.9711 132.867 48.7529 133.493 47.6488C134.118 46.5447 134.959 45.5771 135.965 44.8033C136.971 44.0296 138.123 43.4653 139.351 43.1439C140.579 42.8225 141.86 42.7506 143.116 42.9323C144.442 43.1219 145.712 43.5894 146.844 44.3042C147.976 45.0189 148.944 45.9648 149.685 47.0796C150.425 48.1944 150.922 49.4531 151.141 50.7731C151.36 52.093 151.298 53.4443 150.958 54.7385C149.47 60.383 149.63 66.4189 149.46 68.7388C149.435 69.0742 149.301 69.3922 149.078 69.6442C148.855 69.8961 148.555 70.0683 148.225 70.1343C148.127 70.1537 148.027 70.1636 147.927 70.1637C147.916 70.1637 147.905 70.1635 147.894 70.1633Z"
            fill="#6D3E99"
          />
          <path
            d="M120.295 30.2613C120.224 30.4203 120.14 30.5734 120.045 30.7193L126.174 45.0782L129.941 45.54L129.987 51.3118L123.238 51.1419C122.693 51.1282 122.167 50.9381 121.739 50.6002C121.311 50.2624 121.005 49.795 120.865 49.2681L116.323 32.0983C115.679 31.9066 115.109 31.5235 114.689 31.0003C114.269 30.477 114.018 29.8383 113.969 29.169C113.921 28.4998 114.078 27.8317 114.419 27.2538C114.76 26.6758 115.27 26.2153 115.879 25.9335C116.488 25.6517 117.169 25.5619 117.831 25.6762C118.493 25.7905 119.104 26.1033 119.583 26.5731C120.063 27.0429 120.388 27.6475 120.515 28.3063C120.642 28.9651 120.565 29.647 120.295 30.2613V30.2613Z"
            fill="#AF6544"
          />
          <path
            d="M126.792 44.5669C126.945 44.4319 127.125 44.3313 127.32 44.2721C127.515 44.2129 127.72 44.1964 127.922 44.2238L134.973 45.1775C136.015 45.1639 137.02 45.5639 137.768 46.2897C138.515 47.0155 138.944 48.0079 138.96 49.0492C138.977 50.0906 138.579 51.0959 137.855 51.8447C137.13 52.5936 136.138 53.0249 135.096 53.044L128.049 54.1791C127.848 54.2115 127.642 54.2002 127.446 54.146C127.249 54.0917 127.067 53.9959 126.911 53.8649C126.755 53.7339 126.629 53.5708 126.542 53.3869C126.455 53.2029 126.409 53.0023 126.406 52.7988L126.315 45.6455C126.312 45.442 126.353 45.2402 126.436 45.0541C126.518 44.8679 126.64 44.7017 126.792 44.5669Z"
            fill="#6D3E99"
          />
          <path
            d="M146.757 59.3793C146.577 59.2845 146.419 59.152 146.295 58.9908C146.17 58.8296 146.082 58.6435 146.036 58.4452L144.435 51.5184C144.054 50.5492 144.073 49.4686 144.488 48.5133C144.903 47.5581 145.68 46.8063 146.649 46.4227C147.618 46.0391 148.699 46.055 149.657 46.4669C150.614 46.8789 151.368 47.6532 151.755 48.6202L155.311 54.8047C155.412 54.9812 155.474 55.1775 155.493 55.3801C155.512 55.5828 155.487 55.7872 155.419 55.9792C155.352 56.1713 155.243 56.3466 155.102 56.4931C154.961 56.6397 154.789 56.7541 154.6 56.8285L147.936 59.4443C147.746 59.5189 147.543 59.5516 147.339 59.5404C147.136 59.5292 146.937 59.4742 146.757 59.3793Z"
            fill="#6D3E99"
          />
          <path
            d="M146.473 40.4897C149.985 38.5711 151.276 34.1704 149.356 30.6605C147.436 27.1506 143.032 25.8607 139.519 27.7793C136.006 29.698 134.715 34.0987 136.635 37.6086C138.555 41.1185 142.96 42.4084 146.473 40.4897Z"
            fill="#AF6544"
          />
          <path
            d="M138.01 31.223C136.455 31.2142 134.899 31.2055 133.344 31.1967C133.234 30.1377 133.705 29.084 134.393 28.2703C135.08 27.4566 135.97 26.8439 136.849 26.2414C137.741 25.6297 138.665 25.0041 139.726 24.7903C140.615 24.6112 141.533 24.7355 142.431 24.8602C143.622 25.0257 144.813 25.1912 146.004 25.3567C147.312 25.5383 148.679 25.7437 149.741 26.5271C151.177 27.5865 150.81 30.0317 152.188 31.1655C156.349 34.5897 154.664 37.58 154.742 40.7012C149.713 41.2282 144.685 41.7552 139.656 42.2822C139.111 42.3761 138.552 42.3599 138.012 42.2345C137.484 42.0732 137 41.6364 136.961 41.086C136.918 40.4713 137.406 39.9681 137.802 39.496C138.748 38.3255 139.315 36.8957 139.43 35.3958C139.544 33.896 139.2 32.3968 138.443 31.0965L138.01 31.223Z"
            fill="#2F2E41"
          />
          <path
            d="M84.0622 40.826C84.0329 40.7615 84.0037 40.6968 83.9746 40.6319C83.9858 40.6323 83.9969 40.6339 84.0082 40.6342L84.0622 40.826Z"
            fill="#2F2E41"
          />
          <path
            d="M78.765 27.7627C79.0907 27.4556 79.5142 27.2727 79.9612 27.2459C80.4049 27.2602 80.8548 27.6606 80.7629 28.0945C81.5778 26.6528 82.875 25.544 84.4266 24.9626C85.9782 24.3813 87.6852 24.3646 89.2479 24.9155C90.3506 25.3042 91.432 26.0819 91.6853 27.2225C91.7099 27.5219 91.7993 27.8125 91.9472 28.0741C92.0754 28.2139 92.2418 28.3133 92.4258 28.3598C92.6098 28.4064 92.8034 28.3982 92.9828 28.3362L92.9936 28.333C93.055 28.3121 93.1213 28.31 93.1839 28.3271C93.2465 28.3442 93.3025 28.3797 93.3447 28.4289C93.3869 28.4781 93.4133 28.5389 93.4205 28.6033C93.4278 28.6677 93.4155 28.7328 93.3853 28.7902L93.0734 29.3716C93.4667 29.4592 93.8753 29.4505 94.2645 29.3463C94.3292 29.3294 94.3975 29.3328 94.4601 29.3562C94.5227 29.3796 94.5766 29.4218 94.6142 29.477C94.6519 29.5321 94.6716 29.5976 94.6706 29.6644C94.6695 29.7311 94.6479 29.796 94.6085 29.8499C94.09 30.5588 93.413 31.1368 92.6315 31.5382C91.85 31.9396 90.9855 32.1531 90.1069 32.1619C88.8603 32.1543 87.6008 31.725 86.3876 32.0115C85.9182 32.1223 85.4796 32.3367 85.104 32.6389C84.7284 32.9412 84.4253 33.3236 84.2169 33.7582C84.0085 34.1928 83.9001 34.6685 83.8997 35.1503C83.8993 35.6322 84.0069 36.1081 84.2146 36.543C83.8419 36.1357 83.1214 36.2321 82.7401 36.6315C82.5645 36.8453 82.4347 37.0928 82.3588 37.3587C82.2829 37.6247 82.2624 37.9034 82.2986 38.1775C82.4002 39.0173 82.6498 39.8325 83.0356 40.5854C81.5615 40.5401 80.1367 40.0438 78.9538 39.1636C77.771 38.2833 76.887 37.0616 76.4213 35.6633C75.9556 34.2651 75.9306 32.7577 76.3498 31.3448C76.7689 29.9319 77.6119 28.6816 78.765 27.7627V27.7627Z"
            fill="#2F2E41"
          />
          <path
            d="M215.085 160H0.326018C0.247244 160 0.171776 159.968 0.116154 159.913C0.0605331 159.857 0.0292969 159.781 0.0292969 159.703C0.0292969 159.624 0.0605331 159.548 0.116154 159.493C0.171776 159.437 0.247244 159.406 0.326018 159.405H215.085C215.125 159.405 215.163 159.413 215.2 159.428C215.236 159.443 215.269 159.464 215.296 159.492C215.324 159.52 215.346 159.553 215.361 159.589C215.376 159.625 215.384 159.664 215.384 159.703C215.384 159.742 215.376 159.781 215.361 159.817C215.346 159.853 215.324 159.886 215.296 159.913C215.269 159.941 215.236 159.963 215.2 159.978C215.163 159.993 215.125 160 215.085 160Z"
            fill="#CCCCCC"
          />
          <path
            d="M51.5934 2.96423C45.416 2.12994 38.3636 5.46606 36.7625 11.4854C36.359 10.4152 35.6254 9.50082 34.6678 8.87441C33.7101 8.248 32.578 7.942 31.4348 8.00061C30.2917 8.05922 29.1969 8.47941 28.3084 9.20047C27.42 9.92153 26.7839 10.9061 26.4922 12.012L27.1994 12.5181C40.4373 15.3784 54.1406 15.3165 67.3521 12.3367C63.0552 7.8246 57.7708 3.7985 51.5934 2.96423Z"
            fill="#F0F0F0"
          />
          <path
            d="M25.1012 19.9449C18.9239 19.1107 11.8714 22.4468 10.2703 28.4662C9.86682 27.3959 9.13326 26.4815 8.1756 25.8551C7.21794 25.2287 6.08578 24.9227 4.94266 24.9813C3.79955 25.0399 2.70467 25.4601 1.81622 26.1812C0.927763 26.9022 0.291729 27.8868 0 28.9927L0.707217 29.4988C13.9451 32.3592 27.6484 32.2972 40.8599 29.3174C36.563 24.8053 31.2786 20.7792 25.1012 19.9449Z"
            fill="#F0F0F0"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_1456_6365"
            x1="15.8457"
            y1="12.7356"
            x2="41.3383"
            y2="12.7356"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFAD03" />
            <stop offset="0.65098" stopColor="#FE8005" />
            <stop offset="1" stopColor="#FD5308" />
          </linearGradient>
          <clipPath id="clip0_1456_6365">
            <rect width="224" height="160" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <Button
        href={pathname.includes("wallet") ? "/wallet" : "/"}
        className="w-full h-12 text-white text-base text-center font-semibold rounded-xl bg-purple-50"
      >
        Done
      </Button>
    </div>
  );
}

export default PaymentSuccessful;
