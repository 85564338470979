/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import Button from "../../../components/forms/Button";
import { useRequestPaymentContext } from "../../../utils/Contexts/V2/RequestPaymentContext";
import { useNavigate } from "react-router-dom";

export default function PaymentLink({ dialogueTrigger }) {
  const navigate = useNavigate();
  const { setShowPaymentLinkPrompt } = useRequestPaymentContext();

  const paymentLinkHandler = () => {
    dialogueTrigger(true, "Request Payment");
    setShowPaymentLinkPrompt(false);
  };

  return (
    <div className="relative w-screen h-screen flex justify-center items-center">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="w-screen h-screen bg-blur"
        onClick={() => setShowPaymentLinkPrompt(false)}
      />

      <div className="absolute z-20 bottom-0 flex flex-col justify-start items-center space-y-5 w-full px-[1rem] py-[1.5rem]  bg-white rounded-t-2xl">
        <svg
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="120"
            height="120"
            rx="60"
            fill="#008753"
            fillOpacity="0.12"
          />
          <path
            d="M20 34.8149H100V88.1483H20V34.8149ZM60 48.1483C63.5362 48.1483 66.9276 49.553 69.4281 52.0535C71.9286 54.554 73.3333 57.9454 73.3333 61.4816C73.3333 65.0178 71.9286 68.4092 69.4281 70.9097C66.9276 73.4102 63.5362 74.8149 60 74.8149C56.4638 74.8149 53.0724 73.4102 50.5719 70.9097C48.0714 68.4092 46.6667 65.0178 46.6667 61.4816C46.6667 57.9454 48.0714 54.554 50.5719 52.0535C53.0724 49.553 56.4638 48.1483 60 48.1483ZM37.7778 43.7038C37.7778 46.0613 36.8413 48.3222 35.1743 49.9892C33.5073 51.6562 31.2464 52.5927 28.8889 52.5927V70.3705C31.2464 70.3705 33.5073 71.307 35.1743 72.974C36.8413 74.641 37.7778 76.9019 37.7778 79.2594H82.2222C82.2222 76.9019 83.1587 74.641 84.8257 72.974C86.4927 71.307 88.7536 70.3705 91.1111 70.3705V52.5927C88.7536 52.5927 86.4927 51.6562 84.8257 49.9892C83.1587 48.3222 82.2222 46.0613 82.2222 43.7038H37.7778Z"
            fill="#008753"
          />
        </svg>
        <p className="text-xl font-semibold">Request money</p>
        <p className="text-base font-normal text-center">
          The payment link has been dispatched via SMS to your chosen contact.
          They can utilize this link conveniently to complete the payment.
        </p>
        <div className="flex flex-col space-y-3 w-full">
          {/* <Button*/}
          {/*  onClick={paymentLinkHandler}*/}
          {/*  type="button"*/}
          {/*  className="w-full h-12 text-primary-50  text-base font-semibold bg-primary-50 flex justify-center items-center"*/}
          {/*>*/}
          {/*  Share payment link*/}
          {/*</Button>*/}
          <button
            onClick={() => {
              navigate("/");
              setShowPaymentLinkPrompt(false);
            }}
            type="button"
            className="w-full h-12 text-base border-[0.093rem] border-primary-50 py-6 font-semibold bg-white flex justify-center items-center rounded-lg"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

PaymentLink.propTypes = {
  dialogueTrigger: PropTypes.func.isRequired,
};
