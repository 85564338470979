import React from "react";

import Layout from "../../components/layout/V2/Layout";
import UpdateWorkingHoursForm from "../../components/V2/UpdateWorkingHoursForm";

export default function SetWorkingHoursPage() {
  return (
    <Layout header headerTitle="Configure Operation Hours">
      <div className="pt-20 pb-0 px-4 min-h-screen max-w-screen-xl container mx-auto scroll-smooth overflow-y-auto">
        <div className="flex flex-col items-center h-[calc(100vh-112px)] md:h-[70vh] container max-w-[400px] mx-auto">
          <p className="text-sm text-center">
            Set your working hours to help customers know when you are available
          </p>

          <UpdateWorkingHoursForm />
        </div>
      </div>
    </Layout>
  );
}
