import React from "react";
import PropTypes from "prop-types";
import { useProductPhotosContext } from "../../utils/Contexts/V2/ProductPhotosContext";

function PhotoDeleteConfirmation({ photo }) {
  const { hideDeleteConfirmationHandler, deleteProductPhoto, isLoading } =
    useProductPhotosContext();

  return (
    <div className="relative w-full ">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="w-full h-screen flex bg-blur"
        onClick={hideDeleteConfirmationHandler}
      />
      <div className="relative bottom-0 flex flex-col justify-start items-center space-y-5 w-full px-4 pt-[1.7rem] pb-6 bg-white rounded-2xl">
        <p className="text-base font-semibold">Are you sure?</p>
        <p className="text-base font-normal">
          You are about to delete this photo
        </p>
        <div className="flex flex-col space-y-3 w-full">
          <button
            type="button"
            className="w-full h-12 text-white text-base font-semibold rounded-xl bg-primary-50 thryve:text-base"
            onClick={() => deleteProductPhoto(photo.id)}
          >
            {!isLoading ? "Yes, Delete" : "Deleting..."}
          </button>
          <button
            type="button"
            className="w-full h-12 text-black text-base font-semibold"
            onClick={hideDeleteConfirmationHandler}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

PhotoDeleteConfirmation.defaultProps = {
  photo: null,
};

PhotoDeleteConfirmation.propTypes = {
  photo: PropTypes.objectOf(PropTypes.string),
};

export default PhotoDeleteConfirmation;
