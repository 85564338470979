/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import InfoIcon from "../assets/icons/info.svg";
import LeftArrow from "../assets/icons/left-arrow.png";
import Button from "./forms/Button";

export default function MpesaSetup() {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      tillNumber: "",
      storeNumber: "",
    },
  });

  const navigate = useNavigate();

  function onSubmit(data) {
    console.log(data);

    navigate("/");
  }

  function goBack() {
    navigate(-1);
  }

  return (
    <div className="flex flex-col items-center h-screen container max-w-[400px] mx-auto md:py-12 px-4">
      <div className="flex items-center justify-start w-full py-4">
        <button type="button" className="mr-8" onClick={goBack}>
          <img src={LeftArrow} alt="Go Back" width={16} />
        </button>
        <h1 className="py-2 px-4">M-Pesa Buy Goods Details</h1>
      </div>
      <hr className="mb-4 w-full" />
      <div
        className="bg-[#F8F6FB] rounded-lg py-4 px-4 text-base text-blue-700 flex items-start my-3 mx-auto"
        role="alert"
      >
        <img
          src={InfoIcon}
          alt="info"
          height={16}
          width={16}
          className="mr-2"
        />
        <p className="text-[#566370] text-sm">
          Your details are only used to receive payment from your customers. It
          won&lsquo;t be used for anything else.
        </p>
      </div>

      <form
        className="py-4 md:pb-12 flex flex-col justify-start flex-grow w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col space-y-2 my-4">
          <label htmlFor="tillNumberInput">Buy Goods Till Number</label>
          <input
            type="text"
            id="tillNumberInput"
            className="form-control block w-full px-3 py-1.5 text-sm font-medium text-[#1A1A1A] bg-white bg-clip-padding border border-solid border-[#D1D9DD] rounded-lg transition ease-in-out m-0 focus:bg-white focus:border-purple-50 focus:outline-none focus:shadow focus:shadow-[#4b1d8f50]"
            placeholder="6 Digit Till Number"
            required
            {...register("tillNumber")}
            maxLength={6}
          />
        </div>

        <div className="flex flex-col space-y-2 my-4 flex-grow">
          <label htmlFor="storeFrontInput">Store Front Number</label>
          <input
            type="text"
            id="storeFrontInput"
            className="form-control block w-full px-3 py-1.5 text-sm font-medium text-[#1A1A1A] bg-white bg-clip-padding border border-solid border-[#D1D9DD] rounded-lg transition ease-in-out m-0 focus:bg-white focus:border-purple-50 focus:outline-none focus:shadow focus:shadow-[#4b1d8f50]"
            placeholder="eg 43854"
            required
            {...register("storeNumber")}
          />
          <div className="text-sm text-[#56637099] mt-1">
            The number given to you by Safaricom
          </div>
        </div>

        <div className="text-center lg:text-left">
          <Button type="submit">Update</Button>
        </div>
      </form>
    </div>
  );
}
