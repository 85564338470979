import React, { createContext, useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import mpseaIcon from "../../../assets/icons/mpsea.svg";
import promocodeIcon from "../../../assets/icons/promocode.svg";
import mtnLogo from "../../../assets/icons/mtn_logo.svg";
import walletIcon from "../../../assets/icons/wallet-icon.svg";

import { useAuth } from "./AuthenticationContext";

const PAYMENT_METHOD = [
  {
    name: "mpesa",
    text: "Pay with M-Pesa",
    span: "",
    logo: mpseaIcon,
    country: "Kenya",
  },
  {
    name: "credits",
    text: "Pay With Credits",
    span: "",
    logo: walletIcon,
    country: "Kenya",
  },
  {
    name: "promoCode",
    text: "Apply Promo code",
    span: "",
    logo: promocodeIcon,
    country: "Kenya",
  },
  {
    name: "airtime",
    text: "Pay with Airtime",
    span: "",
    logo: mtnLogo,
    country: "Nigeria",
  },
  // {
  //   name: "promoCode",
  //   text: "Apply Promo code",
  //   span: "",
  //   logo: promocodeIcon,
  //   country: "Nigeria",
  // },
];

const PaymentContext = createContext(null);

function usePaymentContext() {
  return useContext(PaymentContext);
}

function PaymentProvider({ children }) {
  const { user } = useAuth();
  const country = user?.country.name;
  const currency = user?.country.currency_code;
  const tathminiId = user?.merchant?.tathmini_account_id;
  const msisdn = user?.phone_number;
  const [flutterwavePaymentDetails, setFlutterwavePaymentDetails] =
    useState(null);
  const [promoCode, setPromoCode] = useState(null);
  const [mpesaPayPhoneNumber, setMpesaPayPhoneNumber] = useState(null);
  const [inputError, setInputError] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState({
    mpesa: false,
    promoCode: false,
    flutter: false,
    airtime: false,
    credits: false,
  });
  const [selectedMpesaPayment, setSelectedMpesaPayment] = useState({
    phone_number: false,
    pay_bill: false,
  });

  const selectedPaymentHandler = (e) => {
    switch (e.target.id) {
      case "mpesa":
        setSelectedPayment({
          promoCode: false,
          mpesa: true,
          flutterwave: false,
          airtime: false,
          credits: false,
        });
        break;
      case "flutterwave":
        setSelectedPayment({
          promoCode: false,
          mpesa: false,
          flutterwave: true,
          airtime: false,
          credits: false,
        });
        break;
      case "airtime":
        setSelectedPayment({
          promoCode: false,
          mpesa: false,
          flutterwave: false,
          airtime: true,
          credits: false,
        });
        break;
      case "credits":
        setSelectedPayment({
          promoCode: false,
          mpesa: false,
          flutterwave: false,
          airtime: false,
          credits: true,
        });
        break;
      default:
        setSelectedPayment({
          promoCode: true,
          mpesa: false,
          flutterwave: false,
          airtime: false,
          credits: false,
        });
    }
  };

  const setPromoCodeHandler = (e) => {
    if (e.target.value) {
      setInputError(false);
      setPromoCode(e.target.value);
    } else {
      setInputError(true);
    }
  };

  const selectedMpesaPaymentHandler = (e) => {
    if (e.target.id === "phone_number") {
      setSelectedMpesaPayment({ phone_number: true, pay_bill: false });
    } else {
      setSelectedMpesaPayment({ phone_number: false, pay_bill: true });
    }
  };

  const mpesaPayPhoneNumberHandler = (e) => {
    if (e.target.value && e.target.value.length === 9) {
      setInputError(false);
      setMpesaPayPhoneNumber(`+254${e.target.value}`);
    } else {
      setInputError(true);
    }
  };

  const setReferenceNumberHandler = (e) => {
    if (e.target.value && e.target.value.length === 10) {
      setInputError(false);
      setReferenceNumber(e.target.value);
    } else {
      setInputError(true);
    }
  };

  const value = useMemo(() => ({
    PAYMENT_METHOD,
    country,
    currency,
    tathminiId,
    promoCode,
    flutterwavePaymentDetails,
    selectedPayment,
    setSelectedPayment,
    setFlutterwavePaymentDetails,
    selectedMpesaPayment,
    mpesaPayPhoneNumber,
    msisdn,
    inputError,
    setInputError,
    referenceNumber,
    setReferenceNumberHandler,
    selectedPaymentHandler,
    setPromoCodeHandler,
    selectedMpesaPaymentHandler,
    mpesaPayPhoneNumberHandler,
  }));

  return (
    <PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>
  );
}

export { usePaymentContext, PaymentProvider };

PaymentProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
