/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";

export default function LabelledTextarea({
  name,
  rules,
  className,
  id,
  register,
  placeholder,
  label,
  wrapperClassName,
  labelClassName,
  helperText,
  ...rest
}) {
  return (
    <div className={wrapperClassName}>
      <textarea
        className={className}
        name={name}
        id={id}
        {...register(name)}
        {...rules}
        placeholder={placeholder || " "} // This is required for the label to float
        {...rest}
        rows={5}
      />
      <label htmlFor={id} className={labelClassName}>
        {label}
      </label>
      {helperText && <p className="text-sm text-gray-500 my-2">{helperText}</p>}
    </div>
  );
}

LabelledTextarea.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.shape({}),
  type: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  register: PropTypes.func,
  wrapperClassName: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
};

LabelledTextarea.defaultProps = {
  rules: {},
  type: "text",
  wrapperClassName: "form-floating mb-3 w-full",
  className:
    "form-control resize-none block w-full px-3 py-1.5 text-sm font-medium text-base bg-white bg-clip-padding border border-solid border-[#D1D9DD] rounded-lg transition ease-in-out m-0 focus:bg-white focus:border-primary-50 thryve:focus:border-secondary-50 focus:outline-none focus:shadow thryve:focus:shadow-[#0061874D]",
  labelClassName: "text-gray-500",
  placeholder: "",
  register: () => {},
  helperText: "",
};
