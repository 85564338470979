/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useStateMachine } from "little-state-machine";
import { toast } from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";

import { type } from "@testing-library/user-event/dist/type";
import { validatePhoneNumber } from "../../utils/validators";
import CustomPhoneInput from "../PhoneInput";
import { updateState, resetStoreValue } from "../../utils/stateMachine";
import LabelledInput from "../forms/LabelledInput";
import Button from "../forms/Button";
import { useAppContext } from "../../utils/Contexts/AppContext";
import { registerMerchant, getCountryList } from "../../utils/requests-v2";
import { useAuth } from "../../utils/Contexts/V2/AuthenticationContext";
import slugifySubdomain from "../../utils/slugifySubdomain";
import PasswordInput from "../forms/PasswordInput";

const NigerianStates = require("../../utils/NigerianStates.json");
const KenyanCounties = require("../../utils/KenyanCounties.json");

export default function RegistrationForm({
  phoneNumber,
  setPhoneNumber,
  updateCountryCode,
}) {
  const appContext = useAppContext();

  const [phoneInputErrors, setPhoneInputErrors] = useState({});

  // const [whatsappInputErrors, setWhatsappInputErrors] = useState({});

  const [regions, setRegions] = useState([]);

  useEffect(() => {
    switch (appContext.appVendor) {
      case "tappi":
        setRegions(KenyanCounties);
        break;
      case "thryve":
        setRegions(NigerianStates);
        break;
      default:
        setRegions([]);
    }
  }, [appContext.appVendor]);

  const { actions, state } = useStateMachine({
    updateState,
    resetStoreValue,
  });

  const navigate = useNavigate();

  const { handleSubmit, register } = useForm({
    defaultValues: {
      businessName: state.registrationForm.business_name,
      email: state.registrationForm.email_address,
      state: state.registrationForm.state,
      location: state.registrationForm.location,
      password: state.registrationForm.password,
    },
  });

  // const [whatsappNumber, setWhatsappNumber] = useState(state.phoneNumber || "");

  const redirectTimeout = () =>
    setTimeout(() => navigate("/join/success"), 3000);

  useEffect(() => () => clearTimeout(redirectTimeout));

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { updateMerchantId } = useAuth();

  const { data: countries } = useQuery(["/country-list"], async () =>
    getCountryList()
  );

  async function handleRegisterMerchant(data) {
    const country = countries.filter(
      (c) => c.country_code.toLowerCase() === appContext.country.toLowerCase()
    );

    const payload = {
      email_address: data.email,
      phone_number: phoneNumber.slice(1),
      business_name: data.businessName,
      // whatsapp_number: whatsappNumber.slice(1),
      state: data.state,
      account_created: true,
      country_id: country[0].id,
      industry_id: 1,
      password: data.password,
      subdomain: slugifySubdomain(data.businessName),
    };

    actions.updateState({
      ...state,
      registrationForm: {
        ...payload,
      },
    });

    try {
      setIsSubmitting(true);

      const res = await registerMerchant(payload);

      updateMerchantId(res.data.id);
      toast.success("Your account was successfully created");

      // Clear state of the registration form
      actions.resetStoreValue("registrationForm");
      redirectTimeout();
    } catch (e) {
      console.error("Could not create merchant account : ", e);
      let message = "Could not create merchant account. Please try again.\n";
      if (e.response?.data?.detail) {
        if (typeof e.response.data.detail === "object") {
          // eslint-disable-next-line no-restricted-syntax
          for (const msg of e.response.data.detail) {
            message += `${msg.msg}\n`;
          }
        } else {
          message = e.response.data.detail;
        }
      }
      toast.error(message);
    } finally {
      setIsSubmitting(false);
    }
  }

  async function onSubmit(data) {
    if (!phoneNumber) {
      setPhoneInputErrors({ phoneNumber: "Phone number is required" });
      return;
    }

    if (!validatePhoneNumber(phoneNumber, appContext.country)) {
      setPhoneInputErrors({ phoneNumber: "Invalid phone number" });
      return;
    }

    // if (
    //   whatsappNumber &&
    //   !validatePhoneNumber(whatsappNumber, appContext.country)
    // ) {
    //   setWhatsappInputErrors({ phoneNumber: "Invalid whatsapp number" });
    //   return;
    // }

    setPhoneInputErrors({});

    await handleRegisterMerchant(data);
  }

  return (
    <fieldset
      className="h-full"
      disabled={isSubmitting}
      aria-busy={isSubmitting}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col h-full space-y-4"
      >
        <div>
          <h4 className="text-[20px] text-base leading-5 font-semibold mt-4">
            Get Started
          </h4>
          <p className="mb-2 text-[#566370]">Grow Your Business</p>
        </div>
        <div>
          <LabelledInput
            id="businessNameInput"
            placeholder="Business Name"
            label="Business Name"
            name="businessName"
            register={register}
            rules={{ required: true, minLength: 3 }}
          />
        </div>

        <div>
          <label htmlFor="phoneInput" className="text-gray-50 block mb-1">
            Phone Number <span style={{ color: "#CD3717" }}> *</span>
          </label>
          <CustomPhoneInput
            errors={phoneInputErrors}
            setErrors={setPhoneInputErrors}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            selectedCountryCode={appContext.country}
            setSelectedCountryCode={updateCountryCode}
            className="md"
            countries={appContext.SUPPORTED_COUNTRIES}
          />
          {appContext.appVendor === "thryve" ? (
            <span className="text-[#56637099] text-sm block mt-1">
              Please use a registered MTN number
            </span>
          ) : null}
        </div>

        <div>
          <LabelledInput
            id="emailInput"
            placeholder="Email"
            label="Email Address"
            name="email"
            register={register}
            rules={{ required: true }}
          />
        </div>

        <div>
          <PasswordInput
            id="passwordInput"
            placeholder="Password"
            label="Password"
            name="password"
            register={register}
            rules={{ required: true }}
            type="password"
          />
        </div>

        {/* <div>
          <label htmlFor="whatsappInput" className="text-gray-50 block mb-1">
            WhatsApp Number
          </label>
          <CustomPhoneInput
            errors={whatsappInputErrors}
            setErrors={setWhatsappInputErrors}
            phoneNumber={whatsappNumber}
            setPhoneNumber={setWhatsappNumber}
            selectedCountryCode={appContext.country}
            setSelectedCountryCode={updateCountryCode}
          />
        </div> */}

        <div>
          <label htmlFor="locationInput" className="text-gray-50">
            Location<span style={{ color: "#CD3717" }}> *</span>
          </label>
          <select
            className="form-select appearance-none block w-full h-[48px] px-3 py-1.5 text-sm font-normal text-gray-60 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded-lg transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-purple-40 focus:outline-none focus:shadow mt-2 thryve:focus:border-secondary-50 thryve:focus:shadow-[#0061874D]"
            aria-label="Select Location"
            required
            id="locationInput"
            name="state"
            {...register("state")}
          >
            <option value="">Select a state</option>
            {regions.map((region) => (
              <option key={region.label} value={region.value}>
                {region.label}
              </option>
            ))}
          </select>
        </div>

        <div className="text-center my-4">
          <p className="font-light text-sm text-grey-60">
            By signing up, you agree to our{" "}
            <a
              href="https://tappi.app/terms-of-use"
              className="text-purple-20 thryve:text-grey-60 underline"
            >
              Terms & Conditions
            </a>{" "}
            and
            <a
              href="https://tappi.app/privacy-policy"
              className="text-purple-20 thryve:text-grey-60 underline"
            >
              {" "}
              Privacy Policy
            </a>
            .
          </p>
        </div>

        <div className="text-center lg:text-left">
          <Button
            type="submit"
            disabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Create Account
          </Button>
        </div>
      </form>
    </fieldset>
  );
}

RegistrationForm.propTypes = {
  phoneNumber: PropTypes.string,
  setPhoneNumber: PropTypes.func.isRequired,
  updateCountryCode: PropTypes.func.isRequired,
};

RegistrationForm.defaultProps = {
  phoneNumber: "",
};
