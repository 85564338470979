import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import { getMerchantReviews } from "../requests";
import { useAuth } from "./AuthenticationContext";

const BusinessContext = createContext(null);

function useBusinessContext() {
  return useContext(BusinessContext);
}

// The BusinessProvider is used to store the business context, eg countryCode, name, logo, etc
function BusinessProvider({ children }) {
  const [business, setBusiness] = useState(null);

  const { user } = useAuth();

  const userId = user?.pk;

  const updateBusiness = (data) => {
    setBusiness((state) => ({
      ...state,
      ...data,
    }));
  };

  const clearBusinessContext = () => {
    setBusiness({});
  };

  // Get user reviews and update business context
  useEffect(() => {
    if (userId) {
      getMerchantReviews({
        joinCode: user.merchant.join_code,
        limit: 20,
      }).then((res) => {
        updateBusiness({ reviews: res });
      });
    }
  }, [userId]);

  const value = useMemo(() => ({
    updateBusiness,
    business,
    clearBusinessContext,
  }));

  return (
    <BusinessContext.Provider value={value}>
      {children}
    </BusinessContext.Provider>
  );
}

export { useBusinessContext, BusinessProvider };

BusinessProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
