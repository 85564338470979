import React from "react";
import { useStateMachine } from "little-state-machine";

import { useBusinessContext } from "../utils/Contexts/BusinessContext";
import MpesaSetup from "../components/MpesaSetup";
import NigerianBankSetup from "../components/NigerianBankSetup";
import { updateState } from "../utils/stateMachine";
import Spinner from "../components/Spinner";

export default function BankingSetup() {
  const businessContext = useBusinessContext();

  const { state } = useStateMachine({
    updateState,
  });

  // Check if the country code was already set during registration in the state machine
  if (state?.countryCode === "NG") {
    return <NigerianBankSetup />;
  }
  if (state?.countryCode === "KE") {
    return <MpesaSetup />;
  }

  // If not, we rely on the API call to get the state
  return (
    <div>
      {businessContext.business?.countryCode ? (
        <div>
          {businessContext.business.countryCode === "NG" ? (
            <NigerianBankSetup />
          ) : (
            <MpesaSetup />
          )}
        </div>
      ) : (
        // Spinner
        <div className="h-screen w-screen flex justify-center items-center">
          <Spinner />
        </div>
      )}
    </div>
  );
}
