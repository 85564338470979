/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useStateMachine } from "little-state-machine";

import { toast } from "react-hot-toast";
import ReactModal from "react-modal";
import moment from "moment/moment";
import Layout from "../components/layout/V2/Layout";
import {
  PAYMENT_REASONS,
  usePaymentContext,
} from "../utils/Contexts/V2/PaymentContext2";
import Button from "../components/forms/Button";
import InputError from "../components/forms/InputError";
import { resetStoreValue } from "../utils/stateMachine";
import { useSubscriptionContext } from "../utils/Contexts/V2/SubscriptionContext";
import { useAuth } from "../utils/Contexts/V2/AuthenticationContext";
import { useWalletContext } from "../utils/Contexts/V2/WalletContext";
import {
  getWalletTransactionDetails,
  updateSubscriptionPackage,
} from "../utils/requests-v2";
import { useAppContext } from "../utils/Contexts/AppContext";

const modalStyles = {
  content: {
    inset: "auto 0px 0px 0px",
    borderRadius: "16px 16px 0 0",
  },
  overlay: {
    backgroundColor: "#00000033",
  },
};

export default function MpesaPaymentPage() {
  const {
    paymentAmount,
    paymentFor,
    handleMpesaAdsPayment,
    handleMpesAdsPaybillPayment,
    handleSubscriptionMpesaPayment,
    handleTopUpWalletWithMpesa,
  } = usePaymentContext();

  const { ID_IDENTIFIER, topUpAmount } = useWalletContext();
  const { appVendor, appTheme } = useAppContext();

  const { selectedPlan, subscriptionPackages } = useSubscriptionContext();

  const { user } = useAuth();
  const { state, actions } = useStateMachine({ resetStoreValue });

  const [paymentReason, setPaymentReason] = useState(null);

  const [isConfirmPaymentModalVisible, setIsConfirmPaymentModalVisible] =
    useState(false);

  const navigate = useNavigate();

  const [subscriptionToPurchase, setSubscriptionToPurchase] = useState(null);

  useEffect(() => {
    if (selectedPlan && subscriptionPackages?.length) {
      const packageObject = subscriptionPackages.find(
        (p) => p.name === selectedPlan.plan
      );

      setSubscriptionToPurchase({
        id: packageObject.id,
        name: packageObject.name,
        interval: selectedPlan.rate,
      });
    }
  }, [selectedPlan, subscriptionPackages]);

  const [effectivePaymentAmount, setEffectivePaymentAmount] = useState(0);

  useEffect(() => {
    switch (paymentFor) {
      case PAYMENT_REASONS.ADS:
        setPaymentReason("Pay For Your Ad");
        setEffectivePaymentAmount(paymentAmount);
        break;
      case PAYMENT_REASONS.SUBSCRIPTION:
        setPaymentReason("Pay For Your Subscription");
        setEffectivePaymentAmount(paymentAmount);
        break;
      case PAYMENT_REASONS.CREDITS:
        setPaymentReason("Pay For Your Credits");
        setEffectivePaymentAmount(topUpAmount);
        break;
      default:
        setPaymentReason("Make Payment");
        break;
    }
  }, [paymentFor]);

  const [mpesaPayMethod, setMpesaPayMethod] = useState("phoneNumber");
  const [mpesaNumber, setMpesaNumber] = useState("");
  const [mpesaRefNumber, setMpesaRefNumber] = useState("");

  const [inputErrors, setInputErrors] = useState({
    phoneNumber: "",
    refNumber: "",
  });

  function clearInputErrors() {
    setInputErrors({
      phoneNumber: "",
      refNumber: "",
    });
  }

  const redirectTimeout = () =>
    setTimeout(() => {
      navigate(`/payments/success?for=${paymentFor}`);
    }, [5000]);

  useEffect(() => () => clearTimeout(redirectTimeout), []);

  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmPayment = async ({ phoneNumber }) => {
    console.log("Attempting to confirm payment");
    let walletNumber;
    if (mpesaNumber) {
      walletNumber = `254${mpesaNumber}`;
    } else {
      walletNumber = phoneNumber;
    }

    console.log("Wallet number is : ", walletNumber);

    try {
      const transactions = await getWalletTransactionDetails(
        `${ID_IDENTIFIER[appVendor]}-${walletNumber}`
      );

      if (
        transactions.data[0].type === "MPESA" &&
        transactions.data[0].user_phone_number === `${walletNumber}` &&
        transactions.data[0].amount.toString() ===
          effectivePaymentAmount.toString()
      ) {
        const minutesElapsed = moment(transactions.data[0].created_at).diff(
          moment(),
          "minutes"
        );

        // Ensure minutes elapsed is not more than 5 minutes
        if (minutesElapsed < -5) {
          console.error("Time elapsed too long ago : ", minutesElapsed);
          toast.error(
            "We could not confirm your payment. If you think this was an error, please reach out to the support team at +254715412061",
            {
              duration: 30000,
            }
          );
          return false;
        }
        // updateSubscriptionPackage({
        //   packageId: subscriptionToPurchase.id,
        //   interval: subscriptionToPurchase.interval,
        // })
        //   .then(() => {
        //     toast.success("Subscription Package Successfully Purchased");
        //   })
        //   .catch((error) => {
        //     console.log("Could not update subscription package", error);
        //     toast.error("Could not purchase subscription. Please try again");
        //   });

        toast.success("Payment was successfully processed.");
        return true;
      }
      // This block runs if the most recent transaction is not a wallet_topup or the amount does not match the subscription cost
      console.group("Could not confirm wallet topup : ", transactions.data[0]);
      console.log(transactions.data[0].type);
      console.log(transactions.data[0].user_phone_number, " ", walletNumber);
      console.log(transactions.data[0].amount, " ", effectivePaymentAmount);

      console.groupEnd();

      toast.error(
        "We could not confirm your payment. If you think this was an error, please reach out to the support team at +254715412061",
        {
          duration: 30000,
        }
      );

      return false;
    } catch (error) {
      console.error("Could not confirm payment : ", error);
      return false;
    } finally {
      setIsConfirmPaymentModalVisible(false);
    }
  };

  const handlePayment = useCallback(async () => {
    if (mpesaPayMethod === "phoneNumber") {
      if (!mpesaNumber) {
        setInputErrors({
          ...inputErrors,
          phoneNumber: "Phone Number Is Required",
        });
        return;
      }

      if (mpesaNumber.length !== 9) {
        setInputErrors({
          ...inputErrors,
          phoneNumber: "Please provide a valid phone number",
        });
        return;
      }

      setIsLoading(true);
      if (paymentFor === PAYMENT_REASONS.ADS) {
        await handleMpesaAdsPayment({
          phoneNumber: `+254${mpesaNumber}`,
        })
          .then(async () => {
            actions.resetStoreValue("adJourney");
            actions.resetStoreValue("paymentInfo");

            toast.success("AD Successfully created");
            redirectTimeout();
          })
          .catch((error) => {
            console.error(error);
            if (error.info) {
              toast.error(error.info);
            } else {
              toast.error("Could not create ad");
            }
          });
        setIsLoading(false);
      } else if (paymentFor === PAYMENT_REASONS.SUBSCRIPTION) {
        setIsLoading(true);
        handleSubscriptionMpesaPayment({
          phoneNumber: `+254${mpesaNumber}`,
        }).then(() => {
          setIsConfirmPaymentModalVisible(true);
        });
        setIsLoading(false);
      } else if (paymentFor === PAYMENT_REASONS.CREDITS) {
        setIsLoading(true);
        handleTopUpWalletWithMpesa({
          phoneNumber: `+254${mpesaNumber}`,
          amount: effectivePaymentAmount,
        }).then(() => {
          setIsConfirmPaymentModalVisible(true);
        });
        setIsLoading(false);
      }
    } else if (mpesaPayMethod === "paybill") {
      if (!mpesaRefNumber) {
        setInputErrors({
          ...inputErrors,
          refNumber: "Ref Number Is Required",
        });
        return;
      }
      if (mpesaRefNumber.length !== 10) {
        setInputErrors({
          ...inputErrors,
          refNumber: "Please provide a valid ref number",
        });
        return;
      }

      if (paymentFor === PAYMENT_REASONS.ADS) {
        setIsLoading(true);
        await handleMpesAdsPaybillPayment({
          ref: mpesaRefNumber,
        })
          .then(() => {
            toast.success("AD Successfully created");
            redirectTimeout();
          })
          .catch((error) => {
            console.error(error);
            if (error.message) {
              toast.error(error.message);
            } else {
              toast.error("Could not create ad");
            }
          })
          .finally(() => setIsLoading(false));
      } else if (paymentFor === PAYMENT_REASONS.SUBSCRIPTION) {
        console.log("Attempting to purchase subscription via paybill");
        await handleConfirmPayment({ phoneNumber: user.phone_number });
      } else if (paymentFor === PAYMENT_REASONS.CREDITS) {
        console.log("Attempting to purchase credits via paybill");
        setIsConfirmPaymentModalVisible(true);
      }
    }
  }, [mpesaNumber, mpesaRefNumber, mpesaPayMethod]);

  // Users should only pay for ads after they have completed all steps
  useEffect(() => {
    if (paymentFor === PAYMENT_REASONS.ADS && state.adJourney.stepsDone !== 3) {
      navigate("/ads/create");
    }
  }, [paymentFor]);

  // Confirm that the wallet has been credited in the past 5 minutes with the amount the user topped up

  const timeoutRef = useRef(null);
  const [countdown, setCountdown] = useState(-1);

  // We will automatically attempt to confirm the payment after 5 minutes if the user doesn't manually confirm
  const startConfirmPaymentTimeout = (time = 30000) => {
    setCountdown(time);
  };

  const clearConfirmPaymentTimeout = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
    setCountdown(-1);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handlePaymentRejected = () => {
    clearConfirmPaymentTimeout();
    toast.error("Your payment was not confirmed. Please try again");
    setIsConfirmPaymentModalVisible(false);
  };

  useEffect(() => {
    if (isConfirmPaymentModalVisible) {
      startConfirmPaymentTimeout(30000);
    } else {
      clearConfirmPaymentTimeout();
    }
  }, [isConfirmPaymentModalVisible]);

  // We check if there is a current countdown running...
  // If there is countdowown, run it down by 1 second till it reaches zero
  useEffect(() => {
    if (countdown > 0) {
      // console.log("Countdown is greater than zero : ", countdown)
      timeoutRef.current = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else if (countdown === 0) {
      // If the countdown reaches zero, we attempt to mannually confirm payment
      // console.log("Countdown is zero or less : ", countdown)
      handleConfirmPayment({ phoneNumber: mpesaNumber });
    }

    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [countdown]);

  // Clear timeouts and dismiss toasts when the component unmounts
  useEffect(
    () => () => {
      clearConfirmPaymentTimeout();
      toast.remove();
    },
    []
  );

  return (
    <Layout
      header
      headerTitle={
        <div className="flex flex-col items-center justify-center">
          <p className="text-xs font-normal text-gray-500 text-center">
            Make MPESA Payment
          </p>
          <h1 className="text-base">{paymentReason}</h1>
        </div>
      }
    >
      <ReactModal
        isOpen={isConfirmPaymentModalVisible}
        onRequestClose={() => setIsConfirmPaymentModalVisible(false)}
        style={modalStyles}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
      >
        <div className={appTheme}>
          <div className="space-y-2 text-xs mb-4 text-[#566370]">
            <p>
              You should have received an prompt on your MPESA number. Please
              enter your MPESA pin then press confirm below to confirm your
              payment.
            </p>
            <p>
              We will automatically attempt to verify your payment within 5
              minutes if you do not manually confirm.
            </p>
          </div>

          <div className="space-y-2">
            <Button
              onClick={() => {
                clearTimeout(timeoutRef.current);
                handleConfirmPayment({
                  phoneNumber: mpesaNumber || user.phone_number,
                });
              }}
            >
              Confirm
            </Button>
            <Button variant="outline" onClick={handlePaymentRejected}>
              Cancel
            </Button>
          </div>
        </div>
      </ReactModal>
      <div className="py-20">
        <div className="text-center mx-auto mb-6">
          <p className="text-sm font-medium mt-2">You are about to pay</p>
          <p className="text-xl font-semibold text-[#1A1A1A] mt-2">
            KES {effectivePaymentAmount}
          </p>
        </div>
        <fieldset
          className="flex flex-col h-full px-4 space-y-4 md:w-[768px] mx-auto"
          disabled={isLoading}
          aria-busy={isLoading}
        >
          <div className="border rounded-lg px-4 py-2">
            <label
              htmlFor="mpesaNumberRadio"
              className="cursor-pointer p-4"
              title="Pay With Your Phone Number"
            >
              <div className="flex justify-between items-center">
                <h3 className="font-semibold text-sm">
                  Enter Phone Number To Pay
                </h3>
                <input
                  type="radio"
                  name="mpesa"
                  id="mpesaNumberRadio"
                  value="phoneNumber"
                  checked={mpesaPayMethod === "phoneNumber"}
                  onChange={(e) => {
                    clearInputErrors();
                    setMpesaPayMethod(e.target.value);
                  }}
                  className="form-check-input appearance-none rounded-full h-[1.4rem] w-[1.4rem] border border-gray-300 bg-white checked:bg-purple-50 checked:border-blue-600 focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain"
                />
              </div>
            </label>
            {mpesaPayMethod === "phoneNumber" && (
              <div>
                <div className="pt-2 px-4 pb-3 bg-[#E7E7E7] text-[#566370] font-semibold text-[0.81rem] mb-4">
                  <p>
                    Enter your M-Pesa mobile number and click on the Confirm
                    button below.
                  </p>
                </div>
                <div
                  className={`flex flex-row justify-start items-center w-full py-5 px-4 border border-solid rounded-lg space-x-2 mb-2 ${
                    inputErrors.phoneNumber ? "border-red-500" : ""
                  }`}
                >
                  <p className="text-[#1A1A1A] font-semibold text-sm">+254</p>
                  <svg
                    width="1"
                    height="24"
                    viewBox="0 0 1 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="1" height="24" fill="#E7E7E7" />
                  </svg>
                  <input
                    className="h-full w-full outline-none text-[#566370] text-sm font-semibold appearance-none"
                    type="number"
                    onChange={(e) => {
                      clearInputErrors();
                      setMpesaNumber(e.target.value);
                    }}
                    value={mpesaNumber}
                    name="mpesaNumber"
                    required
                    minLength={9}
                    maxLength={9}
                  />
                </div>
                {inputErrors.phoneNumber && (
                  <div className="p-2">
                    <InputError error={inputErrors.phoneNumber} />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="border rounded-lg p-4">
            <label
              htmlFor="mpesaPaybillRadio"
              className="flex justify-between items-center py-4 cursor-pointer"
              title="Pay Via Paybill"
            >
              <h3 className="font-semibold text-sm">Pay Via Paybill</h3>
              <input
                name="paybill"
                type="radio"
                id="mpesaPaybillRadio"
                checked={mpesaPayMethod === "paybill"}
                value="paybill"
                onChange={(e) => {
                  clearInputErrors();
                  setMpesaPayMethod(e.target.value);
                }}
                className="form-check-input appearance-none rounded-full h-[1.4rem] w-[1.4rem] border border-gray-300 bg-white checked:bg-purple-50 checked:border-blue-600 focus:outline-none transition duration-200 bg-no-repeat bg-center bg-contain"
              />
            </label>
            {mpesaPayMethod === "paybill" && (
              <div className="flex flex-col space-y-4">
                <div>
                  <p className="pt-2 px-4 pb-3 bg-[#E7E7E7] text-[#566370] font-semibold text-[0.81rem]">
                    Follow the steps below. Once you receive a successful reply
                    from Mpesa, enter the reference number and click on the
                    Confirm button below.
                  </p>
                </div>
                <div className="flex flex-row justify-start items-center w-full py-5 px-4 border border-solid rounded-lg space-x-2">
                  <ul className="h-full outline-none text-[#1A1A1A] text-sm font-normal list-disc ml-3 leading-6">
                    <li>Go to M-PESA on your phone</li>
                    <li>Select Pay Bill option</li>
                    <li>
                      Enter Business no. <b>4114987</b>
                    </li>
                    <li>
                      Enter Account no. <b>SUB{user.id}</b>
                    </li>
                    <li>
                      Enter the Amount. <b>KES {effectivePaymentAmount}</b>
                    </li>
                    <li>Enter your M-PESA PIN and Send</li>
                    <li>You will receive a confirmation SMS from M-PESA</li>
                  </ul>
                </div>
                <div className="flex flex-col justify-start items-start w-full space-y-3">
                  <div
                    className={`flex flex-row justify-start items-center w-full py-5 px-4 border border-solid rounded-lg space-x-2 `}
                  >
                    <p className="text-[#1A1A1A] font-semibold text-sm">
                      Ref no.
                    </p>
                    <svg
                      width="1"
                      height="24"
                      viewBox="0 0 1 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="1" height="24" fill="#E7E7E7" />
                    </svg>
                    <input
                      className="h-full w-[65%] outline-none text-[#566370] text-sm font-semibold "
                      type="text"
                      maxLength={10}
                      onChange={(e) => {
                        clearInputErrors();
                        setMpesaRefNumber(e.target.value);
                      }}
                    />
                  </div>
                  {inputErrors.refNumber && (
                    <div className="p-2">
                      <InputError error={inputErrors.refNumber} />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <Button type="button" onClick={handlePayment}>
            {isLoading ? "Processing Payment..." : "Confirm"}
          </Button>
        </fieldset>
      </div>
    </Layout>
  );
}
