import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import { toast } from "react-hot-toast";
import {
  getBankList,
  getPaymentInfo,
  savePaymentInfo,
} from "../../requests-v2";
import { useAuth } from "./AuthenticationContext";

const PaymentInfoContext = createContext(null);

function usePaymentInfoContext() {
  return useContext(PaymentInfoContext);
}

function PaymentInfoProvider({ children }) {
  const { isAuthenticated, user } = useAuth();
  const [isLoadingPaymentInfo, setIsLoadingPaymentInfo] = useState(false);
  const [isSavingPaymentInfo, setIsSavingPaymentInfo] = useState(false);
  const [bankList, setBankList] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState(null);
  // const [bankInfo, setBankInfo] = useState({
  //   bank_id: "",
  //   bank_name: "",
  //   account_number: "",
  //   account_name: "",
  // });
  const [errorField, setErrorField] = useState([]);

  const getBankId = (name) =>
    bankList.filter((item) => item.bank_name === name)[0].bank_id;

  const setBankInfoHandler = (e) => {
    if (e.target.id === "bank_name") {
      setPaymentInfo({
        ...paymentInfo,
        bank_name: e.target.value,
        bank_id: getBankId(e.target.value),
      });
    } else if (e.target.id === "account_name") {
      setPaymentInfo({ ...paymentInfo, account_name: e.target.value });
    } else {
      setPaymentInfo({ ...paymentInfo, account_number: e.target.value });
    }
  };

  const bankInfoFieldBlurHandler = (e) => {
    if (!e.target.value) {
      setErrorField([...errorField, e.target.id]);
    } else {
      const newErrorField = errorField.filter(
        (fieldId) => fieldId !== e.target.id
      );
      setErrorField(newErrorField);
    }

    if (e.target.value.length !== 10 && e.target.id === "account_number") {
      setErrorField([...errorField, e.target.id]);
      toast.error("Account Number must be 10 characters long");
    }
  };

  const savePaymentInfoHandler = (callback) => {
    if (errorField.length === 0) {
      setIsSavingPaymentInfo(true);
      savePaymentInfo({
        bank_name: paymentInfo.bank_name,
        account_number: paymentInfo.account_number,
        bank_id: paymentInfo.bank_id,
      })
        .then((res) => {
          setIsSavingPaymentInfo(false);
          toast.success("Bank Details save successfully");
          return res;
        })
        .then(() => {
          setIsLoadingPaymentInfo(true);
          return getPaymentInfo();
        })
        .then((res) => {
          setIsLoadingPaymentInfo(false);
          setPaymentInfo(res.data);
          callback("/account");
        })
        .catch((err) => {
          setIsSavingPaymentInfo(false);
          toast.error(
            err?.response?.data?.detail || "Could not save payment info!"
          );
          return Promise.reject(err);
        });
    } else {
      toast.error("Check that all fields are filled");
    }
  };

  useEffect(() => {
    if (isAuthenticated && user?.country?.country_code === "NG") {
      setIsLoadingPaymentInfo(true);
      getBankList()
        .then((res) => {
          setBankList(res.data);
        })
        .then(() => getPaymentInfo())
        .then((res) => {
          setIsLoadingPaymentInfo(false);
          setPaymentInfo(res.data);
          return Promise.resolve(res);
        })
        .catch((err) => {
          setIsLoadingPaymentInfo(false);
          toast.error(
            err?.response?.data?.detail[0]?.msg || "Something went wrong!"
          );
          return Promise.reject(err);
        });
    }
  }, [isAuthenticated, user]);

  const value = useMemo(() => ({
    bankList,
    paymentInfo,
    isLoadingPaymentInfo,
    isSavingPaymentInfo,
    errorField,
    bankInfoFieldBlurHandler,
    setBankInfoHandler,
    savePaymentInfoHandler,
  }));

  return (
    <PaymentInfoContext.Provider value={value}>
      {children}
    </PaymentInfoContext.Provider>
  );
}

export { usePaymentInfoContext, PaymentInfoProvider };

PaymentInfoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
