/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { v4 as uuid } from "uuid";
import { toast } from "react-hot-toast";

import ClockIcon from "../../assets/icons/clock-icon.png";
import Button from "../forms/Button";
import { updateMerchantOperationHours } from "../../utils/requests";
import { useAuth } from "../../utils/Contexts/V2/AuthenticationContext";

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const times = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];

export default function UpdateWorkingHoursForm() {
  const { user, getUserToken } = useAuth();

  const navigate = useNavigate();

  const redirectTimeOut = () =>
    setTimeout(() => {
      navigate("/account/profile");
    }, 2000);

  const [defaultWorkingHours, setDefaultWorkingHours] = useState(
    user?.operation_hours
  );
  const [isUpdating, setIsUpdating] = useState(false);

  function sortByDay(arr) {
    arr.sort((a, b) => days.indexOf(a.day) - days.indexOf(b.day));
    return arr;
  }

  const checkHandler = (selectedDay, e) => {
    const newOperationHours = [...defaultWorkingHours];
    const indexOfCheckedDay = newOperationHours.findIndex(
      (d) => d.day === selectedDay
    );
    if (e.target.checked) {
      newOperationHours[indexOfCheckedDay].open_time = "08:00";
      newOperationHours[indexOfCheckedDay].close_time = "17:00";
    } else {
      newOperationHours[indexOfCheckedDay].open_time = "00:00";
      newOperationHours[indexOfCheckedDay].close_time = "00:00";
    }
    setDefaultWorkingHours(sortByDay(newOperationHours));
  };

  const updateOpshourHandler = (selectedDay, e) => {
    const updateWorkingHours = [...defaultWorkingHours];
    const indexOfDay = updateWorkingHours.findIndex(
      (d) => d.day === selectedDay
    );
    console.log("id", e.target.id);
    if (e.target.id === "open") {
      updateWorkingHours[indexOfDay].open_time = e.target.value;
    }

    if (e.target.id === "close") {
      updateWorkingHours[indexOfDay].close_time = e.target.value;
    }
    setDefaultWorkingHours(sortByDay(updateWorkingHours));
  };

  const updateWorkingHoursHandler = async () => {
    setIsUpdating(true);
    try {
      updateMerchantOperationHours(defaultWorkingHours, getUserToken());
      toast.success("Successfully update your working hours");
      redirectTimeOut();
    } catch (error) {
      toast.error("Something went wrong. Please try again");
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <form className="flex flex-col w-full mt-6 h-full">
      {defaultWorkingHours.map((day, index) => (
        <div
          className={`flex justify-between my-2 ${
            index === 6 ? "flex-grow items-start" : "items-center"
          }`}
          key={uuid()}
        >
          <div className="form-check form-switch flex justify-between py-2 w-[120px] pl-0">
            <label
              className="form-check-label block text-gray-800 text-xs"
              htmlFor={`${day.day}Input`}
            >
              {day.day}
            </label>
            <input
              className="form-check-input appearance-none -ml-10 rounded-full float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm h-4 w-8"
              type="checkbox"
              role="switch"
              id={`${day.day}Input`}
              checked={day.open_time !== "00:00" || day.close_time !== "00:00"}
              onChange={(e) => checkHandler(day.day, e)}
            />
          </div>

          {day.open_time !== "00:00" || day.close_time !== "00:00" ? (
            <div className="flex items-center ml-1">
              <div className="relative">
                <img
                  src={ClockIcon}
                  alt="Clock Icon"
                  width={10}
                  height={10}
                  className="absolute top-[10px] left-[6px]"
                />
                <div>
                  <select
                    className="form-select appearance-none bg-none block px-3 py-1.5 text-xs font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none w-[92px] pl-[20px] appearance-none "
                    aria-label="Set Opening Time"
                    id="open"
                    value={day.open_time}
                    onChange={(e) => updateOpshourHandler(day.day, e)}
                  >
                    {times.map((time) => (
                      <option key={time} value={time}>
                        {time}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <p className="mx-2">to</p>
              <div className="relative">
                <img
                  src={ClockIcon}
                  alt="Clock Icon"
                  width={10}
                  height={10}
                  className="absolute top-[10px] left-[6px]"
                />
                <div>
                  <select
                    className="form-select appearance-none bg-none block px-3 py-1.5 text-xs font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none w-[92px] pl-[20px] appearance-none "
                    aria-label="Set Closing Time"
                    id="close"
                    value={day.close_time}
                    onChange={(e) => updateOpshourHandler(day.day, e)}
                  >
                    {times.map((time) => (
                      <option key={time} value={time}>
                        {time}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          ) : (
            <p className="text-xs">Closed</p>
          )}
        </div>
      ))}
      {/* </>
        )} */}

      <div className="text-center lg:text-left mb-2">
        <Button type="button" onClick={updateWorkingHoursHandler}>
          {isUpdating ? "Updating..." : "Update"}
        </Button>
      </div>
    </form>
  );
}
