import React from "react";
import PropTypes from "prop-types";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import requestMoney from "../../assets/icons/request_payment.svg";
import promoteYourBusiness from "../../assets/icons/promote_your_business.svg";
import walletTopUp from "../../assets/icons/walletTopUp.svg";
import addProductPhoto from "../../assets/icons/add_product_photo.svg";
import requestReview from "../../assets/icons/request_review.svg";
import { useContact } from "../../utils/Contexts/V2/ContactContext";
import { resetStoreValue } from "../../utils/stateMachine";

function Menu({ close, showDialogueHandler }) {
  const { setShowAddCustomerPrompt } = useContact();

  const requestReviewHandler = () => {
    showDialogueHandler(true, "Request Review");
    close(false);
  };

  const addCustomerHandler = () => {
    setShowAddCustomerPrompt(true);
    close(false);
  };

  const navigate = useNavigate();

  const { actions } = useStateMachine({ resetStoreValue });

  // Reset the ad journey anytime a user wants to create a new ad
  const createAdHandler = () => {
    actions.resetStoreValue("adJourney");
    navigate("/ads/create");
  };

  return (
    <div className="fixed bottom-0 z-20 w-full h-screen  flex">
      <div className="w-full pt-7 px-4 pb-9 rounded-t-2xl bg-white self-end">
        <div className="w-full flex flex-row justify-between items-center pb-7 h-12">
          <h2>What would you like to do?</h2>
          <button type="button" onClick={() => close(false)}>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
        <div className="w-full flex flex-col items-start justify-center space-y-6">
          <button
            className="flex flex-row justify-start items-start space-x-3"
            type="button"
            onClick={createAdHandler}
          >
            <img src={promoteYourBusiness} alt="Promote your business" />
            <h2 className="font-sans font-semibold text-[0.93rem]">
              Promote your business
            </h2>
          </button>
          <Link
            to="/create-offers"
            className="flex flex-row justify-start items-start space-x-3"
          >
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="14" cy="14" r="14" fill="#E37333" />
              <g clipPath="url(#clip0_8068_183)">
                <path
                  d="M13.3586 8.2251L19.133 9.05051L19.9578 14.8255L14.5958 20.1875C14.4864 20.2969 14.3381 20.3583 14.1834 20.3583C14.0287 20.3583 13.8804 20.2969 13.771 20.1875L7.99599 14.4125C7.88663 14.3031 7.8252 14.1548 7.8252 14.0001C7.8252 13.8454 7.88663 13.6971 7.99599 13.5877L13.3586 8.2251ZM15.0082 13.1753C15.1166 13.2836 15.2452 13.3695 15.3868 13.4281C15.5283 13.4867 15.6801 13.5169 15.8333 13.5168C15.9865 13.5168 16.1382 13.4866 16.2797 13.4279C16.4213 13.3693 16.5499 13.2833 16.6582 13.175C16.7665 13.0666 16.8524 12.938 16.911 12.7964C16.9696 12.6549 16.9998 12.5031 16.9998 12.3499C16.9997 12.1967 16.9695 12.045 16.9109 11.9035C16.8522 11.7619 16.7663 11.6333 16.6579 11.525C16.5495 11.4167 16.4209 11.3308 16.2794 11.2722C16.1378 11.2136 15.9861 11.1834 15.8329 11.1834C15.5234 11.1835 15.2267 11.3065 15.0079 11.5253C14.7892 11.7441 14.6663 12.0409 14.6664 12.3503C14.6664 12.6598 14.7894 12.9565 15.0082 13.1753Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_8068_183">
                  <rect
                    width="14"
                    height="14"
                    fill="white"
                    transform="translate(7 7)"
                  />
                </clipPath>
              </defs>
            </svg>

            <h2 className="font-sans font-semibold text-[0.93rem]">
              Send Offers (Bulk sms)
            </h2>
          </Link>
          <NavLink
            to="/request-payment"
            className=" flex-row justify-start items-start space-x-3 hidden thryve:flex "
          >
            <img src={requestMoney} alt="Request payment" />
            <h2 className="font-sans font-semibold text-[0.93rem]">
              Request Payment
            </h2>
          </NavLink>
          <NavLink
            to="/add-photos"
            className="flex flex-row justify-start items-start space-x-3"
          >
            <img src={addProductPhoto} alt="Add Product" />
            <h2 className="font-sans font-semibold text-[0.93rem]">
              Add Product Photo
            </h2>
          </NavLink>
          <button
            type="button"
            onClick={requestReviewHandler}
            className="flex flex-row justify-start items-start space-x-3"
          >
            <img src={requestReview} alt="Request Review" />
            <h2 className="font-sans font-semibold text-[0.93rem]">
              Request Review
            </h2>
          </button>
          <button
            type="button"
            onClick={addCustomerHandler}
            className="flex flex-row justify-start items-start space-x-3"
          >
            <svg
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="14" cy="14" r="14" fill="#CB04FF" />
              <path
                d="M13.9999 14.0001C15.5469 14.0001 16.7999 12.7471 16.7999 11.2001C16.7999 9.65315 15.5469 8.40015 13.9999 8.40015C12.4529 8.40015 11.1999 9.65315 11.1999 11.2001C11.1999 12.7471 12.4529 14.0001 13.9999 14.0001ZM13.9999 9.80015C14.7699 9.80015 15.3999 10.4301 15.3999 11.2001C15.3999 11.9701 14.7699 12.6001 13.9999 12.6001C13.2299 12.6001 12.5999 11.9701 12.5999 11.2001C12.5999 10.4301 13.2299 9.80015 13.9999 9.80015ZM13.9999 14.7001C12.1309 14.7001 8.3999 15.6381 8.3999 17.5001V18.9001C8.3999 19.2851 8.7149 19.6001 9.0999 19.6001H18.8999C19.2849 19.6001 19.5999 19.2851 19.5999 18.9001V17.5001C19.5999 15.6381 15.8689 14.7001 13.9999 14.7001ZM18.1999 18.2001H9.7999V17.5071C9.9399 17.0031 12.1099 16.1001 13.9999 16.1001C15.8899 16.1001 18.0599 17.0031 18.1999 17.5001V18.2001Z"
                fill="white"
              />
            </svg>

            <h2 className="font-sans font-semibold text-[0.93rem]">
              Add Customer
            </h2>
          </button>
          <NavLink
            to="/wallet"
            className="flex flex-row justify-start items-start space-x-3"
          >
            <img src={walletTopUp} alt="Top Up Wallet" />
            <h2 className="font-sans font-semibold text-[0.93rem]">
              Top Up Wallet
            </h2>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

Menu.defaultProps = {
  showDialogueHandler: null,
};

Menu.propTypes = {
  close: PropTypes.func.isRequired,
  showDialogueHandler: PropTypes.func,
};

export default Menu;
