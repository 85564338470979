/* eslint-disable camelcase */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import {
  checkFlutterwaveStatus,
  checkPaybillPaymentStatus,
  getAllSubscriptionHistory,
  getSubscriptionPackages,
  paySubscriptionFlutterwave,
  makeMpesaPayment,
  checkPromoCode,
  updateSubscriptionPackage,
} from "../requests";
import { usePaymentContext } from "./PaymentContext";
import { useAuth } from "./AuthenticationContext";

const BILLING_PLANS = [
  {
    id: 2,
    name: "Silver",
    price: {
      Monthly: {
        Kenya: 1500,
        Nigeria: 5400,
      },
      Quarterly: {
        Kenya: 4500,
        Nigeria: 16200,
      },
      Yearly: {
        Kenya: 13500,
        Nigeria: 48600,
      },
    },
    benefits: {
      recommended: true,
      benefitsDetails: [
        {
          name: "A professional business website",
          availability: true,
        },
        {
          name: "1 social media Ad (10,000 views)",
          availability: true,
        },
        {
          name: "Reviews from customers online",
          availability: true,
        },
        {
          name: "150 verified reviews",
          availability: true,
        },
        {
          name: "Be found on google",
          availability: true,
        },
        {
          name: "Insights on Ads",
          availability: true,
        },
        {
          name: "Customer engagement insights",
          availability: false,
        },
      ],
    },
  },
  {
    id: 1,
    name: "Bronze",
    price: {
      Monthly: {
        Kenya: 500,
        Nigeria: 1800,
      },
      Quarterly: {
        Kenya: 1500,
        Nigeria: 5400,
      },
      Yearly: {
        Kenya: 4500,
        Nigeria: 16200,
      },
    },
    benefits: {
      recommended: false,
      benefitsDetails: [
        {
          name: "A professional business website",
          availability: true,
        },
        {
          name: "Facebook & Google Ads",
          availability: true,
        },
        {
          name: "Reviews from customers online",
          availability: true,
        },
        {
          name: "Verified reviews",
          availability: false,
        },
        {
          name: "Be found on google",
          availability: false,
        },
        {
          name: "Insights on payments",
          availability: false,
        },
        {
          name: "Insights on Ads",
          availability: false,
        },
        {
          name: "Customer engagement insights",
          availability: false,
        },
      ],
    },
  },
  {
    id: 3,
    name: "Gold",
    price: {
      Monthly: {
        Kenya: 3000,
        Nigeria: 10800,
      },
      Quarterly: {
        Kenya: 9000,
        Nigeria: 32400,
      },
      Yearly: {
        Kenya: 27000,
        Nigeria: 97200,
      },
    },
    benefits: {
      recommended: false,
      benefitsDetails: [
        {
          name: "A professional business website",
          availability: true,
        },
        {
          name: "1 social media Ad (10,000 views)",
          availability: true,
        },
        {
          name: "Reviews from customers online",
          availability: true,
        },
        {
          name: "350 Verified reviews",
          availability: true,
        },
        {
          name: "Be found on google",
          availability: true,
        },
        {
          name: "Insights on payments",
          availability: true,
        },
        {
          name: "Insights on Ads",
          availability: true,
        },
        {
          name: "Customer engagement insights",
          availability: true,
        },
      ],
    },
  },
];

const SubscriptionContext = createContext(null);

function useSubscriptionContext() {
  return useContext(SubscriptionContext);
}

function SubscriptionProvider({ children }) {
  const {
    country,
    selectedPayment,
    referenceNumber,
    msisdn,
    mpesaPayPhoneNumber,
    tathminiId,
    setInputError,
    flutterwavePaymentDetails,
    setFlutterwavePaymentDetails,
    promoCode,
  } = usePaymentContext();
  const { user } = useAuth();
  const [subscriptionHistory, setSubscriptionHistory] = useState(null);
  const [subscriptionPackages, setSubscriptionPackages] = useState(null);
  const [
    isLoadingFlutterwavePaymentDetails,
    setIsLoadingFlutterwavePaymentDetails,
  ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(
    JSON.parse(localStorage.getItem("SELECTED_PLAN"))
  );
  const paymentData = {
    phone_number: mpesaPayPhoneNumber,
    amount: selectedPlan?.amount.toString() || 0,
  };
  const flutterwavePaymentData = {
    msisdn,
    amount: selectedPlan?.amount.toString() || 0,
  };
  const subscriptionPackageData = {
    subscription_id:
      subscriptionPackages?.filter(
        (item) => item.name === selectedPlan?.plan
      )[0]?.id || 1,
    interval: selectedPlan?.plan,
  };
  const subscriptionData = {
    subscriptionPackage: user?.merchant.subscription_package,
    subscriptionAmount: user?.merchant.subscription_amount,
    subscriptionExpiryDate: user?.merchant.subscription_expiry_date,
    subscriptionStatus: user?.merchant.subscription_status,
    subscriptionInterval: user?.merchant.interval,
    country: user?.country_name,
  };

  const selectedPlanHandler = (plan, callback) => {
    setSelectedPlan(plan);
    callback("/account/subscription/payment");
  };

  const paymentNavigationHandler = (callback) => {
    if (selectedPayment.mpesa) {
      callback("/account/subscription/payment/mpesa");
    } else {
      callback("/account/subscription/payment/promo_code");
    }
  };

  // A helper function to throw an error when an undesired response is gotten from a call
  const errorHandler = (e) => {
    throw e;
  };

  // Subscription Payment with Mpesa
  const mpesaPay = async (callback) => {
    if (mpesaPayPhoneNumber && mpesaPayPhoneNumber.length === 13) {
      setIsLoading(true);
      makeMpesaPayment(paymentData)
        .then((res) => {
          if (res.data.status === 400) {
            setErrorMessage("Transaction Unsuccessful");
            return errorHandler();
          }
          return updateSubscriptionPackage(tathminiId, subscriptionPackageData);
        })
        .then((res) => {
          setIsLoading(false);
          callback("/account/subscription/status");
          Promise.resolve(res);
        })
        .catch((error) => {
          setShowError(true);
          setIsLoading(false);
          Promise.reject(error);
          setTimeout(() => setShowError(false), 5000);
        });
    } else {
      setInputError(true);
    }
  };

  // Subscription Payment with Flutterwave
  const flutterwavePay = async () => {
    setIsLoadingFlutterwavePaymentDetails(true);
    paySubscriptionFlutterwave(flutterwavePaymentData)
      .then((res) => {
        if (res.data.status === "failed") {
          setErrorMessage("Service currently unavailable");
          setShowError(true);
          setTimeout(() => setShowError(false), 5000);
          return errorHandler();
        }
        setIsLoadingFlutterwavePaymentDetails(false);
        setFlutterwavePaymentDetails(res.data);
        return Promise.resolve(res);
      })
      .catch((error) => {
        setShowError(true);
        setIsLoadingFlutterwavePaymentDetails(false);
        Promise.reject(error);
        setTimeout(() => setShowError(false), 5000);
      });
  };

  // Confirm status Subscription Payment with Flutterwave
  const confirmFlutterwavePaymentStatus = async (callback) => {
    const payRef = {
      transfer_reference:
        flutterwavePaymentDetails.meta.authorization.transfer_reference,
    };
    setIsLoading(true);
    checkFlutterwaveStatus(payRef)
      .then((res) => {
        if (res.data.status !== "successful") {
          setErrorMessage("Payment not yet received");
          return errorHandler();
        }
        return updateSubscriptionPackage(tathminiId, subscriptionPackageData);
      })
      .then((res) => {
        setIsLoading(false);
        callback("/account/subscription/status");
        Promise.resolve(res);
      })
      .catch((error) => {
        setShowError(true);
        setIsLoading(false);
        Promise.reject(error);
        setTimeout(() => setShowError(false), 5000);
      });
  };

  // Confirm status Subscription Payment with Paybill
  const confirmPaybillPaymentStatus = async (callback) => {
    const data = {
      reference_number: referenceNumber,
      amount: selectedPlan.amount,
    };
    if (data.reference_number && data.reference_number.length === 10) {
      setIsLoading(true);
      checkPaybillPaymentStatus(data)
        .then((res) => {
          if (res.data.status !== 200) {
            setErrorMessage("Payment not yet received");
            return errorHandler();
          }
          return updateSubscriptionPackage(tathminiId, subscriptionPackageData);
        })
        .then((res) => {
          setIsLoading(false);
          callback("/wallet/status");
          Promise.resolve(res);
        })
        .catch((error) => {
          setShowError(true);
          setIsLoading(false);
          Promise.reject(error);
          setTimeout(() => setShowError(false), 5000);
        });
    } else {
      setInputError(false);
    }
  };

  // Manages Payment using promo code
  const promoCodePay = async (callback) => {
    setIsLoading(true);
    if (promoCode) {
      checkPromoCode(promoCode)
        .then((res) => {
          if (res.data.message === "Invalid Promo Code. Please try again") {
            setErrorMessage("Invalid Promo Code. Please try again");
            return errorHandler();
          }
          return updateSubscriptionPackage(tathminiId, subscriptionPackageData);
        })
        .then((res) => {
          setIsLoading(false);
          callback("/account/subscription/status");
          Promise.resolve(res);
        })
        .catch((error) => {
          setShowError(true);
          setIsLoading(false);
          Promise.reject(error);
          setTimeout(() => setShowError(false), 5000);
        });
    } else {
      setIsLoading(false);
      setInputError(true);
    }
  };

  // Get subscription data ... This should only run if we have user data
  useEffect(() => {
    if (user?.pk) {
      setIsLoading(true);
      getSubscriptionPackages()
        .then((res) => {
          setSubscriptionPackages(res);
        })
        .catch(() => {
          setSubscriptionPackages(null);
        });
      getAllSubscriptionHistory(user?.pk)
        .then((res) => {
          setSubscriptionHistory(res);
          setIsLoading(false);
        })
        .catch(() => {
          setSubscriptionHistory(null);
        });
    }
  }, [user]);

  useEffect(() => {
    localStorage.setItem("SELECTED_PLAN", JSON.stringify(selectedPlan));
  }, [selectedPlan]);

  const value = useMemo(() => ({
    subscriptionData,
    BILLING_PLANS,
    isLoading,
    subscriptionPackages,
    country,
    selectedPlan,
    selectedPlanHandler,
    mpesaPay,
    promoCodePay,
    showError,
    mpesaPayPhoneNumber,
    subscriptionHistory,
    isLoadingFlutterwavePaymentDetails,
    flutterwavePay,
    flutterwavePaymentDetails,
    confirmFlutterwavePaymentStatus,
    errorMessage,
    tathminiId,
    paymentNavigationHandler,
    confirmPaybillPaymentStatus,
  }));

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
}

export { useSubscriptionContext, SubscriptionProvider };

SubscriptionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
