/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import LabelledInput from "../../components/forms/LabelledInput";
import Button from "../../components/forms/Button";
import { resetForgottenPassword } from "../../utils/requests-v2";
import PasswordInput from "../../components/forms/PasswordInput";

export default function ResetPasswordPage() {
  const {
    register,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("FP")) {
      navigate("/forgot-password");
    }
  }, []);

  const redirectTimeOut = () =>
    setTimeout(() => {
      navigate("/forgot-password/success");
    }, 2000);

  useEffect(() => clearTimeout(redirectTimeOut));

  const onSubmit = async (data) => {
    if (data.password !== data.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    const userData = JSON.parse(localStorage.getItem("FP"));

    const payload = {
      ...userData,
      otp: userData.otp,
      password: data.password,
    };

    try {
      await resetForgottenPassword(payload);
      toast.success("Password reset successfully");
      redirectTimeOut();
    } catch (error) {
      console.error(error);
      const message =
        error.response?.data?.detail[0]?.msg ||
        "Could not reset password. Please try again";
      toast.error(message);
    }
  };

  return (
    <div className="h-screen w-screen">
      <div className="p-4 h-full">
        <form
          className="flex flex-col h-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <fieldset disabled={isSubmitting} aria-disabled={isSubmitting}>
            <h1 className="mb-4 text-center h-24 md:h-32 py-8">
              Create New Password
            </h1>

            <div className="flex flex-col gap-4 grow">
              <PasswordInput
                register={register}
                control={control}
                type="password"
                label="New Password"
                name="password"
                rules={{ required: true }}
              />

              <div className="grow">
                <PasswordInput
                  register={register}
                  control={control}
                  type="password"
                  label="Confirm Password"
                  name="confirmPassword"
                  rules={{ required: true }}
                />
              </div>

              <div className="pb-12">
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
}
