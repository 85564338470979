import React from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/layout/Layout";
// import { useSubscriptionContext } from "../../utils/Contexts/SubscriptionContext";
import LabelledInput from "../../components/forms/LabelledInput";
import { useWalletContext } from "../../utils/Contexts/V2/WalletContext";
import { usePaymentContext } from "../../utils/Contexts/V2/PaymentContext";

function PaymentPromoCode() {
  const navigate = useNavigate();
  // const { pathname } = useLocation();
  const { setPromoCodeHandler, inputError } = usePaymentContext();
  // const { showError, promoCodePay, errorMessage } = pathname.includes(
  //   "subscription"
  // )
  //   ? useSubscriptionContext()
  //   : useWalletContext();

  // const { isLoading } = useSubscriptionContext();
  const { isLoadingWalletData, promoCodePay } = useWalletContext();

  const loaderState = () =>
    // if (pathname.includes("subscription")) {
    //   return isLoading;
    // }
    isLoadingWalletData;
  return (
    <Layout header headerTitle="Apply Promo Code">
      <div className="relative flex flex-col items-start justify-between w-full pt-20 h-screen">
        <div className="w-full flex flex-col items-start justify-start space-y-2 pt-8 px-4">
          <p className="text-sm font-semibold">Promo Code</p>
          <div
            className={`w-full h-[3.9rem] rounded-lg ${
              inputError ? "border-[0.14rem] border-red-50" : ""
            }`}
          >
            <LabelledInput
              name="promo_code"
              id="promo_code"
              type="number"
              label="Enter promo code"
              onChange={setPromoCodeHandler}
              disabled={loaderState()}
            />
          </div>
        </div>
        <div className="w-full p-4 border-[0.099rem] border-[#E7E7E7]">
          <button
            type="button"
            className="w-full h-14 text-white text-base text-center thryve:text-base font-semibold rounded-xl bg-primary-50 justify-center items-center"
            onClick={() => promoCodePay(navigate)}
          >
            {!loaderState() ? "Apply" : "Processing Request..."}
          </button>
        </div>
      </div>
    </Layout>
  );
}

export default PaymentPromoCode;
