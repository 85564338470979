import React from "react";
import { useQuery } from "@tanstack/react-query";

import Layout from "../../components/layout/V2/Layout";
import ProfileForm from "../../components/V2/ProfileForm";
import {
  getIndustries,
  getMerchantOperationHours,
} from "../../utils/requests-v2";
import { useAuth } from "../../utils/Contexts/V2/AuthenticationContext";
import Spinner from "../../components/Spinner";

export default function ProfilePage() {
  const { merchantId } = useAuth();

  const { data: industries, isLoading: isLoadingIndustries } = useQuery(
    ["/industries"],
    async () => getIndustries()
  );

  const { data: workingHours, isLoading: isLoadingWorkingHours } = useQuery(
    ["/working-hours", merchantId],
    () => getMerchantOperationHours(),
    {
      enabled: !!merchantId,
    }
  );

  return (
    <Layout header headerTitle="Profile" backTo="/account">
      <div className="relative overflow-y-auto min-h-screen max-w-screen-xl container mx-auto scroll-smooth pt-[60px] pb-4">
        {!merchantId || isLoadingIndustries || isLoadingWorkingHours ? (
          <div className="h-screen w-screen flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className="w-full">
            <ProfileForm industries={industries} workingHours={workingHours} />
          </div>
        )}
      </div>
    </Layout>
  );
}
