import slugify from "slugify";

export default function slugifySubdomain(subdomain) {
  return slugify(subdomain, {
    remove: /[^A-Za-z0-9 -]/g,
    lower: true,
    strict: true,
    locale: "en-US",
    trim: true,
  });
}
