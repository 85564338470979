import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Transition } from "@headlessui/react";
import Layout from "../../../components/layout/V2/Layout";
import { useContact } from "../../../utils/Contexts/V2/ContactContext";
import displayPicHandler from "../../../utils/displayPicNameHandler";
import { useRequestPaymentContext } from "../../../utils/Contexts/V2/RequestPaymentContext";
import UpdateBankDetailsPrompt from "./update-bank-details-prompt";
import Spinner from "../../../components/Spinner";

export default function RequestPayment() {
  const navigate = useNavigate();
  const { contacts } = useContact();
  const {
    selectContactHandler,
    isVerifyingBankDetails,
    bankValidationPrompt,
    setBankValidationPrompt,
  } = useRequestPaymentContext();

  return (
    <Layout headerTitle="Request money" header>
      {isVerifyingBankDetails ? (
        <div className="h-screen w-screen flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className="relative h-screen pt-12 w-full flex flex-col justify-start items-center">
          <Transition
            className="fixed -bottom-0 left-0 z-20 w-full"
            show={bankValidationPrompt}
            enterFrom=" translate-y-0"
            enterTo=" -translate-y-12"
            leaveFrom=" -translate-y-12"
            leaveTo=" translate-y-0"
          >
            <UpdateBankDetailsPrompt
              setBankValidation={setBankValidationPrompt}
            />
          </Transition>
          <Link
            onClick={() => selectContactHandler(null)}
            to="/request-payment/enter-amount"
            className="px-4 py-[1.25rem] flex flex-row space-x-[0.62rem] items-start  w-full bg-primary-10 thryve:bg-[#EEF1F2]"
          >
            <div className="w-[2.25rem] h-[2.25rem] pt-[0.26rem]">
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="18"
                  cy="18"
                  r="18"
                  className="fill-primary-50 thryve:fill-secondary-50"
                />
                <path
                  d="M10.2222 25.7782C10.6861 26.2427 11.2372 26.611 11.8438 26.8619C12.4505 27.1128 13.1007 27.2414 13.7572 27.2402C14.4138 27.2414 15.0642 27.1128 15.671 26.8619C16.2778 26.611 16.8291 26.2427 17.2932 25.7782L20.1212 22.9492L18.7072 21.5352L15.8792 24.3642C15.3157 24.9252 14.5529 25.2402 13.7577 25.2402C12.9625 25.2402 12.1997 24.9252 11.6362 24.3642C11.0747 23.8009 10.7594 23.038 10.7594 22.2427C10.7594 21.4474 11.0747 20.6845 11.6362 20.1212L14.4652 17.2932L13.0512 15.8792L10.2222 18.7072C9.28601 19.6457 8.76025 20.9171 8.76025 22.2427C8.76025 23.5683 9.28601 24.8397 10.2222 25.7782V25.7782ZM25.7782 17.2932C26.7139 16.3545 27.2393 15.0831 27.2393 13.7577C27.2393 12.4323 26.7139 11.1609 25.7782 10.2222C24.8397 9.28601 23.5683 8.76025 22.2427 8.76025C20.9171 8.76025 19.6457 9.28601 18.7072 10.2222L15.8792 13.0512L17.2932 14.4652L20.1212 11.6362C20.6847 11.0752 21.4475 10.7602 22.2427 10.7602C23.0379 10.7602 23.8007 11.0752 24.3642 11.6362C24.9257 12.1995 25.241 12.9624 25.241 13.7577C25.241 14.553 24.9257 15.3159 24.3642 15.8792L21.5352 18.7072L22.9492 20.1212L25.7782 17.2932Z"
                  className="fill-base thryve:fill-white"
                />
                <path
                  d="M14.4638 22.9498L13.0488 21.5358L21.5358 13.0498L22.9498 14.4648L14.4638 22.9498Z"
                  className="fill-base thryve:fill-white"
                />
              </svg>
            </div>
            <div className="flex flex-col justify-start items-start w-[17rem] w-full">
              <h1 className="text-primary-50 thryve:text-base text-[0.875rem] pt-0">
                Share a payment link
              </h1>
              <p className="text-[#667085] text-[0.8125rem] leading-[1rem] ">
                Receive money directly from your customers with a customized
                link
              </p>
            </div>
          </Link>
          <div className="w-full scroll-smooth pt-4 flex flex-col items-center justify-between">
            <p className="px-4 font-bold text-[0.9375rem] text-left w-full">
              Select customer
            </p>
            <div className="w-full px-4 pt-4">
              <div className="flex flex-row justify-between items-center border border-solid rounded-lg px-3 py-1">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.3335 0.333252C9.6455 0.333252 12.3335 3.02125 12.3335 6.33325C12.3335 9.64525 9.6455 12.3333 6.3335 12.3333C3.0215 12.3333 0.333496 9.64525 0.333496 6.33325C0.333496 3.02125 3.0215 0.333252 6.3335 0.333252ZM6.3335 10.9999C8.9115 10.9999 11.0002 8.91125 11.0002 6.33325C11.0002 3.75459 8.9115 1.66659 6.3335 1.66659C3.75483 1.66659 1.66683 3.75459 1.66683 6.33325C1.66683 8.91125 3.75483 10.9999 6.3335 10.9999ZM11.9902 11.0473L13.8762 12.9326L12.9328 13.8759L11.0475 11.9899L11.9902 11.0473Z"
                    fill="#808A93"
                  />
                </svg>
                <input
                  className="h-full w-[87%] py-2 text-xs outline-none"
                  placeholder="Search"
                />
              </div>{" "}
            </div>
            <div className="w-full h-full flex flex-col justify-start pt-[0.69rem] px-4">
              {contacts?.map((item) => (
                <button
                  type="button"
                  className="w-full flex flex-row justify-between items-center py-2 border-t border-b border-[#F8F6FB]"
                  key={item.id}
                  onClick={() => selectContactHandler(item, navigate)}
                >
                  <div className="flex flex-row items-center space-x-[0.69rem]">
                    <p className="w-10 h-10 bg-[#D9D9D9] rounded-full p-4 flex justify-center items-center font-bold text-[1rem]">
                      {displayPicHandler(item.full_name)}
                    </p>
                    <div className="flex flex-col justify-between items-start">
                      <p className="font-bold text-sm">{item.full_name}</p>
                      <p className="font-normal text-xs text-[#566370]">
                        {item.phone_number}
                      </p>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
