/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { useWatch } from "react-hook-form";

export default function LabelledInput({
  name,
  rules,
  type,
  className,
  id,
  register,
  placeholder,
  label,
  wrapperClassName,
  labelClassName,
  helperText,
  characterLimit,
  showCharacterCount,
  control,
  errorText,
  ...rest
}) {
  const value = control
    ? useWatch({
        control,
        name,
      })
    : "";

  return (
    <div className={wrapperClassName}>
      <input
        type={type}
        className={className}
        name={name}
        id={id}
        {...register(name)}
        {...rules}
        placeholder={placeholder || " "} // This is required for the label to float
        {...rest}
        maxLength={rest.maxLength || characterLimit || Infinity}
      />
      {showCharacterCount && (
        <p className="text-xs text-[#566370] mt-1">
          {value.length}/{characterLimit}
        </p>
      )}
      <label htmlFor={id} className={labelClassName}>
        {label}
        {rest.required || rules.required ? (
          <span style={{ color: "#CD3717" }}> *</span>
        ) : (
          ""
        )}
      </label>
      {helperText && <p className="text-sm text-gray-500 my-2">{helperText}</p>}
      {errorText && <p className="text-xs text-[#CD3717] my-2">{errorText}</p>}
    </div>
  );
}

LabelledInput.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.shape({ required: PropTypes.bool }),
  type: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  register: PropTypes.func,
  wrapperClassName: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  characterLimit: PropTypes.number,
  showCharacterCount: PropTypes.bool,
  control: PropTypes.object,
  errorText: PropTypes.string,
};

LabelledInput.defaultProps = {
  rules: {},
  type: "text",
  wrapperClassName: "form-floating mb-3 w-full",
  className:
    "form-control block w-full px-3 py-1.5 text-sm font-medium text-base bg-white bg-clip-padding border border-solid border-[#D1D9DD] rounded-lg transition ease-in-out m-0 focus:bg-white focus:border-primary-50 thryve:focus:border-secondary-50 focus:outline-none focus:shadow thryve:focus:shadow-[#0061874D]",
  labelClassName: "text-gray-500",
  placeholder: "",
  register: () => {},
  helperText: "",
  characterLimit: 1000,
  showCharacterCount: false,
  control: null,
  errorText: "",
};
