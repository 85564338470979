import React from "react";

import { useProductPhotosContext } from "../../../utils/Contexts/V2/ProductPhotosContext";

function AddCategory() {
  const {
    isCreatingCategory,
    setNewCategory,
    createNewCategory,
    newCategory,
    setShowAddCategoryPrompt,
  } = useProductPhotosContext();
  return (
    <div className="relative w-full">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="relative w-full h-screen flex bg-blur z-20"
        onClick={() => setShowAddCategoryPrompt(false)}
      />
      <div className="relative bottom-[10px]  flex flex-col z-40 justify-between items-center space-y-5 w-full h-[14rem] px-[1rem] pt-[1.7rem] pb-6 bg-white rounded-t-2xl overflow-hidden">
        <div className="text-center flex flex-col space-y-4 w-full">
          <div className="flex flex-row justify-between w-full items-center">
            <p className="text-[1.125rem] font-semibold text-[#1A1A1A]">
              Add a new category
            </p>
            <button
              type="button"
              onClick={() => setShowAddCategoryPrompt(false)}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.0088 18.5383L4.76608 6.29552C4.34778 5.87722 4.34778 5.18347 4.76608 4.76517C5.18437 4.34688 5.87813 4.34688 6.29642 4.76517L18.5392 17.0079C18.9575 17.4262 18.9575 18.12 18.5392 18.5383C18.1209 18.9566 17.4271 18.9566 17.0088 18.5383Z"
                  fill="#1A1A1A"
                />
                <path
                  d="M4.76551 18.5383C4.34721 18.12 4.34721 17.4262 4.76551 17.0079L17.0083 4.76517C17.4266 4.34688 18.1203 4.34688 18.5386 4.76517C18.9569 5.18347 18.9569 5.87722 18.5386 6.29552L6.29585 18.5383C5.87756 18.9566 5.1838 18.9566 4.76551 18.5383Z"
                  fill="#1A1A1A"
                />
              </svg>
            </button>
          </div>
          <input
            className="w-full rounded-[0.5rem]  border-[1px] border-[#E7E7E7] px-[1rem]  py-[1.38rem] font-graphik-regular text-[1rem] text-[#1A1A1A] placeholder:text-[#566370] focus:border-[#4B1D8F] focus:outline-none focus:ring-0"
            placeholder="Enter category name"
            id="category_name"
            onChange={(e) => setNewCategory(e.target.value)}
            value={newCategory}
          />
        </div>
        <div className="flex flex-col space-y-3 w-full">
          <button
            disabled={!newCategory}
            type="button"
            className={`${
              !newCategory && "opacity-50"
            } w-full  h-12 text-white text-base font-semibold rounded-xl bg-primary-50 thryve:text-base`}
            onClick={createNewCategory}
          >
            {isCreatingCategory ? "Saving.." : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddCategory;
