import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Helmet } from "react-helmet";

const AppContext = createContext(null);

export function useAppContext() {
  return useContext(AppContext);
}

const APP_VENDOR = process.env.REACT_APP_APP_VENDOR || "tappi";
const APP_THEME = process.env.REACT_APP_THEME || "tappi";

export function AppProvider({ children }) {
  const [language, setLanguage] = useState("en");
  const [country, setCountry] = useState(null);
  const appTheme = APP_THEME;
  const appVendor = APP_VENDOR;

  const SUPPORTED_COUNTRIES_MAP = {
    thryve: ["NG"],
    tappi: ["KE"],
  };

  const SUPPORTED_COUNTRIES = SUPPORTED_COUNTRIES_MAP[appVendor];

  // Set the country used within the app
  useEffect(() => {
    switch (appVendor) {
      case "thryve":
        setCountry("NG");
        break;
      case "tappi":
        setCountry("KE");
        break;
      default:
        setCountry("NG");
        break;
    }
  }, [appVendor]);

  const value = useMemo(() => ({
    language,
    setLanguage,
    country,
    setCountry,
    appTheme,
    appVendor,
    SUPPORTED_COUNTRIES,
  }));

  const favicon = document.querySelector("link[rel~='icon']");
  const [faviconLink, setFaviconLink] = useState("/TAPPI_FAVICON.png");
  const [appTitle, setAppTitle] = useState(
    "Tappi, Find Customers Online: Connect, Convert, Retain With Ease."
  );
  const [appDescription, setAppDescription] = useState(
    "Tappi, Find Customers Online: Connect, Convert, Retain With Ease."
  );
  const [appImageUrl, setAppImageUrl] = useState(
    "https://tappi.app/tappi-preview.png"
  );
  const [ogDescription, setOgDescription] = useState(
    "Convert one-time customers into loyal ones by getting discovered, growing leads and building your business reputation."
  );
  const [ogUrl, setOgUrl] = useState("https://tappi.app");

  useEffect(() => {
    if (appVendor === "thryve") {
      document.title = "MTN Thryve";
      favicon.href = "/thryve-favicon.png";
      setFaviconLink("/thryve-favicon.png");
      setAppTitle(
        "MTN Thryve, Find More Customers. Bring Your Business Online."
      );
      setAppDescription(
        "Start your first business web-page in minutes. Easily power online transactions, Buy Ads with your Airtime on Facebook, Instagram, and Google. Get ratings and reviews to build your business credibility, and enlarge your customer base"
      );
      setAppImageUrl("/thryve-favicon.png");
      setOgDescription(
        "Start your first business web-page in minutes. Easily power online transactions, Buy Ads with your Airtime on Facebook, Instagram, and Google. Get ratings and reviews to build your business credibility, and enlarge your customer base"
      );
      setOgUrl("https://thryve.ng");
    }
  }, [appVendor]);

  return (
    <AppContext.Provider value={value}>
      {/* This seems to be the only way themes can be set to dynamically load.
          Using template strings or variables isn't working
          This means new themes must be added here as well
      */}
      <div
        className={classNames({
          tappi: appTheme === "tappi",
          thryve: appTheme === "thryve",
        })}
      >
        <Helmet>
          <title>{appTitle}</title>
          <link rel="icon" href={faviconLink} />
          <meta name="description" content={appDescription} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={appTitle} />
          <meta property="og:description" content={ogDescription} />
          <meta property="og:image" content={appImageUrl} />
          <meta property="og:url" content={ogUrl} />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={ogUrl} />
          <meta property="twitter:title" content={appTitle} />
          <meta property="twitter:description" content={ogDescription} />
          <meta property="twitter:image" content={appImageUrl} />
        </Helmet>
        {children}
      </div>
    </AppContext.Provider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
