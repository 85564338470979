export const ButtonType = {
  primary:
    "bg-primary-50 hover:bg-primary-70 tappi:text-white thryve:text-base hover:shadow-lg focus:bg-primary-70 w-full",
  outline:
    "bg-transparent focus:bg-transparent hover:bg-transparent border shadow-none w-full",
};

export const ButtonSize = {
  sm: "py-1 px-4 text-xs h-8",
  md: "py-2 px-6 h-12",
  lg: "py-4 px-8 text-lg h-16",
};

export const ButtonRadius = {
  sm: "rounded",
  md: "rounded-lg",
  lg: "rounded-xl",
  full: "rounded-full",
};
