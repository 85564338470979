import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ReactModal from "react-modal";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import Layout from "../../components/layout/V2/Layout";
import LabelledTextarea from "../../components/forms/LabelledTextarea";
import Button from "../../components/forms/Button";
import CustomPhoneInput from "../../components/PhoneInput";
import { useAuth } from "../../utils/Contexts/V2/AuthenticationContext";
import { validatePhoneNumber } from "../../utils/validators";
import { deleteAccount } from "../../utils/requests-v2";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    paddingTop: "12px",
  },
};

export default function DeleteAccountPage() {
  const { register, handleSubmit } = useForm();

  const { user, logout } = useAuth();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDeleteAccount = (data) => {
    console.log(data);

    setIsModalOpen(true);
  };

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberErrors, setPhoneNumberErrors] = useState({
    phoneNumber: "",
  });

  const navigate = useNavigate();

  const handleConfirmDelete = async () => {
    if (!phoneNumber) {
      setPhoneNumberErrors({ phoneNumber: "Phone number is required" });
      return;
    }

    if (!validatePhoneNumber(phoneNumber, user.country.country_code)) {
      setPhoneNumberErrors({ phoneNumber: "Invalid phone number" });
      return;
    }

    if (phoneNumber.slice(1) !== user.phone_number) {
      setPhoneNumberErrors({ phoneNumber: "Phone number does not match" });
      return;
    }

    await deleteAccount()
      .then(() => {
        toast.success("Your account was successfully deleted");
        setIsModalOpen(false);

        logout();
        setTimeout(() => {
          navigate("/join");
        }, [2000]);
      })
      .catch((error) => {
        console.error("Could not delete account : ", error);
        toast.error("We could not delete your account. Please try again.");
      });
  };

  return (
    <Layout header headerTitle="Delete Account" backTo="/account">
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={modalStyles}
      >
        <div className="card flex flex-col">
          <div
            className="card-header flex justify-between items-center pb-2"
            style={{ borderBottom: "1px solid #E7E7E7" }}
          >
            <h3>Delete Account</h3>
            <button type="button" onClick={() => setIsModalOpen(false)}>
              <svg
                viewBox="0 0 1024 1024"
                fill="currentColor"
                height="1.5em"
                width="1.5em"
              >
                <path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 00-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z" />
                <path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
              </svg>
            </button>
          </div>
          <div className="card-body my-4">
            <p>Please enter your phone number to confirm</p>

            <form className="mt-4">
              <CustomPhoneInput
                selectedCountryCode={user?.country.country_code}
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                errors={phoneNumberErrors}
                setErrors={setPhoneNumberErrors}
                countries={[user?.country.country_code]}
                className="sm"
              />

              <div className="flex space-x-4 mt-4">
                <Button size="sm" onClick={handleConfirmDelete}>
                  Confirm Delete
                </Button>
                <Button size="sm" onClick={() => setIsModalOpen(false)}>
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </div>
      </ReactModal>
      <div className="h-screen max-w-screen container mx-auto px-4 pt-20 pb-8 flex flex-col">
        <p className="text-center text-sm">
          We are sad to see you go. Could you please share your reason for
          deleting your account with us in order to help us fix it?
        </p>

        <form
          onSubmit={handleSubmit(handleDeleteAccount)}
          className="mt-4 flex-grow flex flex-col"
        >
          <div className="flex-grow">
            <LabelledTextarea
              label="Type here (optional)"
              register={register}
              name="reason"
              id="deleteAccountReasonInput"
            />
          </div>

          <Button type="submit">Proceed</Button>
        </form>
      </div>
    </Layout>
  );
}
