import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { v4 } from "uuid";

import useClickOutside from "../utils/useClickOutside";

export default function Dropdown({ trigger, menu, menuPosition }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOutside = () => {
    setTimeout(() => setOpen(false), 10);
  };

  const ref = useClickOutside(handleClickOutside);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={(e) => e.stopPropagation()}>
      <div className="dropdown">
        {React.cloneElement(trigger, {
          onClick: handleOpen,
          ref,
        })}
        {open ? (
          <ul
            className={classNames("menu", {
              "left-[-100px]": menuPosition === "left",
            })}
          >
            {menu.map((menuItem) => (
              <li key={v4()} className="menu-item">
                {React.cloneElement(menuItem, {
                  onClick: () => {
                    menuItem.props.onClick();
                    setOpen(false);
                  },
                })}
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </div>
  );
}

Dropdown.propTypes = {
  trigger: PropTypes.node.isRequired,
  menu: PropTypes.arrayOf([PropTypes.node]).isRequired,
  menuPosition: PropTypes.string,
};

Dropdown.defaultProps = {
  menuPosition: "left",
};
