import React from "react";
import PropTypes from "prop-types";
import Button from "../forms/Button";
import sendTappi from "../../assets/icons/Send-Successfully-tappi.gif";
import sendThryve from "../../assets/icons/Send-Successfully-thryve.gif";
import { useAppContext } from "../../utils/Contexts/AppContext";

const gifAnimation = {
  tappi: sendTappi,
  thryve: sendThryve,
};

function SuccessScreen({ message, redirectTo }) {
  const { appTheme } = useAppContext();
  return (
    <div className="relative w-full">
      <div className="relative -bottom-16  h-screen flex bg-blur" />
      <div className="relative bottom-0 flex flex-col justify-between items-center space-y-5 w-full h-[27rem] px-4 pt-[1.7rem] pb-6 bg-white rounded-t-2xl overflow-hidden">
        <div className="text-center flex flex-col justify-center items-center space-y-4 w-full">
          <p className="font-semibold text-[1.25rem]">Success!</p>
          <p className="text-sm font-normal">{message}</p>
          <img src={gifAnimation[`${appTheme}`]} alt="success" width={200} />
        </div>
        <div className="flex flex-col space-y-3 w-full">
          <Button
            type="button"
            href={redirectTo}
            className="font-bold flex justify-center items-center"
          >
            Done
          </Button>
        </div>
      </div>
    </div>
  );
}

SuccessScreen.propTypes = {
  message: PropTypes.string.isRequired,
  redirectTo: PropTypes.string.isRequired,
};

export default SuccessScreen;
