import React from "react";
import PropTypes from "prop-types";

function DeleteConfirmationPrompt({ text, action, closePrompt, isDeleting }) {
  return (
    <div className="relative w-full ">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="w-full h-screen flex bg-blur"
        onClick={() => closePrompt(false)}
      />
      <div className="relative bottom-0 flex flex-col justify-start items-center space-y-5 w-full px-4 pt-[1.7rem] pb-6 bg-white rounded-2xl">
        <p className="text-base font-semibold">Are you sure?</p>
        <p className="text-base font-normal text-center">{text}</p>
        <div className="flex flex-col space-y-3 w-full">
          <button
            type="button"
            className="w-full h-12 text-white text-base font-semibold rounded-xl bg-primary-50 thryve:text-base"
            onClick={action}
          >
            {!isDeleting ? "Yes, Delete" : "Deleting..."}
          </button>
          <button
            type="button"
            className="w-full h-12 text-black text-base font-semibold"
            onClick={() => closePrompt(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

DeleteConfirmationPrompt.propTypes = {
  text: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  closePrompt: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool.isRequired,
};

export default DeleteConfirmationPrompt;
