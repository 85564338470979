import React from "react";
import PropTypes from "prop-types";
import moment from "moment/moment";
import { useStateMachine } from "little-state-machine";

export default function FacebookAdsDurationDetails({ openModal }) {
  const { state } = useStateMachine();

  const durations = {
    startDate:
      typeof state.adJourney.durationData.startDate === "string"
        ? moment(state.adJourney.durationData.startDate)
        : state.adJourney.durationData.startDate,
    endDate:
      typeof state.adJourney.durationData.endDate === "string"
        ? moment(state.adJourney.durationData.endDate)
        : state.adJourney.durationData.endDate,
  };

  function calculateDuration() {
    const diff = durations.endDate.diff(durations.startDate, "days");
    return `${diff} ${diff === 1 ? "Day" : "Days"}`;
  }

  return (
    <button
      type="button"
      className="border rounded-lg px-4 py-2 flex justify-between items-start w-full"
      onClick={() => openModal("duration-modal")}
    >
      <div className="text-start">
        <h4 className="font-semibold">Duration</h4>
        <div className="text-sm">
          <p>
            <span className="text-gray-60">Run Ad For: </span>
            <span className="font-semibold">{calculateDuration()}</span>
          </p>
          <p>
            <span className="text-gray-60">Start Date: </span>
            <span className="font-semibold">
              {durations.startDate.format("MMM, Do YYYY")}
            </span>
          </p>
          <p>
            <span className="text-gray-60">End Date: </span>
            <span className="font-semibold">
              {durations.endDate.format("MMM, Do YYYY")}
            </span>
          </p>
        </div>
      </div>
      <div className="bg-grey-20 px-2 py-2 rounded-full">
        <svg viewBox="0 0 20 20" fill="currentColor" height="1em" width="1em">
          <path d="M12.3 3.7l4 4L4 20H0v-4L12.3 3.7zm1.4-1.4L16 0l4 4-2.3 2.3-4-4z" />
        </svg>
      </div>
    </button>
  );
}

FacebookAdsDurationDetails.propTypes = {
  openModal: PropTypes.func.isRequired,
};
