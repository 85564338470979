import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import moment from "moment/moment";
import Layout from "../components/layout/V2/Layout";
import SubscriptionIllustration from "../assets/images/subscription.svg";
import { useSubscriptionContext } from "../utils/Contexts/V2/SubscriptionContext";
import { usePaymentContext } from "../utils/Contexts/V2/PaymentContext";
import Spinner from "../components/Spinner";

function Subscription() {
  const { currency } = usePaymentContext();
  const { subscriptionData, subscriptionHistory, isLoading } =
    useSubscriptionContext();

  const [remainingDays, setRemainingDays] = useState(0);

  useEffect(() => {
    if (subscriptionData) {
      const { subscriptionExpiryDate } = subscriptionData;
      if (subscriptionExpiryDate) {
        const days = moment
          .duration(moment(subscriptionExpiryDate) - moment().startOf("day"))
          .asDays()
          .toFixed();
        setRemainingDays(days);
      } else {
        setRemainingDays(0);
      }
    }
  }, [subscriptionData]);

  return (
    <Layout header headerTitle="Subscription">
      {isLoading ? (
        <div className="h-screen w-screen flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col justify-start items-center w-full px-4 pt-20">
          <p className="text-sm font-normal text-[#1A1A1A]">
            Your Active Subscription
          </p>
          <div className="flex flex-row justify-between items-center px-4 py-5 w-full border border-solid border-purple-50 rounded-md mt-4">
            <div className="flex flex-col justify-start items-start w-full space-y-px">
              <p className="text-[0.93rem] font-semibold">
                {subscriptionData?.subscriptionPackage}
              </p>
              <p className="text-[0.81rem] font-normal text-[#1A1A1A]">
                {remainingDays <= 0
                  ? "expired"
                  : `${remainingDays || 0} remaining days`}
              </p>
            </div>
            <div className="flex flex-row items-center space-x-1">
              <p className="text-base font-normal">{currency}</p>
              <p className="text-base font-bold">
                {subscriptionData?.subscriptionAmount || 0}
              </p>
              <p className="text-sm font-normal text-[#1A1A1A] lowercase">
                {subscriptionData?.subscriptionInterval}
              </p>
            </div>
          </div>
          <NavLink
            to="/account/subscription/plans"
            className="text-[0.93rem] font-medium text-purple-50 underline text-left w-full mt-4"
          >
            Change Plan
          </NavLink>
          <div className="mt-[3.7rem] flex flex-col flex-start items-start space-y-4 w-full">
            <p className="text-base font-semibold text-[#1A1A1A]">
              Subscription History
            </p>
            {!subscriptionHistory ? (
              <div className="flex flex-col justify-center items-center w-full pt-[6.3rem]">
                <img
                  src={SubscriptionIllustration}
                  alt="no subscription history illustration"
                />
                <p className="mt-6 text-[#1A1A1A] text-sm">
                  Your subscription history will display here
                </p>
              </div>
            ) : (
              subscriptionHistory?.map((item) => (
                <div className="w-full flex flex-col" key={item.id}>
                  <div className="w-full flex flex-row justify-between pb-4 border-b-[0.07rem] border-[#D1D9DD]">
                    <div className="flex flex-col justify-start items-start  space-y-px">
                      <p className="text-[0.93rem] font-semibold">
                        {item.package?.name}
                      </p>
                      <p className="text-[0.81rem] font-normal text-[#4B5762]">
                        {moment(item.date_from).format("Do MMM YYYY")} -{" "}
                        {moment(item.date_to).format("Do MMM YYYY")}
                      </p>
                    </div>
                    <p className="text-sm text-[#0D253F] font-bold mt-3">
                      {currency} {item.package_price.toLocaleString() || 0}
                    </p>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </Layout>
  );
}

export default Subscription;
