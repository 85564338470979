/* eslint-disable camelcase */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import {
  checkFlutterwaveStatus,
  checkPaybillPaymentStatus,
  getWalletDetails,
  getWalletTransactionHistory,
  paySubscriptionFlutterwave,
  makeMpesaPayment,
  checkPromoCode,
  topUpWallet,
} from "../requests";
import { usePaymentContext } from "./PaymentContext";
import { useAuth } from "./AuthenticationContext";

const WalletContext = createContext(null);

function useWalletContext() {
  return useContext(WalletContext);
}

function WalletProvider({ children }) {
  const { user } = useAuth();
  const userID = user?.pk;
  const {
    mpesaPayPhoneNumber,
    selectedPayment,
    msisdn,
    setInputError,
    setFlutterwavePaymentDetails,
    flutterwavePaymentDetails,
    promoCode,
    referenceNumber,
  } = usePaymentContext();
  const [isLoadingWalletData, setIsLoadingWalletData] = useState(false);
  const [walletDetails, setWalletDetails] = useState(null);
  const [walletTransactionHistory, setWalletTransactionHistory] =
    useState(null);
  const [topUpAmount, setTopUpAmount] = useState(
    localStorage.getItem("TOP_UP_AMOUNT") || 0
  );
  const [showWalletTopUp, setShowWalletTop] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showError, setShowError] = useState(false);
  const [
    isLoadingFlutterwavePaymentDetails,
    setIsLoadingFlutterwavePaymentDetails,
  ] = useState(false);
  const paymentData = {
    phone_number: mpesaPayPhoneNumber,
    amount: topUpAmount.toString() || 0,
  };
  const flutterwavePaymentData = {
    msisdn,
    amount: topUpAmount.toString() || 0,
  };

  const topUpAmountHandler = (e) => {
    if (e.target.value > 0) {
      setInputError(false);
      setTopUpAmount(e.target.value);
    } else {
      setTopUpAmount(0);
      setInputError(true);
    }
  };

  const paymentNavigationHandler = (callback) => {
    if (selectedPayment.mpesa) {
      callback("/wallet/payment/mpesa");
    } else {
      callback("/wallet/payment/promo_code");
    }
  };

  // A helper function to throw an error when an undesired response is gotten from a call
  const errorHandler = (e) => {
    throw e;
  };

  // Subscription Payment with Mpesa
  const mpesaPay = async (callback) => {
    if (mpesaPayPhoneNumber && mpesaPayPhoneNumber.length === 13) {
      setIsLoadingWalletData(true);
      makeMpesaPayment(paymentData)
        .then((res) => {
          if (res.data.status === 400) {
            setErrorMessage("Transaction Unsuccessful");
            return errorHandler();
          }
          return topUpWallet(paymentData);
        })
        .then((res) => {
          setIsLoadingWalletData(false);
          callback("/wallet/status");
          Promise.resolve(res);
        })
        .catch((error) => {
          setShowError(true);
          setIsLoadingWalletData(false);
          Promise.reject(error);
          setTimeout(() => setShowError(false), 5000);
        });
    } else {
      setInputError(true);
    }
  };

  // Subscription Payment with Flutterwave
  const flutterwavePay = async () => {
    setIsLoadingFlutterwavePaymentDetails(true);
    paySubscriptionFlutterwave(flutterwavePaymentData)
      .then((res) => {
        if (res.data.status === "failed") {
          setErrorMessage("Service currently unavailable");
          setShowError(true);
          setTimeout(() => setShowError(false), 5000);
          return errorHandler();
        }
        setIsLoadingFlutterwavePaymentDetails(false);
        setFlutterwavePaymentDetails(res.data);
        return Promise.resolve(res);
      })
      .catch((error) => {
        setShowError(true);
        setIsLoadingFlutterwavePaymentDetails(false);
        Promise.reject(error);
        setTimeout(() => setShowError(false), 5000);
      });
  };

  // Confirm status Subscription Payment with Flutterwave
  const confirmFlutterwavePaymentStatus = async (callback) => {
    const payRef = {
      transfer_reference:
        flutterwavePaymentDetails.meta.authorization.transfer_reference,
    };
    setIsLoadingWalletData(true);
    checkFlutterwaveStatus(payRef)
      .then((res) => {
        if (res.data.status !== "successful") {
          setErrorMessage("Payment not yet received");
          return errorHandler();
        }
        return topUpWallet(paymentData);
      })
      .then((res) => {
        setIsLoadingWalletData(false);
        callback("/wallet/status");
        Promise.resolve(res);
      })
      .catch((error) => {
        setShowError(true);
        setIsLoadingWalletData(false);
        Promise.reject(error);
        setTimeout(() => setShowError(false), 5000);
      });
  };

  // Confirm status Wallet top up Payment with Paybill
  const confirmPaybillPaymentStatus = async (callback) => {
    const data = {
      reference_number: referenceNumber,
      amount: topUpAmount,
    };
    const topWalletData = {
      phone_number: msisdn,
      amount: topUpAmount,
    };
    if (data.reference_number && data.reference_number.length === 10) {
      setIsLoadingWalletData(true);
      setInputError(false);
      checkPaybillPaymentStatus(data)
        .then((res) => {
          if (res.data.status !== 200) {
            setErrorMessage("Payment not yet received");
            return errorHandler();
          }
          return topUpWallet(topWalletData);
        })
        .then((res) => {
          setIsLoadingWalletData(false);
          callback("/wallet/status");
          Promise.resolve(res);
        })
        .catch((error) => {
          setShowError(true);
          setIsLoadingWalletData(false);
          Promise.reject(error);
          setTimeout(() => setShowError(false), 5000);
        });
    } else {
      setInputError(true);
    }
  };

  // Manages Payment using promo code
  const promoCodePay = async (callback) => {
    const topWalletData = {
      phone_number: msisdn,
      amount: topUpAmount,
    };
    setIsLoadingWalletData(true);
    if (promoCode) {
      checkPromoCode(promoCode)
        .then((res) => {
          if (res.data.message === "Invalid Promo Code. Please try again") {
            setErrorMessage("Invalid Promo Code. Please try again");
            return errorHandler();
          }
          return topUpWallet(topWalletData);
        })
        .then((res) => {
          setIsLoadingWalletData(false);
          callback("/wallet/status");
          Promise.resolve(res);
        })
        .catch((error) => {
          setShowError(true);
          setIsLoadingWalletData(false);
          Promise.reject(error);
          setTimeout(() => setShowError(false), 5000);
        });
    } else {
      setIsLoadingWalletData(false);
      setInputError(true);
    }
  };

  useEffect(() => {
    setIsLoadingWalletData(true);
    if (userID) {
      getWalletDetails(msisdn)
        .then((res) => {
          setWalletDetails(res.data.data.data);
        })
        .catch(() => {
          setWalletDetails(null);
        });
      getWalletTransactionHistory(userID)
        .then((res) => {
          setWalletTransactionHistory(res.data.data);
          setIsLoadingWalletData(false);
        })
        .catch(() => {
          setWalletTransactionHistory(null);
        });
    } else {
      setWalletDetails([]);
      setWalletTransactionHistory([]);
    }
  }, [userID]);

  useEffect(() => {
    localStorage.setItem("TOP_UP_AMOUNT", topUpAmount);
  }, [topUpAmount]);

  const value = useMemo(() => ({
    isLoadingWalletData,
    walletDetails,
    walletTransactionHistory,
    topUpAmountHandler,
    showWalletTopUp,
    setShowWalletTop,
    topUpAmount,
    errorMessage,
    mpesaPay,
    showError,
    isLoadingFlutterwavePaymentDetails,
    paymentNavigationHandler,
    flutterwavePay,
    confirmFlutterwavePaymentStatus,
    promoCodePay,
    confirmPaybillPaymentStatus,
  }));

  return (
    <WalletContext.Provider value={value}>{children}</WalletContext.Provider>
  );
}

export { useWalletContext, WalletProvider };

WalletProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
