/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";

import { useAuth } from "../../utils/Contexts/V2/AuthenticationContext";
import UploadImageIcon from "../../assets/icons/upload-image-icon.png";
import TapiLogo from "../../assets/icons/tappi-white-logo.svg";
import ThryveLogo from "../../assets/icons/thryve-logo.svg";
import { useAppContext } from "../../utils/Contexts/AppContext";
import Button from "../forms/Button";

const modalStyles = {
  content: {
    inset: "auto 0 0 0",
    borderRadius: "16px 16px 0 0",
  },
  overlay: {
    backgroundColor: "#00000033",
  },
};

export default function FacebookAdPreview({
  editable,
  setAdImage,
  image,
  description,
  onDeleteImage,
  onChange,
  setImageIdBeingUpdated,
}) {
  const { user } = useAuth();
  const { appVendor } = useAppContext();

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isDeletingImage, setIsDeletingImage] = useState(false);

  function handleDeleteImage() {
    setIsDeleteModalVisible(true);
  }

  useEffect(() => {
    if (image.id) {
      setImageIdBeingUpdated(image.id);
    } else {
      setImageIdBeingUpdated(null);
    }
  });

  const [logo, setLogo] = useState(null);

  useEffect(() => {
    if (appVendor === "thryve") {
      setLogo(ThryveLogo);
    } else {
      setLogo(TapiLogo);
    }
  }, [appVendor]);

  return (
    <div className="border rounded-lg my-4">
      <ReactModal
        isOpen={isDeleteModalVisible}
        onRequestClose={() => setIsDeleteModalVisible(false)}
        style={modalStyles}
      >
        <fieldset disabled={isDeletingImage} aria-busy={isDeletingImage}>
          <div className="flex justify-between items-center">
            <h3>Delete Image</h3>
            <button
              type="button"
              onClick={() => setIsDeleteModalVisible(false)}
            >
              <svg
                viewBox="0 0 1024 1024"
                fill="currentColor"
                height="1.5em"
                width="1.5em"
              >
                <path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 00-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z" />
                <path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
              </svg>
            </button>
          </div>

          <div className="mt-4 space-y-4">
            <p className="text-sm">
              Are you sure you want to delete this image?
            </p>
            <div className="flex space-x-2">
              <Button
                onClick={async () => {
                  setIsDeletingImage(true);
                  await onDeleteImage(image.id);
                  setIsDeletingImage(false);
                  setIsDeleteModalVisible(false);
                }}
                size="sm"
                isLoading={isDeletingImage}
              >
                Yes
              </Button>
              <Button
                onClick={() => setIsDeleteModalVisible(false)}
                size="sm"
                variant="outline"
                className="w-full"
                isLoading={isDeletingImage}
              >
                Cancel
              </Button>
            </div>
          </div>
        </fieldset>
      </ReactModal>
      <div className="flex space-x-2 px-4 py-2">
        <div className="bg-primary-50 h-10 w-10 rounded-full flex items-center justify-center">
          <img src={logo} alt="Tappi Logo" />
        </div>
        <div className="flex flex-col">
          <p className="text-[#1A1A1A] font-semibold text-sm">
            {appVendor === "thryve" ? "MTN Thryve" : "Tappi"}
          </p>
          <p className="text-xs text-[#566370]">Sponsored</p>
        </div>
      </div>

      <div className="border-transparent">
        <div className="h-80 w-full relative">
          {editable && image && (
            <button
              type="button"
              className="h-8 w-8 flex items-center justify-center p-0 rounded-full bg-white absolute right-[6px] top-[6px] z-[5]"
              title="Delete Photo"
              onClick={handleDeleteImage}
            >
              <svg
                height="1.5em"
                width="1.5em"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 6H5H21"
                  stroke="#101828"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z"
                  stroke="#101828"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14 11V17"
                  stroke="#101828"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 11V17"
                  stroke="#101828"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          )}
          {editable && (
            <div>
              <input
                type="file"
                id="businessBannerImageInput"
                hidden
                onChange={(e) => {
                  if (FileReader && e.target.files && e.target.files.length) {
                    const fr = new FileReader();

                    fr.onload = () => {
                      const src = fr.result;
                      setAdImage(src);
                    };

                    fr.readAsDataURL(e.target.files[0]);

                    onChange(e);
                  }
                  // onChange(e);
                }}
                name="businessBannerImage"
                accept="image/*"
              />

              <label
                htmlFor="businessBannerImageInput"
                className="bg-[#1A1A1A] h-[32px] w-[32px] flex items-center justify-center rounded-full hover:cursor-pointer absolute top-[37.5%] left-[50%]"
              >
                {image.id ? (
                  <svg
                    viewBox="0 0 512 512"
                    fill="white"
                    className="h-[32px] w-[32px] rotate-180"
                  >
                    <path d="M256 48C141.31 48 48 141.32 48 256c0 114.86 93.14 208 208 208 114.69 0 208-93.31 208-208 0-114.87-93.13-208-208-208zm0 313a94 94 0 010-188h4.21l-14.11-14.1a14 14 0 0119.8-19.8l40 40a14 14 0 010 19.8l-40 40a14 14 0 01-19.8-19.8l18-18c-2.38-.1-5.1-.1-8.1-.1a66 66 0 1066 66 14 14 0 0128 0 94.11 94.11 0 01-94 94z" />
                  </svg>
                ) : (
                  <img
                    src={UploadImageIcon}
                    alt="Upload Banner"
                    className="h-[16px] w-[16px]"
                    aria-label="Upload Ad Image"
                    title="Upload Ad Image"
                  />
                )}
              </label>
            </div>
          )}
          <img
            src={image.image_file || image}
            alt="Ad"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
      <div className="pb-4">
        <button
          type="button"
          className="flex items-center justify-between px-4 py-2 bg-[#F2F2F2] w-full"
        >
          <div className="text-[15px] text-[#202D62] font-medium">
            <p>Learn More</p>
          </div>
          <svg fill="#006187" viewBox="0 0 16 16" height="1em" width="1em">
            <path
              fillRule="evenodd"
              d="M4.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L10.293 8 4.646 2.354a.5.5 0 010-.708z"
            />
          </svg>
        </button>
        <div className="flex flex-col text-[#1A1A1A] pt-2 px-4 text-[12px] sm:text-xs">
          <p className="font-semibold">{user?.business_name}</p>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
}

FacebookAdPreview.propTypes = {
  editable: PropTypes.bool,
  setAdImage: PropTypes.func,
  image: PropTypes.string,
  description: PropTypes.string,
  onDeleteImage: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  setImageIdBeingUpdated: PropTypes.func,
};

FacebookAdPreview.defaultProps = {
  editable: false,
  setAdImage: () => null,
  image: "",
  description: "",
  setImageIdBeingUpdated: () => null,
};
