/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { useStateMachine } from "little-state-machine";
import moment from "moment/moment";

import { updateAdJourney } from "../utils/stateMachine";
import Button from "./forms/Button";
import { useAppContext } from "../utils/Contexts/AppContext";

export default function FacebookAdsDurationModal({
  modalIsOpen,
  closeModal,
  modalStyles,
}) {
  const { state, actions } = useStateMachine({ updateAdJourney });

  const { appTheme } = useAppContext();

  const [disableDecrement, setDisableDecrement] = useState(false);

  const [formState, setFormState] = useState({
    startDate:
      typeof state.adJourney.durationData.startDate === "string"
        ? moment(state.adJourney.durationData.startDate)
        : state.adJourney.durationData.startDate,
    endDate:
      typeof state.adJourney.durationData.endDate === "string"
        ? moment(state.adJourney.durationData.endDate)
        : state.adJourney.durationData.endDate,
  });

  const [duration, setDuration] = useState(0);
  const [formattedDuration, setFormattedDuration] = useState("");

  useEffect(() => {
    const diff = formState.endDate.diff(formState.startDate, "days");
    setDuration(diff);

    if (diff > 1) {
      setFormattedDuration(`${diff} Days`);
    } else {
      setFormattedDuration(`${diff} Day`);
    }

    actions.updateAdJourney({
      durationData: {
        ...state.adJourney.durationData,
        days: diff,
      },
    });
  }, [formState]);

  function handleSubmit() {
    actions.updateAdJourney({
      durationData: {
        ...state.adJourney.durationData,
        ...formState,
        days: duration,
      },
    });

    closeModal();
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={modalStyles}
      contentLabel="Duration Modal"
      id="duration-modal"
      shouldCloseOnOverlayClick={false}
    >
      <form onSubmit={handleSubmit} className={appTheme}>
        <fieldset className="space-y-4">
          <div className="flex justify-between items-center">
            <h2>Edit Duration</h2>
            <button type="button" onClick={closeModal}>
              <svg
                viewBox="0 0 1024 1024"
                fill="currentColor"
                height="1.5em"
                width="1.5em"
              >
                <path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 00-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z" />
                <path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
              </svg>
            </button>
          </div>
          <div className="my-4 border py-2 px-4 rounded-lg flex justify-between items-center">
            <h4>{formattedDuration}</h4>
            <div className="space-x-2">
              <button
                type="button"
                className="h-6 w-6 border rounded-full inline-flex items-center justify-center"
                onClick={() =>
                  setFormState((currentState) => {
                    setDisableDecrement(false);
                    return {
                      ...currentState,
                      endDate: currentState.endDate.clone().add(1, "days"),
                    };
                  })
                }
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  height="1em"
                  width="1em"
                >
                  <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6z" />
                </svg>
              </button>
              <button
                type="button"
                className="h-6 w-6 border rounded-full inline-flex items-center justify-center disabled:cursor-not-allowed"
                onClick={() =>
                  setFormState((currentState) => {
                    let newEndDate = currentState.endDate
                      .clone()
                      .subtract(1, "day");

                    if (newEndDate.diff(currentState.startDate, "day") < 1) {
                      newEndDate = currentState.endDate;
                      setDisableDecrement(true);
                    }
                    return {
                      ...currentState,
                      endDate: newEndDate,
                    };
                  })
                }
                disabled={disableDecrement}
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  height="1em"
                  width="1em"
                >
                  <path d="M5 11h14v2H5z" />
                </svg>
              </button>
            </div>
          </div>

          <div className="flex text-sm space-x-2">
            <div className="border p-2 rounded-lg">
              <label htmlFor="startDateInput">Start Date</label>
              <input
                type="date"
                name="startDate"
                id="startDateInput"
                style={{ height: "2rem" }}
                value={formState.startDate.format("YYYY-MM-DD")}
                onChange={(e) => {
                  setFormState((currentState) => ({
                    ...currentState,
                    startDate: moment(e.target.value),
                  }));
                }}
                min={moment().format("YYYY-MM-DD")}
                max={formState.endDate
                  .clone()
                  .subtract(1, "day")
                  .format("YYYY-MM-DD")}
              />
            </div>
            <div className="border p-2 rounded-lg">
              <label htmlFor="endDateInput">End Date</label>
              <input
                type="date"
                name="endDate"
                id="endDateInput"
                style={{ height: "2rem" }}
                value={formState.endDate.format("YYYY-MM-DD")}
                onChange={(e) => {
                  setFormState((currentState) => ({
                    ...currentState,
                    endDate: moment(e.target.value),
                  }));
                }}
                min={formState.startDate
                  .clone()
                  .add(1, "day")
                  .format("YYYY-MM-DD")}
              />
            </div>
          </div>

          <div className="flex justify-between">
            <Button type="submit" size="md">
              Update
            </Button>
          </div>
        </fieldset>
      </form>
    </Modal>
  );
}

FacebookAdsDurationModal.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalStyles: PropTypes.object.isRequired,
};
