import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { useStateMachine } from "little-state-machine";

import VerifyImage from "../../assets/images/verify-otp.png";
import { useAuth } from "../../utils/Contexts/V2/AuthenticationContext";
import { updateState } from "../../utils/stateMachine";
import Button from "../../components/forms/Button";
import { getMerchantOTP } from "../../utils/requests-v2";
import InputError from "../../components/forms/InputError";
import { validatePhoneNumber } from "../../utils/validators";

export default function SubmitOTP() {
  const [OTP, setOTP] = useState("");
  const navigate = useNavigate();

  const [remainingSeconds, setRemainingSeconds] = useState(60);
  const [OTPRequestCount, setOTPRequestCount] = useState(0);

  const [OTPError, setOTPError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { state } = useStateMachine({ updateState });

  const auth = useAuth();

  async function handleSubmitOTP(e) {
    e.preventDefault();

    setIsLoading(true);
    await auth
      .login({
        username: state.phoneNumber.slice(1),
        password: OTP,
      })
      .then(() => {
        setIsLoading(false);
        navigate("/");
      })
      .catch(() => {
        setIsLoading(false);
        setOTPError("Invalid verification code, please try again");
      });
  }

  // If we cannot get the user's phone number from persisted state, redirect to login page
  useEffect(() => {
    if (!validatePhoneNumber(state.phoneNumber, state.countryCode)) {
      navigate("/join");
    }
  }, []);

  // This effect counts down the timer
  useEffect(() => {
    const today = new Date().getTime();

    let countDownTime;

    if (OTPRequestCount > 0) {
      countDownTime = today + 120000; // Set it to 120 seconds after user requests OTP at least once
    } else {
      countDownTime = today + 60000; // Default 60 seconds timer
    }

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownTime - now;

      const seconds = Math.floor((distance % (1000 * 120)) / 1000); // Dividing by 120 so we can be able to count down to max of 120 seconds

      setRemainingSeconds(seconds);

      // Clear interval if timer hits zero
      if (distance < 0) {
        clearInterval(interval);
        setRemainingSeconds(0);
      }
    }, 1000);

    // Cleanup function to clear the interval
    return () => clearInterval(interval);
  }, [OTPRequestCount]);

  return (
    <div className="md mx-auto w-full px-2 h-[100vh] bg-[#F9F6F2]">
      <div className="flex flex-col items-center justify-center h-[inherit] text-center max-w-[400px] mx-auto">
        <div className="my-8">
          <img
            src={VerifyImage}
            height={200}
            width={200}
            alt="Verify OTP"
            className="mx-auto mb-4"
          />
          <h3 className="text-xl my-2">Verify Your Account</h3>
          <p className="text-sm font-normal">
            Enter the 6 digit OTP code sent to your phone number & email address
          </p>
        </div>

        <form onSubmit={handleSubmitOTP} className="my-2">
          <OtpInput
            isInputNum
            value={OTP}
            onChange={(value) => {
              setOTP(value);
              setOTPError("");
            }}
            inputStyle={{
              border: "1px solid #D0D5DD",
              width: "100%",
              height: "50px",
            }}
            containerStyle={`flex justify-between otp-input my-4 ${
              OTPError
                ? "border border-[#CD3717] shadow shadow-[#CD371730] rounded-xl"
                : ""
            }`}
            shouldAutoFocus
            hasErrored={OTPError}
            errorStyle={{ borderColor: "#D0D5DD50" }}
            numInputs={6}
          />

          <InputError error={OTPError} />

          <div className="mt-12 mb-6">
            <p className="text-[#566370] text-sm mt-6">
              Didn&apos;t receive the code?
            </p>
            <button
              type="button"
              className="text-purple-50 mb-4"
              style={{ height: "1.5rem", padding: "0.5rem 2rem" }}
              onClick={async () => {
                setOTP("");
                setOTPError("");
                await getMerchantOTP({
                  phoneNumber: state.phoneNumber.slice(1),
                });
                setOTPRequestCount((count) => count + 1);
              }}
              disabled={remainingSeconds >= 1}
            >
              Resend code{" "}
              {remainingSeconds > 0 && <span> in {remainingSeconds}</span>}
            </button>
          </div>

          <Button
            type="submit"
            disabled={OTP.length < 6 || isLoading}
            isLoading={isLoading}
          >
            Verify
          </Button>
        </form>
      </div>
    </div>
  );
}
