/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import slugify from "slugify";
import { useStateMachine } from "little-state-machine";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import BusinessProfileIcon from "../assets/images/profile.png";
import PinLocationIcon from "../assets/icons/google-maps-pin-icon.png";
import CustomDropZone from "../components/CustomDropZone";
import MerchantBanner from "../assets/images/default-merchant-banner.png";
import LabelledInput from "../components/forms/LabelledInput";
import { updateState } from "../utils/stateMachine";
import UploadImageIcon from "../assets/icons/upload-image-icon.png";
import LeftArrow from "../assets/icons/left-arrow.png";
import { getIndustries } from "../utils/requests";
import Button from "../components/forms/Button";

function slugifySubdomain(subdomain) {
  return slugify(subdomain, {
    remove: /[^A-Za-z0-9 -]/g,
    lower: true,
    strict: true,
    locale: "en-US",
    trim: true,
  });
}

export default function BusinessRegistration() {
  const { actions, state } = useStateMachine({
    updateState,
  });

  const { register, handleSubmit } = useForm({
    defaultValues: {
      industry: state.industry,
      businessDescription: state.businessDescription,
      address: state.address,
      whatsappBusinessNumber: state.whatsappBusinessNumber,
      instagramUsername: state.instagramUsername,
      facebookUsername: state.facebookUsername,
      businessWebsite: state.businessWebsite,
    },
  });

  const [subDomain, setSubDomain] = useState("");

  const navigate = useNavigate();

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  function onSubmit(data) {
    console.log({ ...data, subDomain, files: acceptedFiles });
    actions.updateState({
      ...data,
      subDomain,
      files: acceptedFiles,
    });

    navigate("/join/business-working-hours");
  }

  // This effect runs to automatically set a slufigied subdomain
  useEffect(() => {
    if (state.businessName) {
      const subDomainSlug = slugifySubdomain(state.businessName);
      setSubDomain(`${subDomainSlug}.tappi.${state.countryCode.toLowerCase()}`);
    } else {
      setSubDomain("");
    }
  }, [state.businessName]);

  function goBack() {
    navigate(-1);
  }

  const { data } = useQuery(["industries"], async () => getIndustries());

  return (
    <div className="container mx-auto my-4 max-w-[400px]">
      <div className="flex items-center justify-start w-full py-4">
        <button type="button" className="mr-8" onClick={goBack}>
          <img src={LeftArrow} alt="Go Back" width={16} />
        </button>
        <h1 className="py-2 px-4">Set Up Business Webpage</h1>
      </div>
      <div className="w-full relative">
        <div className="relative">
          <input
            type="file"
            id="businessBannerImageInput"
            hidden
            onChange={(e) => console.log(e.target.value)}
            name="businessBannerImage"
          />
          <label
            htmlFor="businessBannerImageInput"
            className="bg-[#1A1A1A] h-[32px] w-[32px] flex items-center justify-center rounded-full hover:cursor-pointer absolute top-[37.5%] left-[50%]"
          >
            <img
              src={UploadImageIcon}
              alt="Upload Banner"
              className="h-[16px] w-[16px]"
              aria-label="Upload Business Banner"
              title="Upload Business Banner"
            />
          </label>
          <img src={MerchantBanner} alt="Banner" className="w-full h-auto" />
        </div>
        <div className="relative">
          <input
            type="file"
            id="businessLogoImageInput"
            hidden
            onChange={(e) => console.log(e.target.value)}
            name="businessBannerImage"
          />
          <label
            htmlFor="businessLogoImageInput"
            className="bg-[#1A1A1A] h-[32px] w-[32px] flex items-center justify-center rounded-full hover:cursor-pointer absolute bottom-[-12px] left-[22.5%] z-[1]"
          >
            <img
              src={UploadImageIcon}
              alt="Upload Logo"
              className="h-[16px] w-[16px]"
              aria-label="Upload Business Logo"
              title="Upload Business Logo"
            />
          </label>
          <img
            src={BusinessProfileIcon}
            alt="Business"
            className="mt-[-42px] ml-8 h-[84px] w-[84px] relative"
          />
        </div>
      </div>

      <section className="mt-12 px-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3 className="font-medium my-2">Business Information</h3>

          <div className="flex justify-center">
            <div className="w-full">
              <LabelledInput
                id="subDomainInput"
                label="Subdomain"
                name="subDomain"
                readOnly
                value={subDomain}
                defaultValue={state.subDomain}
              />

              <div className="mb-3 w-full">
                {data && (
                  <select
                    className="form-select appearance-none
                    block
                    w-full h-[58px]
                    px-3
                    py-1.5
                    text-sm
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding bg-no-repeat
                    border border-solid border-gray-300
                    rounded-lg
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-purple-40 focus:outline-none focus:shadow"
                    aria-label="Default select example"
                    required
                    {...register("industry")}
                    defaultValue={state.industry}
                  >
                    <option value="">Category / Industry</option>
                    {data.map((industry) => (
                      <option key={industry.id} value={industry.name}>
                        {industry.name}
                      </option>
                    ))}
                  </select>
                )}
              </div>

              <div className="mb-3 w-full">
                <textarea
                  className="
                    form-control
                    block
                    w-full h-auto
                    px-3
                    py-1.5
                    text-sm
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded-lg
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-purple-50 focus:outline-none focus:shadow
                "
                  id="businessDescriptionInput"
                  rows="3"
                  placeholder="Provide A Brief Description (min 30 characters)"
                  {...register("businessDescription")}
                  defaultValue={state.businessDescription}
                  minLength={30}
                  required
                />
              </div>

              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control
                    block
                    w-full
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-gray-300 focus:shadow-none focus:outline-none"
                  placeholder="Address"
                  aria-label="Address"
                  aria-describedby="location-select"
                  id="addressInput"
                  {...register("address")}
                />
                <button
                  className="input-group-text flex items-center py-[18px] h-14"
                  id="location-select"
                  type="button"
                  tabIndex={-1}
                  style={{ paddingLeft: "10px" }}
                >
                  <img
                    src={PinLocationIcon}
                    alt="location"
                    style={{ height: 14, width: 12 }}
                  />
                  <span className="ml-2 text-sm font-normal text-green-50">
                    Use map
                  </span>
                </button>
              </div>

              <LabelledInput
                id="whatAppBusinessNumberInput"
                name="whatsappBusinessNumber"
                register={register}
                type="tel"
                label="WhatsApp Business Number"
                defaultValue={state.whatsappBusinessNumber}
              />

              <CustomDropZone
                getInputProps={getInputProps}
                getRootProps={getRootProps}
                acceptedFiles={acceptedFiles}
                label="Upload pricelist/menu"
                className="dropzone my-6"
              />

              <hr className="my-4" />

              <h3 className="font-medium my-2 mt-4">Social Links</h3>

              <div className="input-group mb-3">
                <span className="input-group-text text-sm font-medium text-black border-r-0 h-14 py-[18px]">
                  instagram.com/
                </span>
                <input
                  type="text"
                  className="form-control
                    block
                    w-full
                    pr-3
                    pl-1
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-gray-300 focus:shadow-none focus:outline-none
                    border-l-0
                    "
                  placeholder="username"
                  aria-label="instagramUsername"
                  aria-describedby="instagramUsernameSelect"
                  id="instagramUsername"
                  {...register("instagramUsername")}
                />
              </div>

              <div className="input-group mb-3">
                <span className="input-group-text text-sm font-medium text-black border-r-0 h-14 py-[18px]">
                  facebook.com/
                </span>
                <input
                  type="text"
                  className="form-control
                    block
                    w-full
                    pr-3
                    pl-1
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-gray-300 focus:shadow-none focus:outline-none
                    border-l-0
                    "
                  placeholder="username"
                  aria-label="Provied Facebook Username"
                  aria-describedby="facebookUsernameInput"
                  id="facebookUsername"
                  {...register("facebookUsername")}
                />
              </div>

              <LabelledInput
                type="text"
                id="businessWebsiteInput"
                register={register}
                label="Website"
                name="businessWebsite"
                defaultValue={state.businessWebsite}
              />

              <div className="text-center lg:text-left">
                <Button type="submit">Save</Button>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}
