import React, { useCallback, useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import { useStateMachine } from "little-state-machine";
import { Navigate } from "react-router-dom";

import LoginForm2 from "../../components/V2/LoginForm2";
import { updateState } from "../../utils/stateMachine";
import CarouseImage1 from "../../assets/images/Home.png";
import CarouselImage2 from "../../assets/images/Home-1.png";
import CarouselImage3 from "../../assets/images/Home-2.png";
import { useAuth } from "../../utils/Contexts/V2/AuthenticationContext";
import { useAppContext } from "../../utils/Contexts/AppContext";
import RegistrationForm from "../../components/V2/RegistrationForm";
import ThryveLogo from "../../assets/icons/thryve-logo.svg";
import Spinner from "../../components/Spinner";

export default function Join() {
  const { actions } = useStateMachine({
    updateState,
  });
  const [phoneNumber, setPhoneNumber] = useState("");

  const appContext = useAppContext();

  // This function is passed to other form components to update the phone number of the phone picker input
  // It also updates the state machine and the businessContext when users change the phone number on the phone picker
  const updatePhoneNumber = useCallback((phone) => {
    setPhoneNumber(phone);

    actions.updateState({
      phoneNumber: phone,
    });
  }, []);

  // This function is passed to other form components to update the country code of the phone picker input
  // It also updates the state machine and the businessContext when users change the country on the phone picker
  const updateCountryCode = useCallback((countryCode) => {
    if (countryCode) {
      appContext.setCountry(countryCode);
      actions.updateState({
        countryCode,
      });
    }
  }, []);

  // This effect sets the country code in the state machine to match what we have in businessContext
  // It should run whenever the business context changes
  useEffect(() => {
    if (appContext.country) {
      actions.updateState({
        countryCode: appContext.country,
      });
    }
  }, [appContext.country]);

  const { isAuthenticated } = useAuth();

  return (
    <div>
      {isAuthenticated ? (
        <Navigate to="/" />
      ) : (
        <div>
          {/* Wait for us to get countryCode from Bridge */}
          {appContext.country ? (
            <section className="h-screen">
              <div className="h-full text-gray-800">
                <div className="flex justify-start xl:justify-start items-center flex-nowrap h-full">
                  <div className="hidden md:block md:h-screen md:bg-primary-50 md:px-6 md:py-16 w-full grow-0 shrink-1 md:shrink-0 basis-auto md:w-6/12 mb-12 md:mb-0">
                    <div
                      id="carouselIndicators"
                      className="carousel slide relative"
                      data-bs-ride="carousel"
                    >
                      <div className="carousel-indicators absolute right-0 lg:bottom-[390px] md:bottom-[490px] xl:bottom-[33%] left-0 flex justify-center p-0 mb-4">
                        <button
                          type="button"
                          data-bs-target="#carouselIndicators"
                          data-bs-slide-to="0"
                          className="active h-4 w-4 rounded-full"
                          aria-current="true"
                          aria-label="Slide 1"
                        />
                        <button
                          type="button"
                          data-bs-target="#carouselIndicators"
                          data-bs-slide-to="1"
                          className="h-4 w-4 rounded-full"
                          aria-label="Slide 2"
                        />
                        <button
                          type="button"
                          data-bs-target="#carouselIndicators"
                          data-bs-slide-to="2"
                          className="h-4 w-4 rounded-full"
                          aria-label="Slide 3"
                        />
                      </div>
                      <div className="carousel-inner relative w-full overflow-hidden h-screen">
                        <div className="carousel-item active float-left w-full">
                          <img
                            src={CarouseImage1}
                            className="block w-full max-h-[60vh] w-auto mx-auto"
                            alt="App Dashoard"
                          />
                          <div className="text-center text-white mt-12 max-w-[485px] mx-auto">
                            <h2 className="font-bold text-3xl leading-10 text-white">
                              Let&apos;s grow your business together!
                            </h2>
                            <p className="font-medium text-2xl leading-7 text-[#FFFFFFCC]">
                              Increase your business visibility and customer
                              reach
                            </p>
                          </div>
                        </div>
                        <div className="carousel-item float-left w-full">
                          <img
                            src={CarouselImage2}
                            className="block w-full max-h-[60vh] w-auto mx-auto"
                            alt="Ads Dashboard"
                          />
                          <div className="text-center text-white mt-12 max-w-[500px] mx-auto">
                            <h2 className="font-bold text-3xl leading-10 text-white">
                              Let&apos;s grow your business together!
                            </h2>
                            <p className="font-medium text-2xl leading-7 text-[#FFFFFFCC]">
                              Increase your business visibility and customer
                              reach
                            </p>
                          </div>
                        </div>
                        <div className="carousel-item float-left w-full">
                          <img
                            src={CarouselImage3}
                            className="block w-full max-h-[60vh] w-auto mx-auto"
                            alt="SMS Marketing"
                          />
                          <div className="text-center text-white mt-12 max-w-[485px] mx-auto">
                            <h2 className="font-bold text-3xl leading-10 text-white">
                              Let&apos;s grow your business together!
                            </h2>
                            <p className="font-medium text-2xl leading-7 text-[#FFFFFFCC]">
                              Increase your business visibility and customer
                              reach
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full h-full mb-0 flex">
                    <div className="h-full min-h-[68vh] md:min-h-[58vh] pt-8 w-full flex flex-col items-center justify-start">
                      {appContext.appVendor === "thryve" ? (
                        <img src={ThryveLogo} className="w-auto" alt="Sample" />
                      ) : null}
                      <ul
                        className="nav nav-tabs flex flex-row list-none pl-0 my-4 border-primary-50 thryve:border-secondary-50 border-2"
                        id="tabs-tab"
                        role="tablist"
                        style={{ borderRadius: 100 }}
                      >
                        <li
                          className="nav-item"
                          role="presentation"
                          style={{ borderRadius: "inherit" }}
                        >
                          <a
                            href="#tabs-register"
                            className="
                          nav-link
                          block
                          font-medium text-xs leading-tight text-center
                          border-x-0 border-t-0 border-transparent
                          hover:border-transparent hover:bg-gray-100
                          focus:border-transparent
                          py-2
                          active
                        "
                            id="tabs-register-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#tabs-register"
                            role="tab"
                            aria-controls="tabs-register"
                            aria-selected="false"
                            style={{ borderRadius: "inherit", width: 100 }}
                          >
                            Sign Up
                          </a>
                        </li>
                        <li
                          className="nav-item"
                          role="presentation"
                          style={{ borderRadius: "inherit" }}
                        >
                          <a
                            href="#tabs-login"
                            className="nav-link block font-medium text-xs leading-tight text-center border-x-0 border-t-0 border-transparent hover:border-transparent hover:bg-gray-100 focus:border-transparent py-2 "
                            id="tabs-login-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#tabs-login"
                            role="tab"
                            aria-controls="tabs-login"
                            aria-selected="true"
                            style={{ borderRadius: "inherit", width: 100 }}
                          >
                            Login
                          </a>
                        </li>
                      </ul>
                      <div
                        className="tab-content w-screen md:w-full px-4 pb-6 flex-grow"
                        id="tabs-tabContent"
                      >
                        <div
                          className="tab-pane fade show active"
                          id="tabs-register"
                          role="tabpanel"
                          aria-labelledby="tabs-register-tab"
                        >
                          <div className="flex flex-col h-full">
                            {appContext.country && (
                              <RegistrationForm
                                phoneNumber={phoneNumber}
                                setPhoneNumber={updatePhoneNumber}
                                updateCountryCode={updateCountryCode}
                              />
                            )}
                          </div>
                        </div>
                        <div
                          className="tab-pane fade h-full"
                          id="tabs-login"
                          role="tabpanel"
                          aria-labelledby="tabs-login-tab"
                        >
                          <div className="flex flex-col h-full">
                            <div className="grow">
                              <LoginForm2
                                phoneNumber={phoneNumber}
                                setPhoneNumber={updatePhoneNumber}
                                updateCountryCode={updateCountryCode}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            // Spinner
            <div className="h-screen w-screen flex justify-center items-center">
              <Spinner />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
