/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PhoneInput from "react-phone-number-input";
import PropTypes from "prop-types";
import classNames from "classnames";

export default function CustomPhoneInput({
  errors,
  setErrors,
  phoneNumber,
  setPhoneNumber,
  selectedCountryCode,
  setSelectedCountryCode,
  countries,
  className,
  ...rest
}) {
  return (
    <div>
      <PhoneInput
        placeholder="Enter phone number"
        international
        countryCallingCodeEditable={false}
        value={phoneNumber}
        defaultCountry={selectedCountryCode}
        onChange={(phone) => {
          setErrors({});
          setPhoneNumber(phone);
        }}
        className={classNames(
          `form-control block w-full px-4 py-2 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-white focus:outline-none ${
            errors.phoneNumber ? "border-2 border-[#CD3717]" : "border-gray-300"
          }`,
          className
        )}
        countries={countries}
        required
        numberInputProps={{ required: true }}
        onCountryChange={(country) => setSelectedCountryCode(country)}
        {...rest}
      />
      {errors.phoneNumber && (
        <div className="text-xs text-[#CD3717] mt-2 font-normal">
          {errors.phoneNumber}
        </div>
      )}
    </div>
  );
}

CustomPhoneInput.propTypes = {
  errors: PropTypes.shape({ phoneNumber: PropTypes.string }),
  setErrors: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  setPhoneNumber: PropTypes.func.isRequired,
  selectedCountryCode: PropTypes.string.isRequired,
  setSelectedCountryCode: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
};

CustomPhoneInput.defaultProps = {
  errors: {},
  countries: ["KE", "NG"],
  className: "",
};
