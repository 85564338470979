import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { useAuth } from "../../utils/Contexts/V2/AuthenticationContext";
import Spinner from "../Spinner";

/**
 * This Route is used to protect routes that require authentication
 * Shows a spinner while the authentication state is being fetched
 */
export default function PrivateRoute({ children }) {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate("/join");
    }
  }, [isAuthenticated]);

  return (
    <div>
      {isAuthenticated === true ? (
        <div>{children}</div>
      ) : (
        // Spinner
        <div className="h-screen w-screen flex justify-center items-center">
          <Spinner />
        </div>
      )}
    </div>
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};
