import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import { useQueryClient } from "@tanstack/react-query";
import { useStateMachine } from "little-state-machine";

import authenticationService from "../authenticationService";
import { clearStore } from "../stateMachine";

const AuthContext = createContext(null);

function useAuth() {
  return useContext(AuthContext);
}

function AuthProvider({ children }) {
  // User can be `undefined`, `null`, or an object
  // Undefined means user data is still fetching, not yet loaded
  const [user, setUser] = useState(undefined);

  // Initial state obtained by calling `getCurrentUser`
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);

  const { actions } = useStateMachine({ clearStore });

  // This effect runs only once, and it checks if there is an existing user and sets them
  // It should only run once as it sets the user on page load
  useEffect(() => {
    if (authenticationService.getCurrentUser()) {
      const userData = authenticationService.getCurrentUser();

      if (!userData) {
        setUser(null);
        setIsAuthenticated(false);
        authenticationService.logout();
      } else {
        setIsAuthenticated(true);
        setUser(userData);
      }
    } else {
      setUser(null);
      setIsAuthenticated(false);
      authenticationService.logout();
    }
  }, []);

  const login = async ({ username, password }) =>
    authenticationService
      .login({ username, password })
      .then((res) => {
        setUser(res);
        setIsAuthenticated(true);
        return Promise.resolve(res);
      })
      .catch((error) => {
        setUser(null);
        setIsAuthenticated(false);
        return Promise.reject(error);
      });

  const queryClient = useQueryClient();

  const logout = async (callback) => {
    authenticationService.logout();
    setUser(null);
    setIsAuthenticated(false);

    actions.clearStore();

    // Clear all cached queries after user logs out
    queryClient.clear();
    if (typeof callback === "function") callback();
  };

  const updateUser = (userData) => {
    authenticationService.updateUserData(userData);
    const userInfo = authenticationService.getCurrentUser();
    setUser(userInfo);
  };

  const value = useMemo(
    () => ({ user, login, logout, isAuthenticated, updateUser }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export { useAuth, AuthProvider };

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
