import React from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/layout/V2/Layout";
import Button from "../../components/forms/Button";
import { usePaymentInfoContext } from "../../utils/Contexts/V2/PaymentInfoContext";
import Spinner from "../../components/Spinner";

export default function BankDetails() {
  const navigate = useNavigate();
  const {
    isLoadingPaymentInfo,
    bankList,
    paymentInfo,
    errorField,
    setBankInfoHandler,
    savePaymentInfoHandler,
    bankInfoFieldBlurHandler,
    isSavingPaymentInfo,
  } = usePaymentInfoContext();

  return (
    <Layout headerTitle="My Bank Details" header>
      {isLoadingPaymentInfo ? (
        <div className="h-screen w-screen flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className="relative h-screen pt-20 max-w-screen-xl container mx-auto flex flex-col justify-between items-center">
          <div className="flex flex-col items-center w-full px-4">
            <p className="text-sm font-bold text-[#566370]">
              Please input your correct details.
            </p>
            <div className="flex flex-row space-x-4 items-start py-2.5 px-3 bg-[#EEF1F2] rounded-lg mt-4">
              <svg width="16" height="16" viewBox="0 0 16 16">
                <path
                  d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8.8 12H7.2V7.2H8.8V12ZM8.8 5.6H7.2V4H8.8V5.6Z"
                  fill="#566370"
                />
              </svg>
              <p className="text-sm font-normal text-[#566370] w-[80%]">
                Your details are only used to receive payment from your
                customers. It won’t be used for ANYTHING else.
              </p>
            </div>
            <div className="flex flex-col justify-start items-center w-full py-6 space-y-5">
              <div className="flex flex-col justify-start items-start space-y-3 w-full">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="text-base font-bold text-[#566370]">
                  Bank Name
                </label>
                <select
                  onChange={setBankInfoHandler}
                  id="bank_name"
                  value={paymentInfo?.bank_name}
                  placeholder="Select Bank"
                  className="h-14 w-full px-3 border-2 border-solid rounded-lg outline-none text-sm font-bold text-[#566370]"
                >
                  {bankList?.map((item) => (
                    <option id={item?.bank_id}>{item.bank_name}</option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col justify-start items-start space-y-3 w-full">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="text-base font-bold text-[#566370]">
                  Account Number
                </label>
                <input
                  placeholder="NUBAN 10 digits"
                  type="number"
                  className={`h-14 w-full px-3 border-2 ${
                    errorField.includes("account_number")
                      ? "border-red-50"
                      : "border-solid "
                  }  rounded-lg outline-none text-sm font-bold text-[#566370]`}
                  onChange={setBankInfoHandler}
                  onBlur={bankInfoFieldBlurHandler}
                  id="account_number"
                  value={paymentInfo?.account_number}
                  maxLength={10}
                />
              </div>
              <div className="flex flex-col justify-start items-start space-y-3 w-full">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="text-base font-bold text-[#566370]">
                  Account holder’s name
                </label>
                <input
                  placeholder="e.g. Amaka Odus"
                  type="text"
                  className={`h-14 w-full px-3 border-2 ${
                    errorField.includes("account_name")
                      ? "border-red-50"
                      : "border-solid "
                  }  rounded-lg outline-none text-sm font-bold text-[#566370]`}
                  onChange={setBankInfoHandler}
                  onBlur={bankInfoFieldBlurHandler}
                  id="account_name"
                  value={paymentInfo?.account_name}
                />
              </div>
            </div>
          </div>
          <div className="w-full shadow-t-lg p-4 border-t border-solid">
            <Button
              onClick={() => savePaymentInfoHandler(navigate)}
              className="font-bold"
            >
              {isSavingPaymentInfo ? "Updating..." : "Update"}
            </Button>
          </div>
        </div>
      )}
    </Layout>
  );
}
