import React from "react";
import { useWalletContext } from "../../../utils/Contexts/V2/WalletContext";
import Button from "../../forms/Button";
import { usePaymentContext } from "../../../utils/Contexts/V2/PaymentContext";

function WalletTopUp() {
  const {
    setShowWalletTop,
    topUpAmountHandler,
    inputError,
    topUpAmount,
    currency,
  } = useWalletContext();
  const { setInputError } = usePaymentContext();

  return (
    <div className="relative h-screen w-full flex flex-col items-center">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="absolute h-screen w-full bg-blur"
        onClick={() => setShowWalletTop(false)}
      />
      <div className="absolute -bottom-2 w-full rounded-t-2xl bg-white pt-[1.62rem] pb-6">
        <div className="w-full flex flex-row justify-between items-center pb-[1.12rem] px-4 border-b-[0.069rem] border-[#E7E7E7]">
          <h1 className="text-lg text-[#1A1A1A] font-semibold">
            Top up wallet
          </h1>
          <button type="button" onClick={() => setShowWalletTop(false)}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.0079 18.5383L4.7651 6.29552C4.34681 5.87722 4.34681 5.18347 4.7651 4.76517C5.1834 4.34688 5.87715 4.34688 6.29545 4.76517L18.5382 17.0079C18.9565 17.4262 18.9565 18.12 18.5382 18.5383C18.1199 18.9566 17.4262 18.9566 17.0079 18.5383Z"
                fill="#1A1A1A"
              />
              <path
                d="M4.76551 18.5383C4.34721 18.12 4.34721 17.4262 4.76551 17.0079L17.0083 4.76517C17.4266 4.34688 18.1203 4.34688 18.5386 4.76517C18.9569 5.18347 18.9569 5.87722 18.5386 6.29552L6.29585 18.5383C5.87756 18.9566 5.1838 18.9566 4.76551 18.5383Z"
                fill="#1A1A1A"
              />
            </svg>
          </button>
        </div>
        <div className="w-full flex flex-col space-y-[0.69rem] items-center px-4 py-4 border-b-[0.069rem] border-[#E7E7E7]">
          <p className="text-[#1A1A1A] font-normal text-[0.94rem]">
            How much do you wish to top up?
          </p>
          <div
            className={`flex flex-row justify-start items-center w-full py-5 px-4 border border-solid rounded-lg space-x-2 ${
              inputError && "border-[0.099rem] border-red-50"
            }`}
          >
            <p className="text-[#566370] font-semibold text-sm">{currency}</p>
            <svg
              width="2"
              height="17"
              viewBox="0 0 2 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0.581 16.516H1.72V0.808H0.581V16.516Z" fill="#1A1A1A" />
            </svg>
            <input
              className="h-full w-full outline-none text-[#566370] text-sm font-semibold"
              type="number"
              onBlur={topUpAmountHandler}
            />
          </div>
        </div>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className="px-4 pt-3"
          onClick={() =>
            topUpAmount > 0 ? setShowWalletTop(false) : setInputError(true)
          }
        >
          <Button href={topUpAmount > 0 ? "/wallet/payment" : "/wallet"}>
            <p className="font-semibold text-center pt-1">Continue</p>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default WalletTopUp;
