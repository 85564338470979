import React from "react";
import PropTypes from "prop-types";

export default function LevelComponent({
  className,
  backgroundClassName,
  levelClassName,
  label,
  value,
  total,
}) {
  return (
    <div className={className}>
      <p className="w-28 text-end">{label}</p>
      <div className={backgroundClassName}>
        <div
          className={levelClassName}
          style={{ width: `${(value / total) * 100}%` }}
        />
      </div>
      <span>{value}</span>
    </div>
  );
}

LevelComponent.propTypes = {
  className: PropTypes.string,
  backgroundClassName: PropTypes.string,
  levelClassName: PropTypes.string,
  total: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};

LevelComponent.defaultProps = {
  className: "h-4 flex items-center space-x-2",
  backgroundClassName:
    "h-full flex w-full bg-gray-200 dark:bg-gray-300 mx-auto rounded-xl",
  levelClassName: "h-full bg-purple-900 rounded-xl",
};
