import React from "react";
import { NavLink, useParams } from "react-router-dom";
import Button from "../../../components/forms/Button";

export default function OfferPaymentSuccessful() {
  const { quantity } = useParams();
  return (
    <div className="flex flex-col justify-around items-center w-full px-4 h-screen">
      <div className="flex flex-col justify-start items-center">
        <svg
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_1456_6402)">
            <circle cx="60" cy="48" r="40" className="fill-primary-50" />
            <g clipPath="url(#clip0_1456_6402)">
              <path
                d="M56.6672 53.2866L71.9872 37.9649L74.3455 40.3216L56.6672 57.9999L46.0605 47.3933L48.4172 45.0366L56.6672 53.2866Z"
                className="fill-base"
              />
            </g>
          </g>
          <defs>
            <filter
              id="filter0_d_1456_6402"
              x="0"
              y="0"
              width="120"
              height="120"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="12" />
              <feGaussianBlur stdDeviation="10" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.294118 0 0 0 0 0.113725 0 0 0 0 0.560784 0 0 0 0.2 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1456_6402"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1456_6402"
                result="shape"
              />
            </filter>
            <clipPath id="clip0_1456_6402">
              <rect
                width="40"
                height="40"
                fill="white"
                transform="translate(40 28)"
              />
            </clipPath>
          </defs>
        </svg>
        <p className="text-xl font-semibold text-[#1A1A1A]">Success!</p>
        <p className="text-base font-normal  text-[#1A1A1A] mt-3">
          You just got <span className="font-semibold">{quantity}</span> bulk
          sms
        </p>
      </div>
      <svg
        width="224"
        height="160"
        viewBox="0 0 224 160"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_7836_16204)">
          <path
            d="M28.5676 25.4711C35.6012 25.4711 41.3031 19.7692 41.3031 12.7355C41.3031 5.7019 35.6012 0 28.5676 0C21.5339 0 15.832 5.7019 15.832 12.7355C15.832 19.7692 21.5339 25.4711 28.5676 25.4711Z"
            fill="url(#paint0_linear_7836_16204)"
          />
          <path
            d="M178.154 130.332C173.714 130.331 169.418 128.756 166.03 125.887C162.642 123.018 160.381 119.04 159.65 114.661L159.635 114.568L159.695 114.496C160.69 113.303 161.527 111.985 162.184 110.576C161.458 111.367 160.636 112.065 159.736 112.652L159.436 112.852L159.419 112.492C159.404 112.181 159.396 111.872 159.396 111.574C159.394 109.823 159.636 108.08 160.114 106.395C162.292 98.7406 162.664 90.5212 161.221 81.965C160.974 80.4979 160.851 79.0128 160.851 77.5251C160.853 71.2381 163.093 65.1573 167.17 60.3713C171.247 55.5854 176.894 52.4069 183.101 51.4051C189.307 50.4033 195.668 51.6436 201.043 54.9038C206.419 58.1641 210.459 63.2314 212.439 69.1984L212.499 69.3803L212.321 69.4521C210.988 70.0166 209.574 70.3675 208.131 70.4918C209.656 70.8426 211.22 70.9928 212.783 70.9387L212.948 70.933L212.989 71.0934C213.513 73.1971 213.778 75.3571 213.777 77.5251L213.776 77.7598C213.772 79.4201 214.117 81.0628 214.789 82.5808C215.462 84.0988 216.446 85.458 217.68 86.5699C219.683 88.393 221.268 90.6286 222.326 93.1228C223.383 95.6171 223.887 98.3107 223.804 101.018C223.721 103.726 223.052 106.384 221.844 108.808C220.635 111.233 218.916 113.367 216.804 115.064L216.706 115.143L216.592 115.091C214.176 113.995 212.417 112.801 211.13 111.376C211.988 113.205 213.143 114.879 214.548 116.33L214.73 116.517L214.504 116.649C211.636 118.321 208.376 119.201 205.056 119.2L204.961 119.2C204.928 119.2 204.894 119.2 204.861 119.2C199.782 119.2 194.969 121.163 191.64 124.597C189.887 126.402 187.791 127.84 185.475 128.825C183.159 129.809 180.67 130.322 178.154 130.332Z"
            fill="#F2F2F2"
          />
          <path
            d="M171.515 159.886C171.434 159.886 171.355 159.857 171.294 159.803C171.232 159.75 171.192 159.676 171.18 159.596C171.166 159.495 169.757 149.334 171.023 135.831C172.191 123.36 175.943 105.397 187.184 90.6452C187.211 90.6098 187.245 90.5801 187.283 90.5578C187.322 90.5354 187.364 90.5208 187.408 90.5149C187.452 90.5089 187.497 90.5117 187.54 90.5231C187.583 90.5344 187.623 90.5542 187.659 90.5811C187.694 90.6081 187.724 90.6417 187.746 90.6801C187.768 90.7186 187.783 90.761 187.789 90.8051C187.795 90.8491 187.792 90.8939 187.781 90.9369C187.769 90.9799 187.75 91.0202 187.723 91.0556C176.579 105.68 172.857 123.511 171.697 135.894C170.439 149.319 171.837 159.4 171.851 159.5C171.858 159.548 171.854 159.597 171.84 159.644C171.827 159.69 171.803 159.733 171.771 159.77C171.739 159.806 171.7 159.836 171.656 159.856C171.612 159.876 171.564 159.886 171.515 159.886Z"
            fill="#3F3D56"
          />
          <path
            d="M177.135 110.152C177.063 110.152 176.993 110.129 176.936 110.087C176.878 110.045 176.835 109.986 176.813 109.918C176.79 109.85 176.79 109.777 176.812 109.709C176.835 109.641 176.878 109.581 176.935 109.539C180.138 107.371 183.598 105.61 187.236 104.296C192.919 102.215 201.561 100.329 210.458 103.161C210.5 103.174 210.539 103.196 210.573 103.224C210.607 103.253 210.635 103.288 210.656 103.328C210.676 103.367 210.689 103.41 210.693 103.454C210.696 103.499 210.691 103.543 210.678 103.586C210.664 103.628 210.643 103.667 210.614 103.701C210.585 103.735 210.55 103.763 210.511 103.784C210.471 103.804 210.428 103.817 210.384 103.82C210.339 103.824 210.295 103.819 210.253 103.806C201.538 101.032 193.053 102.887 187.469 104.932C183.89 106.223 180.485 107.955 177.333 110.087C177.276 110.129 177.206 110.152 177.135 110.152Z"
            fill="#3F3D56"
          />
          <path
            d="M39.5948 134.745C36.4366 134.739 31.4645 134.994 28.7562 136.424C26.6749 137.523 24.7796 138.943 23.1395 140.631C22.5074 141.284 21.9087 141.969 21.3457 142.683C19.7882 137.354 19.6069 131.718 20.8188 126.301C20.904 125.936 20.876 125.554 20.7385 125.206C20.601 124.857 20.3606 124.559 20.0493 124.351C19.738 124.143 19.3706 124.034 18.9961 124.04C18.6217 124.046 18.2579 124.166 17.9534 124.384C13.5536 127.593 10.7406 133.031 10.0565 138.532C9.18492 145.54 11.2373 152.603 14.0899 159.067L14.089 159.07C14.0837 159.099 14.0757 159.131 14.0679 159.162C14.0573 159.21 14.0441 159.257 14.0336 159.305C14.015 159.387 13.994 159.471 13.9755 159.553L14.4373 159.532C14.4638 159.545 14.4902 159.556 14.5165 159.569H26.085C26.2383 159.402 26.3812 159.226 26.5127 159.041C27.7928 157.286 28.3233 154.908 28.97 152.762C29.4383 151.208 30.0242 149.692 30.7226 148.227C32.4809 144.548 34.9072 141.226 37.878 138.433C37.9729 138.343 38.0706 138.253 38.1683 138.166C38.6659 137.708 39.4372 137.096 40.1908 136.522C40.3555 136.397 40.4769 136.223 40.5377 136.025C40.5986 135.828 40.5959 135.616 40.5301 135.42C40.4643 135.223 40.3387 135.053 40.171 134.932C40.0032 134.811 39.8017 134.745 39.5948 134.745Z"
            fill="#F2F2F2"
          />
          <path
            d="M117.703 25.667C117.975 26.4279 117.938 27.2653 117.598 27.9986C117.258 28.7318 116.644 29.3023 115.888 29.5869C115.721 29.6494 115.548 29.697 115.372 29.729L113.624 40.4339L109.523 36.6472L111.812 27.0622C111.727 26.2546 111.964 25.446 112.47 24.8113C112.977 24.1766 113.713 23.7669 114.52 23.6706C115.198 23.5928 115.883 23.7481 116.461 24.1109C117.04 24.4737 117.478 25.0224 117.703 25.667L117.703 25.667Z"
            fill="#9E616A"
          />
          <path
            d="M87.437 49.9231C87.437 49.9231 90.62 45.7175 102.17 45.8327L108.726 43.4507L110.225 29.7163L115.469 30.9649L114.72 47.9456L103.482 54.4383L88.749 57.4349L87.437 49.9231Z"
            fill="#1B4A27"
          />
          <path
            d="M69.689 153.462L72.7148 154.751L79.1283 143.694L74.6626 141.791L69.689 153.462Z"
            fill="#9E616A"
          />
          <path
            d="M94.6926 156.325L97.9814 156.325L99.5461 143.639L94.6919 143.639L94.6926 156.325Z"
            fill="#9E616A"
          />
          <path
            d="M94.5768 159.756L104.691 159.756V159.628C104.691 158.584 104.276 157.583 103.538 156.844C102.799 156.106 101.798 155.691 100.754 155.691H100.754L98.9064 154.29L95.4595 155.692L94.5767 155.692L94.5768 159.756Z"
            fill="#2F2E41"
          />
          <path
            d="M67.8491 155.658L77.1534 159.623L77.2036 159.506C77.6128 158.545 77.6238 157.462 77.2341 156.493C76.8444 155.524 76.086 154.75 75.1255 154.341L75.1253 154.341L73.9753 152.327L70.2548 152.265L69.4426 151.919L67.8491 155.658Z"
            fill="#2F2E41"
          />
          <path
            d="M97.9187 81.2446L102.917 111.681L101.557 116.217L103.342 119.429L100.721 147.64L101.785 149.982L98.8116 151.816H92.7619L92.4409 126.276L90.9465 122.527V120.46L87.9044 104.887L87.3443 122.701L86.4594 126.456L87.2027 129.13L86.2315 131.004L77.9081 148.876H71.1245L71.6492 144.834C70.4338 144.219 73.4739 142.421 73.7661 141.09L72.7672 138.842L73.7661 134.597L74.6819 126.302L73.7161 108.792L74.1706 85.2133L82.1061 79.145L97.9187 81.2446Z"
            fill="#2F2E41"
          />
          <path
            d="M85.746 41.1617C89.5321 41.1617 92.6014 38.0924 92.6014 34.3063C92.6014 30.5202 89.5321 27.4509 85.746 27.4509C81.9599 27.4509 78.8906 30.5202 78.8906 34.3063C78.8906 38.0924 81.9599 41.1617 85.746 41.1617Z"
            fill="#9E616A"
          />
          <path
            d="M73.8343 93.8698C74.0265 93.5254 74.1407 93.143 74.1691 92.7497C74.1975 92.3563 74.1393 91.9615 73.9986 91.5931C73.8579 91.2246 73.6381 90.8915 73.3547 90.6172C73.0713 90.3429 72.7313 90.1341 72.3585 90.0054L72.3422 80.4187L68.188 83.1683L68.8403 91.8505C68.6656 92.4911 68.7321 93.1735 69.027 93.7684C69.3219 94.3634 69.8248 94.8294 70.4404 95.0783C71.056 95.3273 71.7415 95.3417 72.367 95.119C72.9925 94.8962 73.5146 94.4518 73.8343 93.8698Z"
            fill="#9E616A"
          />
          <path
            d="M73.45 51.4516L71.5941 49.6627L70.2308 50.6467C70.2308 50.6467 68.8894 52.2564 67.8163 57.0853C66.7432 61.9143 67.8163 88.4734 67.8163 88.4734L73.7183 87.6686L72.9135 73.7183L75.328 61.9143L73.45 51.4516Z"
            fill="#1B4A27"
          />
          <path
            d="M70.71 50.0991L76.9627 47.1123L81.2079 43.1748L88.9491 43.1168L91.4463 46.3631L98.0388 48.6026L96.94 71.3348L97.6892 73.0828C97.6892 73.0828 97.1673 73.5543 96.9288 73.818C96.6903 74.0817 97.2583 76.2893 97.2583 76.2893L97.5785 78.6911L96.6903 79.3257L97.845 80.6897L98.1317 82.8397C98.1317 82.8397 77.9758 77.8299 74.1673 86.1291C73.9661 86.5675 74.4616 82.2822 74.4616 82.2822L74.9571 79.145L74.4655 76.3292L73.7164 75.58L74.9728 74.3236L74.1673 68.3618L70.71 50.0991Z"
            fill="#1B4A27"
          />
          <path
            d="M135.975 153.327H132.264L130.499 139.015H135.975V153.327Z"
            fill="#AF6544"
          />
          <path
            d="M137.933 159.756H135.272L134.798 157.244L133.581 159.756H126.524C126.185 159.756 125.856 159.648 125.583 159.448C125.311 159.247 125.11 158.965 125.009 158.642C124.908 158.319 124.913 157.972 125.024 157.652C125.134 157.333 125.344 157.057 125.622 156.864L131.258 152.972V150.432L137.186 150.786L137.933 159.756Z"
            fill="#2F2E41"
          />
          <path
            d="M172.061 145.965L168.87 147.858L160.05 136.449L164.761 133.655L172.061 145.965Z"
            fill="#AF6544"
          />
          <path
            d="M177.025 150.496L174.736 151.853L173.046 149.935L173.281 152.716L167.211 156.316C166.92 156.489 166.581 156.564 166.245 156.53C165.908 156.497 165.591 156.357 165.34 156.13C165.088 155.904 164.916 155.603 164.848 155.272C164.779 154.94 164.819 154.596 164.96 154.288L167.822 148.066L166.527 145.881L171.806 143.162L177.025 150.496Z"
            fill="#2F2E41"
          />
          <path
            d="M134.319 61.8174L132.516 73.3123L149.645 73.5376L147.391 62.4936L134.319 61.8174Z"
            fill="#AF6544"
          />
          <path
            d="M132.45 73.167L127.557 85.0327L127.201 116.764L131.376 147.062H135.996L137.889 117.258L142.546 96.8658L147.138 118.194L161.417 139.071H168.433L155.534 116.066L154.041 91.0056C154.041 91.0056 154.971 80.3745 150.209 74.1012L149.05 70.7523L132.45 73.167Z"
            fill="#2F2E41"
          />
          <path
            d="M156.415 81.8302C155.938 81.7002 155.495 81.4672 155.118 81.1477C154.741 80.8281 154.438 80.4298 154.232 79.9806C154.025 79.5315 153.92 79.0426 153.922 78.5482C153.925 78.0539 154.036 77.5662 154.248 77.1195L146.961 53.3612L152.321 52.2987L158.986 75.5868C159.713 75.9922 160.266 76.6502 160.54 77.436C160.814 78.2219 160.79 79.081 160.473 79.8505C160.157 80.6201 159.568 81.2467 158.82 81.6116C158.072 81.9764 157.216 82.0542 156.415 81.8302V81.8302Z"
            fill="#AF6544"
          />
          <path
            d="M147.77 70.1633L134.963 64.1919C134.625 64.1847 134.299 64.0671 134.035 63.8571C133.771 63.647 133.583 63.3562 133.5 63.029C132.867 60.5227 131.474 57.7368 132.213 51.2313C132.358 49.9711 132.755 48.7529 133.38 47.6488C134.005 46.5447 134.845 45.5771 135.85 44.8033C136.856 44.0296 138.006 43.4653 139.233 43.1439C140.461 42.8225 141.74 42.7506 142.996 42.9323C144.32 43.1219 145.59 43.5894 146.721 44.3042C147.852 45.0189 148.819 45.9648 149.559 47.0796C150.299 48.1944 150.794 49.4531 151.014 50.7731C151.233 52.093 151.17 53.4443 150.831 54.7385C149.344 60.383 149.504 66.4189 149.334 68.7388C149.309 69.0742 149.175 69.3922 148.952 69.6442C148.729 69.8961 148.43 70.0683 148.1 70.1343C148.002 70.1537 147.902 70.1636 147.802 70.1637C147.791 70.1637 147.781 70.1635 147.77 70.1633Z"
            fill="#6D3E99"
          />
          <path
            d="M120.195 30.2616C120.124 30.4205 120.041 30.5737 119.945 30.7195L126.069 45.0785L129.833 45.5403L129.879 51.312L123.136 51.1421C122.591 51.1284 122.066 50.9384 121.638 50.6005C121.211 50.2626 120.904 49.7952 120.765 49.2684L116.226 32.0986C115.583 31.9068 115.014 31.5238 114.594 31.0005C114.174 30.4773 113.923 29.8385 113.875 29.1693C113.827 28.5 113.983 27.832 114.324 27.254C114.665 26.676 115.174 26.2155 115.783 25.9337C116.392 25.6519 117.072 25.5622 117.733 25.6764C118.394 25.7907 119.005 26.1035 119.484 26.5734C119.963 27.0432 120.288 27.6477 120.415 28.3066C120.542 28.9654 120.465 29.6473 120.195 30.2616V30.2616Z"
            fill="#AF6544"
          />
          <path
            d="M126.686 44.5667C126.838 44.4318 127.018 44.3312 127.213 44.272C127.408 44.2128 127.613 44.1963 127.815 44.2236L134.859 45.1774C135.901 45.1638 136.905 45.5638 137.652 46.2896C138.399 47.0154 138.828 48.0077 138.844 49.0491C138.86 50.0904 138.463 51.0958 137.739 51.8446C137.015 52.5935 136.024 53.0248 134.983 53.0439L127.942 54.179C127.741 54.2114 127.535 54.2001 127.339 54.1458C127.143 54.0916 126.96 53.9957 126.805 53.8647C126.649 53.7338 126.523 53.5707 126.436 53.3868C126.349 53.2028 126.302 53.0022 126.3 52.7987L126.209 45.6454C126.206 45.4418 126.247 45.2401 126.33 45.0539C126.412 44.8678 126.534 44.7016 126.686 44.5667Z"
            fill="#6D3E99"
          />
          <path
            d="M146.635 59.3793C146.455 59.2846 146.297 59.1521 146.173 58.9909C146.048 58.8297 145.96 58.6436 145.915 58.4452L144.314 51.5185C143.934 50.5493 143.953 49.4686 144.367 48.5134C144.782 47.5582 145.559 46.8064 146.527 46.4228C147.495 46.0391 148.575 46.0551 149.532 46.467C150.488 46.8789 151.242 47.6533 151.629 48.6203L155.181 54.8047C155.283 54.9812 155.345 55.1775 155.363 55.3802C155.382 55.5829 155.357 55.7872 155.29 55.9793C155.222 56.1713 155.114 56.3466 154.973 56.4932C154.832 56.6397 154.66 56.7541 154.471 56.8285L147.812 59.4444C147.623 59.5189 147.42 59.5517 147.216 59.5405C147.013 59.5292 146.815 59.4743 146.635 59.3793Z"
            fill="#6D3E99"
          />
          <path
            d="M146.351 40.4895C149.861 38.5708 151.15 34.1701 149.232 30.6602C147.313 27.1504 142.912 25.8604 139.403 27.7791C135.893 29.6978 134.603 34.0985 136.521 37.6084C138.44 41.1183 142.841 42.4082 146.351 40.4895Z"
            fill="#AF6544"
          />
          <path
            d="M137.894 31.2227C136.34 31.214 134.786 31.2052 133.232 31.1965C133.122 30.1374 133.593 29.0837 134.28 28.2701C134.967 27.4563 135.856 26.8436 136.734 26.2411C137.625 25.6294 138.549 25.0039 139.608 24.7901C140.497 24.6109 141.415 24.7352 142.312 24.86C143.502 25.0255 144.692 25.191 145.882 25.3564C147.188 25.538 148.555 25.7435 149.616 26.5268C151.05 27.5862 150.684 30.0315 152.06 31.1652C156.218 34.5894 154.534 37.5798 154.612 40.7009C149.588 41.2279 144.564 41.7549 139.539 42.282C138.994 42.3759 138.435 42.3596 137.897 42.2342C137.369 42.0729 136.885 41.6361 136.846 41.0858C136.803 40.471 137.29 39.9679 137.686 39.4958C138.631 38.3252 139.198 36.8954 139.313 35.3956C139.427 33.8958 139.083 32.3965 138.327 31.0963L137.894 31.2227Z"
            fill="#2F2E41"
          />
          <path
            d="M83.9924 40.8259C83.9631 40.7615 83.9339 40.6968 83.9048 40.6318C83.916 40.6322 83.9271 40.6338 83.9383 40.6341L83.9924 40.8259Z"
            fill="#2F2E41"
          />
          <path
            d="M78.7002 27.7627C79.0257 27.4556 79.4488 27.2727 79.8955 27.2459C80.3388 27.2602 80.7883 27.6606 80.6965 28.0945C81.5107 26.6528 82.8068 25.544 84.3571 24.9626C85.9074 24.3813 87.613 24.3646 89.1744 24.9155C90.2762 25.3042 91.3566 26.0819 91.6097 27.2225C91.6344 27.5219 91.7237 27.8125 91.8715 28.0741C91.9996 28.2139 92.1658 28.3133 92.3496 28.3598C92.5334 28.4064 92.7269 28.3982 92.9061 28.3362L92.917 28.333C92.9783 28.3121 93.0446 28.31 93.1071 28.3271C93.1696 28.3442 93.2256 28.3797 93.2677 28.4289C93.3099 28.4781 93.3363 28.5389 93.3435 28.6033C93.3508 28.6677 93.3385 28.7328 93.3084 28.7902L92.9966 29.3716C93.3896 29.4592 93.7979 29.4505 94.1868 29.3463C94.2514 29.3294 94.3197 29.3328 94.3823 29.3562C94.4448 29.3796 94.4986 29.4218 94.5362 29.477C94.5739 29.5321 94.5935 29.5976 94.5925 29.6644C94.5915 29.7311 94.5698 29.796 94.5305 29.8499C94.0124 30.5588 93.336 31.1368 92.5552 31.5382C91.7743 31.9396 90.9106 32.1531 90.0327 32.1619C88.7871 32.1543 87.5287 31.725 86.3164 32.0115C85.8475 32.1223 85.4093 32.3367 85.0339 32.6389C84.6586 32.9412 84.3558 33.3236 84.1476 33.7582C83.9393 34.1928 83.8311 34.6685 83.8307 35.1503C83.8303 35.6322 83.9378 36.1081 84.1453 36.543C83.7728 36.1357 83.053 36.2321 82.672 36.6315C82.4966 36.8453 82.3669 37.0928 82.291 37.3587C82.2152 37.6247 82.1947 37.9034 82.2309 38.1775C82.3325 39.0173 82.5818 39.8325 82.9673 40.5854C81.4944 40.5401 80.0708 40.0438 78.8889 39.1636C77.707 38.2833 76.8238 37.0616 76.3585 35.6633C75.8932 34.2651 75.8682 32.7577 76.287 31.3448C76.7058 29.9319 77.5481 28.6816 78.7002 27.7627V27.7627Z"
            fill="#2F2E41"
          />
          <path
            d="M214.906 160H0.325771C0.247062 160 0.171657 159.968 0.116082 159.913C0.0605071 159.857 0.0292969 159.781 0.0292969 159.703C0.0292969 159.624 0.0605071 159.548 0.116082 159.493C0.171657 159.437 0.247062 159.406 0.325771 159.405H214.906C214.945 159.405 214.984 159.413 215.02 159.428C215.056 159.443 215.089 159.464 215.117 159.492C215.144 159.52 215.166 159.552 215.181 159.589C215.196 159.625 215.204 159.663 215.204 159.703C215.204 159.742 215.196 159.78 215.181 159.817C215.166 159.853 215.144 159.886 215.117 159.913C215.089 159.941 215.056 159.963 215.02 159.978C214.984 159.992 214.945 160 214.906 160Z"
            fill="#CCCCCC"
          />
          <path
            d="M51.55 2.96423C45.3778 2.12994 38.3312 5.46606 36.7315 11.4854C36.3283 10.4152 35.5954 9.50082 34.6385 8.87441C33.6816 8.248 32.5504 7.942 31.4083 8.00061C30.2661 8.05922 29.1721 8.47941 28.2844 9.20047C27.3967 9.92153 26.7612 10.9061 26.4697 12.012L27.1764 12.5181C40.4032 15.3784 54.0951 15.3165 67.2955 12.3367C63.0022 7.8246 57.7222 3.7985 51.55 2.96423Z"
            fill="#F0F0F0"
          />
          <path
            d="M25.0802 19.9449C18.9081 19.1107 11.8615 22.4468 10.2617 28.4662C9.85858 27.3959 9.12563 26.4815 8.16877 25.8551C7.21191 25.2287 6.08069 24.9227 4.93853 24.9813C3.79638 25.0399 2.70242 25.4601 1.8147 26.1812C0.926989 26.9022 0.291485 27.8868 0 28.9927L0.706626 29.4988C13.9335 32.3592 27.6254 32.2972 40.8258 29.3174C36.5325 24.8053 31.2525 20.7792 25.0802 19.9449Z"
            fill="#F0F0F0"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_7836_16204"
            x1="15.832"
            y1="12.7356"
            x2="41.3034"
            y2="12.7356"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFAD03" />
            <stop offset="0.65098" stopColor="#FE8005" />
            <stop offset="1" stopColor="#FD5308" />
          </linearGradient>
          <clipPath id="clip0_7836_16204">
            <rect width="223.813" height="160" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <div className="w-full flex flex-col items-center space-y-3">
        <NavLink
          to="/create-offers"
          className="py-[1.2rem] w-full flex justify-center items-center rounded-xl border-2 border-primary-50 text-base font-bold"
        >
          Send an offer
        </NavLink>
        <Button
          href="/"
          className="w-full h-12 text-white text-base text-center font-semibold rounded-xl bg-purple-50"
        >
          Done
        </Button>
      </div>
    </div>
  );
}
