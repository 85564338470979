import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Button from "../../components/forms/Button";
import { PAYMENT_REASONS } from "../../utils/Contexts/V2/PaymentContext2";
import AdCreationSuccessImage from "../../assets/images/ad-creation-success.png";
import DefaultSuccessImage from "../../assets/images/success-image.svg";

export default function PaymentSuccessPage() {
  const [paymentFor, setPaymentFor] = useState(null);

  const location = useLocation();

  const [bodyContent, setBodyContent] = useState(<p />);
  const [bodyImage, setBodyImage] = useState(DefaultSuccessImage);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const paymentReason = params.get("for");

    if (paymentFor) {
      setPaymentFor(paymentReason);
    }

    switch (paymentReason) {
      case PAYMENT_REASONS.ADS:
        setBodyContent(
          <p className="text-base font-normal text-[#1A1A1A] pt-3.5 w-72 text-center">
            Your ad has been submitted and is being reviewed.
          </p>
        );
        setBodyImage(AdCreationSuccessImage);
        break;

      default:
        setBodyContent(
          <p className="text-base font-normal text-[#1A1A1A] pt-3.5 w-72 text-center">
            Payment Succesful
          </p>
        );

        setBodyImage(DefaultSuccessImage);
    }
  }, [location]);

  return (
    <div className="flex flex-col justify-around items-center w-full px-4 h-screen">
      <div className="flex flex-col justify-start items-center">
        <svg width="120" height="120" viewBox="0 0 120 120">
          <g filter="url(#filter0_d_1456_6402)">
            <circle cx="60" cy="48" r="40" className="fill-primary-50" />
            <g clipPath="url(#clip0_1456_6402)">
              <path
                d="M56.6672 53.2866L71.9872 37.9649L74.3455 40.3216L56.6672 57.9999L46.0605 47.3933L48.4172 45.0366L56.6672 53.2866Z"
                className="fill-base"
              />
            </g>
          </g>
          <defs>
            <filter
              id="filter0_d_1456_6402"
              x="0"
              y="0"
              width="120"
              height="120"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="12" />
              <feGaussianBlur stdDeviation="10" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.294118 0 0 0 0 0.113725 0 0 0 0 0.560784 0 0 0 0.2 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1456_6402"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1456_6402"
                result="shape"
              />
            </filter>
            <clipPath id="clip0_1456_6402">
              <rect
                width="40"
                height="40"
                fill="white"
                transform="translate(40 28)"
              />
            </clipPath>
          </defs>
        </svg>
        <p className="text-xl font-semibold text-[#1A1A1A]">Success</p>
        {bodyContent}
      </div>

      <img src={bodyImage} alt="success" className="w-full h-auto md:w-7/12" />
      <Button
        href="/"
        className="w-full h-12 text-white text-base text-center font-semibold rounded-xl bg-purple-50"
      >
        Done
      </Button>
    </div>
  );
}
