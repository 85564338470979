/**
 * Obsfucates a string by replacing the first `lengthHidden` characters with asterisks.
 *
 * @param {object} options - An object containing the string and the length of characters to hide.
 * @param {string} options.string - The input string to obsfucate.
 * @param {number} options.lengthHidden - The number of characters to replace with asterisks.
 * @return {string} The obsfucated string.
 */
export default function obsfucateString({ string, lengthHidden }) {
  if (!string) {
    return null;
  }
  const parsedString = string.split("");
  for (let i = 0; i <= lengthHidden; i += 1) {
    if (i >= string.length) {
      break;
    }
    parsedString[i] = "*";
  }

  return parsedString.join("");
}
