import React from "react";
import Button from "../../components/forms/Button";
import SuccessImage from "../../assets/icons/password reset illustration.svg";

export default function ResetPasswordSuccessPage() {
  return (
    <div className="h-screen w-screen bg-[#32135F33] flex flex-col items-center justify-center">
      <div className="bg-white flex flex-col items-center gap-8 p-4 md:p-8 rounded-lg text-center max-w-[320]">
        <div>
          <img src={SuccessImage} alt="success" />
        </div>
        <h1>Congratulations!</h1>
        <p>
          You password has been reset successfully. You can now proceed to
          login.
        </p>
        <Button href="/join">Great</Button>
      </div>
    </div>
  );
}
