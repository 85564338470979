/**
 * Return the username of a social media account when given a url
 * @param {string} url - url, eg https://facebook.com/username
 * @param {string} separator - separator from where the username should begin
 * @returns {string} username of provided network
 */
export default function parseSocialMediaUsername(
  url = "",
  separator = ".com/"
) {
  if (!url) {
    return url;
  }
  if (url.includes("http")) {
    const str = url.split(separator);
    return str[1];
  }
  return url;
}
