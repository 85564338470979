import React from "react";
import Button from "../../components/forms/Button";
import TappiLogo from "../../assets/icons/TAPPI MAIN SVG.svg";
import SuccessImage from "../../assets/icons/registration-success-gear.svg";

export default function RegistrationSuccessfulPage() {
  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center bg-[#98A2B3] p-4 md:p-8">
      <div className="container mx-auto my-auto bg-white rounded-2xl flex flex-col gap-8 p-4 md:p-8">
        <div>
          <img src={TappiLogo} alt="logo" className="h-8 mx-auto" />
        </div>
        <div>
          <img src={SuccessImage} alt="success" className="mx-auto" />
        </div>
        <div className="text-content space-y-4 text-center">
          <h1>Welcome to Tappi! 😄</h1>
          <p>
            To ensure you have the best experience possible, we need to verify
            your account. We’ll send you an email as soon as this is done.
          </p>
          <Button type="button" href="/join">
            Got It
          </Button>
          <p className="text-muted text-xs">
            Thank you for your interest in Tappi!
          </p>
        </div>
      </div>
    </div>
  );
}
