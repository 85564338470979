import React from "react";
import PropTypes from "prop-types";
import { useStateMachine } from "little-state-machine";

export default function FacebookAdsAudienceDetails({ openModal }) {
  const { state } = useStateMachine();

  return (
    <button
      type="button"
      className="border rounded-lg px-4 py-2 flex justify-between items-start w-full"
      onClick={() => openModal("audience-modal")}
    >
      <div className="text-start">
        <h4 className="font-semibold">Audience Details</h4>
        <div className="text-sm">
          <p>
            <span className="text-gray-60">Location: </span>
            <span className="font-semibold">
              {state?.adJourney?.audienceData?.location?.name}
            </span>
          </p>
          <p>
            <span className="text-gray-60">Age: </span>
            <span className="font-semibold">
              {state?.adJourney?.audienceData?.startAge} -{" "}
              {state?.adJourney?.audienceData?.endAge}
            </span>
          </p>
          <p>
            <span className="text-gray-60">Gender: </span>
            <span className="font-semibold">
              {state?.adJourney?.audienceData?.gender}
            </span>
          </p>
          <p>
            <span className="text-gray-60">Goals: </span>
            <span className="font-semibold">
              {state?.adJourney?.audienceData?.goal || "Not set yet"}
            </span>
          </p>
          <p>
            <span className="text-gray-60">Interests: </span>
            <span className="font-semibold">
              {state?.adJourney?.audienceData?.interests || "Not set yet"}
            </span>
          </p>
        </div>
      </div>
      <div className="bg-grey-20 px-2 py-2 rounded-full">
        <svg viewBox="0 0 20 20" fill="currentColor" height="1em" width="1em">
          <path d="M12.3 3.7l4 4L4 20H0v-4L12.3 3.7zm1.4-1.4L16 0l4 4-2.3 2.3-4-4z" />
        </svg>
      </div>
    </button>
  );
}

FacebookAdsAudienceDetails.propTypes = {
  openModal: PropTypes.func.isRequired,
};
