import React, { useCallback, useState } from "react";
import QRCode from "../../components/QRCode";
import Layout from "../../components/layout/V2/Layout";
import Button from "../../components/forms/Button";
import { useAppContext } from "../../utils/Contexts/AppContext";
import { useAuth } from "../../utils/Contexts/V2/AuthenticationContext";
import resolveSubdomain from "../../utils/resolveSubdomain";

export default function QRCodePage() {
  const { appVendor } = useAppContext();
  const { user } = useAuth();

  const qrLink = useCallback(
    () => resolveSubdomain(appVendor, user),
    [appVendor, user]
  );

  const [showDialogue, setShowDialogue] = useState(false);
  const [operation, setOperation] = useState(null);

  const showDialogueHandler = (bool, type) => {
    setShowDialogue(bool);
    setOperation(type);
  };

  return (
    <Layout
      header
      headerTitle="Your QR Code"
      showDialogueHandler={showDialogueHandler}
      show={showDialogue}
      operation={operation}
    >
      <div className="py-20 px-4 h-screen">
        <div className="flex flex-col items-center justify-center h-full">
          <div className="flex-grow flex flex-col items-center justify-center space-y-4">
            <div className="border rounded-xl border-[#D9D9D9] p-2 w-max">
              <QRCode link={qrLink()} />
            </div>
            <p className="text-center">
              Share your QR code with your customers to get reviews on your
              business page
            </p>
          </div>
          <div className="space-y-2">
            <Button onClick={() => showDialogueHandler(true, "Share Webpage")}>
              Share Website
            </Button>
            <Button
              variant="outline"
              onClick={() => showDialogueHandler(true, "Request Review")}
            >
              Generate Review Link
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
}
