import React from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/layout/V2/Layout";
import Button from "../../../components/forms/Button";
import LabelledInput from "../../../components/forms/LabelledInput";
import LabelledTextarea from "../../../components/forms/LabelledTextarea";
import { useOffer } from "../../../utils/Contexts/V2/OfferContext";

export default function OfferDetails() {
  const { createNewOfferHandler, newOfferHandler, isCreatingOffer } =
    useOffer();
  const navigate = useNavigate();

  return (
    <Layout headerTitle="Create Offers" header>
      <div className="relative w-full h-screen scroll-smooth pt-20 flex flex-col items-center justify-between">
        <div className="px-4 flex flex-col justify-center items-center space-y-4 w-full pb-8">
          <div className="relative w-[15.56rem] h-1 rounded-lg bg-primary-20">
            <div className="w-[25%] bg-primary-50 h-1 rounded-lg" />
          </div>
          <p className="px-7 font-normal text-base font-sm text-center">
            You can give details of your flash sales, special offers & holiday
            deals here.
          </p>
          <div className="flex flex-col justify-start items-center space-y-6 w-full">
            <LabelledInput
              id="title"
              placeholder="Offer name"
              label="Offer name"
              name="title"
              onChange={newOfferHandler}
            />
            <LabelledTextarea
              label="Description"
              placeholder="Description"
              name="description"
              rules={{ required: true }}
              id="description"
              onChange={newOfferHandler}
            />
          </div>
        </div>

        <div className="relative p-4 w-full shadow-[0_-2px_8px_rgba(0, 0, 0, 0.3)] border border-t-solid border-t-[#D1D9DD]">
          <Button
            className="font-semibold flex justify-center items-center"
            onClick={() => createNewOfferHandler(navigate)}
          >
            {isCreatingOffer ? `Creating Offer..` : `Continue`}
          </Button>
        </div>
      </div>
    </Layout>
  );
}
