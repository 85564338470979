/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";

import { useBusinessContext } from "../../utils/Contexts/V2/BusinessContext";
import FacebookAdDetails, { FacebookAdStatistics } from "./FacebookAdDetails";
import NoAdsImage from "../../assets/images/create-ads.png";
import Button from "../forms/Button";

const modalStyles = {
  content: {
    inset: "auto 0px 0px 0px",
    borderRadius: "16px 16px 0 0",
  },
  overlay: {
    backgroundColor: "#00000033",
  },
};

export default function AdCampaignsTab() {
  const { business } = useBusinessContext();

  const [clickThroughRate, setClickThroughRate] = useState(0);

  useEffect(() => {
    if (business?.facebookAdsInsights) {
      setClickThroughRate(
        (business.facebookAdsInsights.total_clicks /
          business.facebookAdsInsights.total_impressions) *
          100 || 0
      );
    }
  }, [business?.facebookAdsInsights]);

  const [adsFilter, setAdsFilter] = useState("ALL");

  const [filteredAds, setFilteredAds] = useState(business?.facebookAdsInsights);

  //   const [isDeletingAd, setIsDeletingAd] = useState(false);

  //   const queryClient = useQueryClient();

  //   const handleDeleteAd = useCallback((id) => {
  //     setIsDeletingAd(true);
  //     deleteFacebookAd({ id })
  //       .then(async () => {
  //         await queryClient.refetchQueries({
  //           queryKey: ["/insights/ads"],
  //           type: "all",
  //         });
  //         toast.success("Ad was succesfully deleted");
  //       })
  //       .catch((error) => {
  //         console.error("Could not delete ad : ", error);
  //         toast.error("Could not delete ad. Please try again.");
  //       })
  //       .finally(() => setIsDeletingAd(false));
  //   }, []);

  function filterAds(filter) {
    if (filter === "ALL") {
      setFilteredAds(business.facebookAdsInsights);
    } else {
      setFilteredAds(
        business.facebookAdsInsights.filter((ad) => ad.status === filter)
      );
    }
  }

  useEffect(() => {
    if (adsFilter && business?.facebookAdsInsights) {
      filterAds(adsFilter);
    }
  }, [adsFilter, business?.facebookAdsInsights]);

  const [areStatsVisible, setAreStatsVisible] = useState(false);
  const [selectedAd, setSelectedAd] = useState(null);

  const handleShowAdStats = (id) => {
    setAreStatsVisible(true);
    const targetAd = business.facebookAdsInsights.find((ad) => ad.id === id);
    setSelectedAd(targetAd);
  };

  const handleHideAdStats = () => {
    setAreStatsVisible(false);
  };

  return (
    <div className="w-full h-full px-4">
      <ReactModal
        isOpen={areStatsVisible}
        onRequestClose={handleHideAdStats}
        style={modalStyles}
      >
        <div className="flex space-between items-center pb-2 w-full">
          <h1 className="flex-grow p-0">Ad Performance</h1>
          <button type="button" onClick={handleHideAdStats}>
            <p className="text-xl">X</p>
          </button>
        </div>
        <hr className="block mb-4" />
        <FacebookAdStatistics
          adDetails={selectedAd}
          onShowAdStats={handleShowAdStats}
          onHideAdStats={handleHideAdStats}
        />
      </ReactModal>
      <div className="h-full">
        <div>
          <div className="flex justify-between">
            <div>
              <h5 className="font-semibold text-lg">
                {business?.facebookAdsInsights?.total_reach || 0}
              </h5>
              <p className="text-xs font-normal">Reached Customers</p>
            </div>
            <div>
              <h5 className="font-semibold text-lg">
                {business?.facebookAdsInsights?.total_clicks || 0}
              </h5>
              <p className="text-xs font-normal">Actions</p>
            </div>
            <div>
              <h5 className="font-semibold text-lg">
                {clickThroughRate.toFixed(4)}%
              </h5>
              <p className="text-xs font-normal">Click-Through Rate</p>
            </div>
          </div>
          <hr className="my-2" />

          <div className="flex justify-between items-center my-4">
            <label htmlFor="adsFilterInput" className="w-7/12">
              <p>Your Ads</p>
            </label>
            <select
              className="form-select appearance-none
                    block
                    w-6/12 h-[48px]
                    px-3
                    py-1.5
                    text-sm
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding bg-no-repeat
                    border border-solid border-gray-300
                    rounded-lg
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-purple-40 focus:outline-none focus:shadow"
              aria-label="Default select example"
              required
              id="adsFilterInput"
              value={adsFilter}
              onChange={(e) => {
                setAdsFilter(e.target.value);
                // filterAds(e.target.value);
              }}
            >
              <option value="ALL">All Ads</option>
              <option value="IS_DRAFT">Draft Ads</option>
              <option value="IN_REVIEW">Ads In Review</option>
              <option value="ACTIVE">Active Ads</option>
              <option value="COMPLETED">Completed Ads</option>
              <option value="REJECTED">Rejected Ads</option>
            </select>
          </div>
        </div>
        {filteredAds?.length ? (
          <div>
            <div className="space-y-2 sm:space-y-4">
              {filteredAds?.map((ad) => (
                <FacebookAdDetails
                  ad={ad}
                  key={ad.id}
                  handleDeleteAd={() => null}
                  handleShowAdStats={() => handleShowAdStats(ad.id)}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className="flex flex-col w-full items-center justify-center space-y-2 md:space-y-4 text-center">
            <img
              src={NoAdsImage}
              alt="Create An Ad"
              className="w-full md:max-w-[400px]"
            />
            <h2>Find More Customers</h2>
            <p>
              Reach more customers on Facebook and Instagram by creating an Ad
            </p>

            <Button
              href="/ads/create"
              className="w-auto mx-auto flex items-center"
            >
              <span>Create An Ad</span>
            </Button>
          </div>
        )}
      </div>
      {/* )} */}
    </div>
  );
}
