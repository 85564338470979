import React from "react";
import { useQRCode } from "next-qrcode";
import PropTypes from "prop-types";

function QRCode({ link }) {
  const { Canvas } = useQRCode();

  return (
    <Canvas
      text={link}
      options={{
        level: "M",
        width: 200,
        color: {
          dark: "#000",
          light: "#fff",
        },
      }}
    />
  );
}

QRCode.propTypes = {
  link: PropTypes.string,
};

QRCode.defaultProps = {
  link: "https://tappi.app",
};

export default QRCode;
