import React, { createContext, useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useStateMachine } from "little-state-machine";
import moment from "moment/moment";
import { toast } from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";
import { useAuth } from "./AuthenticationContext";
import { updatePaymentInfo } from "../../stateMachine";
import createEnum from "../../createEnum";
import { checkPaybillPaymentStatus, makeMpesaPayment } from "../../requests";
import {
  confirmPaymentViaAirtime,
  // debitWallet,
  payWithAirtime,
  updateFacebookAd,
  checkPromoCode,
  payWithFlutterwave,
  checkFlutterwavePaymentStatus,
} from "../../requests-v2";

const PaymentContext = createContext(null);

function usePaymentContext() {
  return useContext(PaymentContext);
}

const PAYMENT_REASONS = createEnum(["ADS", "SUBSCRIPTION", "CREDITS"]);

function PaymentProvider({ children }) {
  const { actions, state } = useStateMachine({
    updatePaymentInfo,
  });

  const { user, merchantId } = useAuth();
  const country = user?.country.name;
  const currency = user?.country.currency_code;
  const msisdn = user?.phone_number;
  const [activePaymentMethod, setActivePaymentMethod] = useState(
    state.paymentInfo.paymentMethod
  );

  const [paymentFor, setPaymentFor] = useState(state.paymentInfo.paymentFor);
  const [paymentAmount, setPaymentAmount] = useState(
    state.paymentInfo.paymentAmount
  );
  const [showAirtimeConfirmationPrompt, setShowAirtimeConfirmationPrompt] =
    useState(false);
  const [
    isConfirmingAirtimePaymentStatus,
    setIsConfirmingAirtimePaymentStatus,
  ] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [flutterwavePaymentDetails, setFlutterwavePaymentDetails] =
    useState(null);
  const [subscriptionPackageId, setSubscriptionPackageId] = useState(null);

  function setPaymentMethod(method) {
    setActivePaymentMethod(method);
    actions.updatePaymentInfo({
      paymentMethod: method,
    });
  }

  function setActivePaymentAmount(amount) {
    setPaymentAmount(amount);
    actions.updatePaymentInfo({
      paymentAmount: amount,
    });
  }

  function setActivePaymentFor(reason) {
    setPaymentFor(reason);
    actions.updatePaymentInfo({
      paymentFor: reason,
    });
  }

  const adsData = {
    header_text: state.adJourney.headline,
    body_text: state.adJourney.description,
    duration: state.adJourney.durationData.days.toString(),
    start_date:
      typeof state.adJourney.durationData.startDate === "string"
        ? moment(state.adJourney.durationData.startDate).format(
            "YYYY-MM-DD HH:mm:ss"
          )
        : state.adJourney.durationData.startDate.format("YYYY-MM-DD HH:mm:ss"),
    end_date:
      typeof state.adJourney.durationData.endDate === "string"
        ? moment(state.adJourney.durationData.endDate).format(
            "YYYY-MM-DD HH:mm:ss"
          )
        : state.adJourney.durationData.endDate.format("YYYY-MM-DD HH:mm:ss"),
    is_draft: false,
    age_max: state.adJourney.audienceData.endAge.toString(),
    age_min: state.adJourney.audienceData.startAge.toString(),
    gender: state.adJourney.audienceData.gender,
    whatsapp_number: user?.whatsapp_number,
    location: state.adJourney.audienceData.location.key || null,
    ad_type: 1,
    // url: "https://www.tappi.app",
    ...(state.adJourney.instagramUsername && {
      instagram_url: `https://instagram.com/${state.adJourney.instagramUsername}`,
    }),
    ai_goal: state.adJourney.audienceData.goal,
    ai_target_audience: state.adJourney.audienceData.interests,
    facebook_page_id: state.adJourney.facebookPageId || null,
  };

  const queryClient = useQueryClient();

  async function handleMpesaAdsPayment({ phoneNumber }) {
    // Extra check to ensure all steps have been completed before creating an ad
    if (state.adJourney.stepsDone !== 3) {
      return Promise.reject(
        new Error("Ad not created. Please complete all steps")
      );
    }
    return makeMpesaPayment({
      amount: paymentAmount.toString(),
      phone_number: phoneNumber,
    })
      .then(async (response) => {
        if (response.data.status === 200) {
          return updateFacebookAd({ id: state.adJourney.id, data: adsData })
            .then(async (res) => {
              await queryClient.refetchQueries({
                queryKey: ["/facebook-ads"],
                type: "all",
              });
              return res.data;
            })
            .catch((error) => Promise.reject(error));
        }
        return Promise.reject(response.data);
      })
      .catch((error) => Promise.reject(error));
  }

  async function handleMpesAdsPaybillPayment({ ref }) {
    // Extra check to ensure all steps have been completed before creating an ad
    if (state.adJourney.stepsDone !== 3) {
      return Promise.reject(
        new Error("Ad not created. Please complete all steps")
      );
    }

    return checkPaybillPaymentStatus({
      amount: paymentAmount.toString(),
      reference_number: ref,
    })
      .then(async (response) => {
        if (response.data.status === 200) {
          return updateFacebookAd({ id: state.adJourney.id, data: adsData })
            .then(async (res) => {
              await queryClient.refetchQueries({
                queryKey: ["/facebook-ads"],
                type: "all",
              });
              return res.data;
            })
            .catch((error) => Promise.reject(error));
        }
        return Promise.reject(response.data);
      })
      .catch((error) => Promise.reject(error));
  }

  async function handleCreditsAdsPayment(navigate) {
    setIsProcessingPayment(true);
    // Extra check to ensure all steps have been completed before creating an ad
    if (state.adJourney.stepsDone !== 3) {
      setIsProcessingPayment(false);
      toast.error("Ad not created. Please complete all steps");
      return Promise.reject(
        new Error("Ad not created. Please complete all steps")
      );
    }
    return updateFacebookAd({ id: state.adJourney.id, data: adsData })
      .then(async () =>
        queryClient.refetchQueries({
          queryKey: ["/facebook-ads"],
          type: "all",
        })
      )
      .then((res) => {
        toast.success("Ad successfully created");
        setIsProcessingPayment(false);
        navigate("/payments/success");
        return Promise.resolve(res);
      })
      .catch((error) => {
        toast.error(
          error?.data?.detail[0]?.msg ||
            "Ad not created. Please complete all steps"
        );
        return Promise.reject(error);
      })
      .finally(() => setIsProcessingPayment(false));
  }

  async function handlePromoCodeAdsPayment({ promoCode }) {
    // Extra check to ensure all steps have been completed before creating an ad
    if (state.adJourney.stepsDone !== 3) {
      return Promise.reject(
        new Error("Ad not created. Please complete all steps")
      );
    }

    return checkPromoCode({ code: promoCode })
      .then(async () =>
        updateFacebookAd({ id: state.adJourney.id, data: adsData })
          .then(async (res) => {
            await queryClient.refetchQueries({
              queryKey: ["/facebook-ads"],
              type: "all",
            });
            return res.data;
          })
          .catch((error) => Promise.reject(error))
      )
      .catch((error) => Promise.reject(error));
  }

  async function handleAirtimeAdsPayment() {
    setIsProcessingPayment(true);
    // Extra check to ensure all steps have been completed before creating an ad
    if (state.adJourney.stepsDone !== 3) {
      setIsProcessingPayment(false);
      return Promise.reject(
        new Error("Ad not created. Please complete all steps")
      );
    }
    return payWithAirtime({
      amount: paymentAmount.toString(),
    })
      .then((res) => {
        setIsProcessingPayment(false);
        setTransactionId(res.data.data.transactionId);
        setShowAirtimeConfirmationPrompt(true);
      })
      .catch((res) => {
        setIsProcessingPayment(false);
        setShowAirtimeConfirmationPrompt(false);
        setErrorMessage(res.response.data.detail[0].msg);
        setTimeout(() => setErrorMessage(null), 5000);
        return Promise.reject(res);
      });
  }

  async function handleAirtimeAdsPaymentConfirmation(navigate) {
    setIsConfirmingAirtimePaymentStatus(true);
    confirmPaymentViaAirtime({ transaction_id: transactionId })
      .then((res) => {
        if (res.data.status === "success") {
          return Promise.resolve(true);
        }
        setErrorMessage("Payment is yet to be confirmed.");
        setIsConfirmingAirtimePaymentStatus(false);
        setTimeout(() => {
          setErrorMessage(null);
        }, 3000);
        throw Error("Payment is pending");
      })
      .then(() =>
        updateFacebookAd({
          id: state.adJourney.id,
          data: adsData,
        })
      )
      .then(async (response) => {
        setErrorMessage(null);
        return response;
      })
      .then(async (response) => {
        setIsConfirmingAirtimePaymentStatus(false);
        setShowAirtimeConfirmationPrompt(false);
        await queryClient.refetchQueries({
          queryKey: ["/facebook-ads"],
          type: "all",
        });
        navigate("/payments/success");
        return response.data;
      })
      .catch((error) => {
        setIsConfirmingAirtimePaymentStatus(false);
        return Promise.reject(error);
      });
  }

  // FlutterwavePayment Payment
  function flutterwavePaymentHandler() {
    setIsProcessingPayment(true);
    payWithFlutterwave({ amount: paymentAmount })
      .then((res) => {
        if (res.data.status === "failed") {
          setIsProcessingPayment(false);
          toast.error("Service currently unavailable");
          throw new Error();
        }
        setIsProcessingPayment(false);
        setFlutterwavePaymentDetails(res.data);
        return Promise.resolve(res);
      })
      .catch((error) => {
        setIsProcessingPayment(false);
        toast.error("Something went wrong");
        return Promise.reject(error);
      });
  }

  function confirmFlutterwavePaymentStatusHandler(navigate) {
    const payRef = {
      transaction_id: flutterwavePaymentDetails.transaction_id,
    };
    setIsProcessingPayment(true);
    checkFlutterwavePaymentStatus(payRef)
      .then((res) => {
        if (res.data.status !== "successful") {
          setIsProcessingPayment(false);
          // eslint-disable-next-line no-throw-literal
          throw "pending";
        }
        return updateFacebookAd({ id: state.adJourney.id, data: adsData }).then(
          async () => {
            await queryClient.refetchQueries({
              queryKey: ["/facebook-ads"],
              type: "all",
            });
            return res;
          }
        );
      })
      // .then(() =>
      //   debitWallet(`EN-${msisdn}`, {
      //     amount: paymentAmount,
      //   })
      // )
      .then((res) => {
        if (res.status === 200) {
          setIsProcessingPayment(false);
          toast.success("Ad successfully created");
          setisLoading(false);
          navigate("/payments/success");
          return Promise.resolve(res);
        }
        return Promise.reject(new Error("Something went wrong"));
      })
      .catch((error) => {
        setIsProcessingPayment(false);
        if (error === "pending") {
          toast.error("Payment not yet received.");
          return Promise.reject(error);
        }
        toast.error(
          error.response?.data?.detail[0]?.msg || "Something went wrong"
        );
        return Promise.reject(error);
      });
  }

  const handleSubscriptionPromoCodePayment = ({ promoCode }) =>
    checkPromoCode({ code: promoCode })
      .then(async (res) => res.data)
      .catch((error) => Promise.reject(error));

  const handleSubscriptionMpesaPayment = ({ phoneNumber }) =>
    makeMpesaPayment({
      amount: paymentAmount.toString(),
      phone_number: phoneNumber,
    })
      .then(async (response) => {
        if (response.data.status === 200) {
          return response;
        }
        return Promise.reject(response.data);
      })
      .catch((error) => Promise.reject(error));

  const handleMpesaSubscriptionPaybillPayment = ({ ref }) =>
    checkPaybillPaymentStatus({
      amount: paymentAmount.toString(),
      reference_number: ref,
    })
      .then(async (response) => {
        if (response.data.status === 200) {
          return response;
        }
        return Promise.reject(response.data);
      })
      .catch((error) => Promise.reject(error));

  const handleTopUpWalletWithMpesa = async ({ amount, phoneNumber }) =>
    makeMpesaPayment({
      amount: amount.toString(),
      phone_number: phoneNumber,
    });

  // TODO: Pass dependency array
  const value = useMemo(() => ({
    country,
    currency,
    merchantId,
    msisdn,
    activePaymentMethod,
    paymentFor,
    paymentAmount,
    showAirtimeConfirmationPrompt,
    setShowAirtimeConfirmationPrompt,
    isConfirmingAirtimePaymentStatus,
    errorMessage,
    isLoading,
    isProcessingPayment,
    flutterwavePaymentDetails,
    subscriptionPackageId,
    setSubscriptionPackageId,
    setActivePaymentAmount,
    setActivePaymentFor,
    setPaymentMethod,
    handleMpesaAdsPayment,
    handleMpesAdsPaybillPayment,
    handleCreditsAdsPayment,
    handlePromoCodeAdsPayment,
    handleAirtimeAdsPayment,
    handleAirtimeAdsPaymentConfirmation,
    flutterwavePaymentHandler,
    confirmFlutterwavePaymentStatusHandler,
    handleSubscriptionPromoCodePayment,
    handleSubscriptionMpesaPayment,
    handleMpesaSubscriptionPaybillPayment,
    handleTopUpWalletWithMpesa,
  }));

  return (
    <PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>
  );
}

PaymentProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { usePaymentContext, PaymentProvider, PAYMENT_REASONS };
