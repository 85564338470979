/* eslint-disable no-restricted-syntax */

/**
 * Return frozen object that can be treated as an enum
 * @param {Array} values - Array of values you want to create an enum from
 * @returns ReadOnly<Object>
 */
export default function createEnum(values) {
  const enumObject = {};
  for (const val of values) {
    enumObject[val] = val;
  }
  return Object.freeze(enumObject);
}
