import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Button from "../../../components/forms/Button";
import { useOffer } from "../../../utils/Contexts/V2/OfferContext";

export default function BulkOfferTopUp({ setShowPrompt }) {
  const navigate = useNavigate();
  const [bulkSMSBundle, setBulkSMSBundle] = useState(null);
  const { topUpBulkSMSHandler, isTopingUp } = useOffer();
  const radioCheckedHandler = (e) => {
    setBulkSMSBundle(e.target.id);
  };

  return (
    <div className="relative h-screen w-full flex flex-col items-center">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="absolute h-screen w-full bg-blur"
        onClick={() => setShowPrompt(false)}
      />
      <div className="absolute -bottom-2 w-full rounded-t-2xl bg-white pt-[1.62rem] pb-6">
        <div className="w-full flex flex-row justify-between items-center pb-[1.12rem] px-4 border-b-[0.069rem] border-[#E7E7E7]">
          <h1 className="text-lg text-[#1A1A1A] font-semibold">Get bulk sms</h1>
          <button type="button" onClick={() => setShowPrompt(false)}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.0079 18.5383L4.7651 6.29552C4.34681 5.87722 4.34681 5.18347 4.7651 4.76517C5.1834 4.34688 5.87715 4.34688 6.29545 4.76517L18.5382 17.0079C18.9565 17.4262 18.9565 18.12 18.5382 18.5383C18.1199 18.9566 17.4262 18.9566 17.0079 18.5383Z"
                fill="#1A1A1A"
              />
              <path
                d="M4.76551 18.5383C4.34721 18.12 4.34721 17.4262 4.76551 17.0079L17.0083 4.76517C17.4266 4.34688 18.1203 4.34688 18.5386 4.76517C18.9569 5.18347 18.9569 5.87722 18.5386 6.29552L6.29585 18.5383C5.87756 18.9566 5.1838 18.9566 4.76551 18.5383Z"
                fill="#1A1A1A"
              />
            </svg>
          </button>
        </div>
        <div className="py-5 px-4 flex flex-col justify-start items space-y-[1.88rem]">
          <div className="flex-col justify-start items space-y-5">
            <div className="flex flex-row justify-between items-center w-full border border-solid rounded-xl p-[1.13rem]">
              <div className="flex flex-row items-center space-x-5">
                <p className="font-bold text-sm text-[#1A1A1A]">100 Bulk SMS</p>
                <p className="font-bold text-xs text-[#6D7789]">100 credits</p>
              </div>
              <input
                type="radio"
                className="form-check-input appearance-none rounded-full h-[1.4rem] w-[1.4rem] border border-gray-300 bg-white checked:text-primary-50 checked:border-white checked:outline-white transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                id="100"
                checked={bulkSMSBundle === "100"}
                onChange={radioCheckedHandler}
              />
            </div>
            <div className="flex flex-row justify-between items-center w-full border border-solid rounded-xl p-[1.13rem]">
              <div className="flex flex-row items-center space-x-5">
                <p className="font-bold text-sm text-[#1A1A1A]">200 Bulk SMS</p>
                <p className="font-bold text-xs text-[#6D7789]">200 credits</p>
              </div>
              <input
                type="radio"
                className="form-check-input appearance-none rounded-full h-[1.4rem] w-[1.4rem] border border-gray-300 bg-white checked:text-primary-50 checked:border-white checked:outline-white transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                id="200"
                checked={bulkSMSBundle === "200"}
                onChange={radioCheckedHandler}
              />
            </div>
            <div className="flex flex-row justify-between items-center w-full border border-solid rounded-xl p-[1.13rem]">
              <div className="flex flex-row items-center space-x-5">
                <p className="font-bold text-sm text-[#1A1A1A]">300 Bulk SMS</p>
                <p className="font-bold text-xs text-[#6D7789]">300 credits</p>
              </div>
              <input
                type="radio"
                className="form-check-input appearance-none rounded-full h-[1.4rem] w-[1.4rem] border border-gray-300 bg-white checked:text-primary-50 checked:border-white checked:outline-white transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                id="300"
                checked={bulkSMSBundle === "300"}
                onChange={radioCheckedHandler}
              />
            </div>
          </div>
          <Button
            className="font-semibold flex justify-center items-center"
            onClick={() =>
              topUpBulkSMSHandler(bulkSMSBundle, setShowPrompt, navigate)
            }
          >
            {isTopingUp ? "Processing..." : "Continue"}
          </Button>
        </div>
      </div>
    </div>
  );
}

BulkOfferTopUp.propTypes = {
  setShowPrompt: PropTypes.func.isRequired,
};
