import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { toast } from "react-hot-toast";
import { useStateMachine } from "little-state-machine";

import Layout from "../../components/layout/V2/Layout";
import Button from "../../components/forms/Button";

import {
  PAYMENT_REASONS,
  usePaymentContext,
} from "../../utils/Contexts/V2/PaymentContext2";
import WalletIcon from "../../assets/icons/wallet-icon.svg";
import { resetStoreValue } from "../../utils/stateMachine";
import { useWalletContext } from "../../utils/Contexts/V2/WalletContext";
import AirtimePaymentPrompt from "../../components/V2/AirtimePaymentPrompt";

function AdPaymentSelectPage() {
  const navigate = useNavigate();

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("credits");

  const { state } = useStateMachine({ resetStoreValue });

  const {
    currency,
    country,
    paymentAmount,
    setPaymentMethod,
    handleCreditsAdsPayment,
    setShowAirtimeConfirmationPrompt,
    showAirtimeConfirmationPrompt,
    isConfirmingAirtimePaymentStatus,
    handleAirtimeAdsPaymentConfirmation,
    errorMessage,
    isLoading,
    isProcessingPayment,
  } = usePaymentContext();

  const { walletDetails } = useWalletContext();

  useEffect(() => {
    if (country === "Nigeria") {
      setSelectedPaymentMethod("credits");
    }
  }, []);

  const redirectTimeout = () =>
    setTimeout(
      () => navigate(`/payments/success?for=${PAYMENT_REASONS.ADS}`),
      2000
    );

  useEffect(() => () => clearTimeout(redirectTimeout), []);

  useEffect(() => {
    if (state.adJourney.stepsDone !== 3) {
      navigate("/ads/create");
    }
  }, []);

  return (
    <Layout header headerTitle="Select Payment Method">
      <div className="relative flex flex-col justify-between items-center h-screen max-w-screen-xl container mx-auto">
        <Transition
          className="fixed -bottom-12 left-0 z-20 w-full"
          show={showAirtimeConfirmationPrompt}
          enterFrom=" translate-y-0"
          enterTo=" -translate-y-12"
          leaveFrom=" -translate-y-12"
          leaveTo=" translate-y-0"
        >
          <AirtimePaymentPrompt
            close={setShowAirtimeConfirmationPrompt}
            confirmPaymentHandler={handleAirtimeAdsPaymentConfirmation}
            checkingStatus={isConfirmingAirtimePaymentStatus}
            errorMessage={errorMessage}
          />
        </Transition>
        <div className="flex flex-col items-center space-y-6 w-full px-4 pt-20 h-full">
          <div className="flex flex-col items-center justify-start space-y-2">
            <p className="text-sm font-medium">You are about to pay</p>
            <h1 className="text-xl font-semibold text-[#1A1A1A]">
              {currency} {paymentAmount}
            </h1>
          </div>
          <fieldset
            className="w-full h-full flex-grow flex flex-col"
            disabled={isLoading}
            aria-busy={isLoading}
          >
            <div className="flex flex-col justify-start items-center space-y-4 w-full flex-grow">
              <label
                className="flex flex-row justify-between items-center w-full border-[0.089rem] border-primary-50 py-3.5 px-4 rounded-[0.87rem]"
                htmlFor="credits"
                title="Pay with credits"
              >
                <div className="flex flex-row items-center space-x-3.5">
                  <img src={WalletIcon} alt="credits" />
                  <p className="text-[0.9rem] font-semibold">
                    Pay With Credits
                  </p>
                </div>
                <input
                  className="form-check-input appearance-none rounded-full h-[1.4rem] w-[1.4rem] border border-gray-300 bg-white checked:bg-primary-50 checked:border-primary-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 disabled:cursor-not-allowed"
                  type="radio"
                  name="credits"
                  value="credits"
                  id="credits"
                  onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                  checked
                />
              </label>
            </div>
            <div className="relative py-4 w-full">
              <Button
                className="w-full h-12 text-white text-base text-center font-semibold rounded-xl bg-purple-50 justify-center items-center"
                onClick={async () => {
                  setPaymentMethod(selectedPaymentMethod.toUpperCase());
                  if (selectedPaymentMethod.toUpperCase() === "MPESA") {
                    navigate("/payments/mpesa");
                  } else if (
                    selectedPaymentMethod.toUpperCase() === "CREDITS"
                  ) {
                    if (paymentAmount <= walletDetails?.credit_balance) {
                      handleCreditsAdsPayment(navigate);
                    } else {
                      toast.error("Insufficient credit balance.");
                    }
                  }
                }}
              >
                {!isProcessingPayment ? "Continue" : "Processing Payment..."}
              </Button>
            </div>
          </fieldset>
        </div>
      </div>
    </Layout>
  );
}

export default AdPaymentSelectPage;
