/**
 * Truncate a text by the word count
 * @param {string} text - The text to be truncated
 * @param {number} wordCount - The number of words to truncate the text by
 * @returns {string}
 */

export default function truncateText(text, wordCount = 5) {
  if (!text) {
    return "";
  }
  if (text.split(" ").length > wordCount) {
    const r = text.split(" ").slice(0, wordCount).join(" ");
    return `${r}...`;
  }

  return text;
}
