import React from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import promoteYourBusiness from "../assets/icons/promote_your_business.svg";
import walletTopUp from "../assets/icons/walletTopUp.svg";
import addProductPhoto from "../assets/icons/add_product_photo.svg";
import requestReview from "../assets/icons/request_review.svg";

function Menu({ close, showDialogueHandler }) {
  const requestReviewHandler = () => {
    showDialogueHandler(true, "Request Review");
    close(false);
  };

  return (
    <div className="fixed bottom-0 z-20 w-full h-screen  flex">
      <div className="w-full pt-7 px-4 pb-9 rounded-t-2xl bg-white self-end">
        <div className="w-full flex flex-row justify-between items-center pb-7 h-12">
          <h2>What would you like to do?</h2>
          <button type="button" onClick={() => close(false)}>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
        <div className="w-full flex flex-col items-start justify-center space-y-6">
          <Link
            to="/ads/create"
            className="flex flex-row justify-start items-start space-x-3"
          >
            <img src={promoteYourBusiness} alt="Promote your business" />
            <h2 className="font-sans font-semibold text-[0.93rem]">
              Promote your business
            </h2>
          </Link>
          <NavLink
            to="/add-photos"
            className="flex flex-row justify-start items-start space-x-3"
          >
            <img src={addProductPhoto} alt="Add Product" />
            <h2 className="font-sans font-semibold text-[0.93rem]">
              Add Product Photo
            </h2>
          </NavLink>
          <button
            type="button"
            onClick={requestReviewHandler}
            className="flex flex-row justify-start items-start space-x-3"
          >
            <img src={requestReview} alt="Request Review" />
            <h2 className="font-sans font-semibold text-[0.93rem]">
              Request Review
            </h2>
          </button>
          <NavLink
            to="/wallet"
            className="flex flex-row justify-start items-start space-x-3"
          >
            <img src={walletTopUp} alt="Top Up Wallet" />
            <h2 className="font-sans font-semibold text-[0.93rem]">
              Top Up Wallet
            </h2>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

Menu.defaultProps = {
  showDialogueHandler: null,
};

Menu.propTypes = {
  close: PropTypes.func.isRequired,
  showDialogueHandler: PropTypes.func,
};

export default Menu;
