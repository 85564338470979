/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { validatePhoneNumber } from "../../utils/validators";
import { useAppContext } from "../../utils/Contexts/AppContext";
import { getPasswordResetLink } from "../../utils/requests-v2";
import Button from "../forms/Button";
import LabelledInput from "../forms/LabelledInput";

export default function ForgotPasswordForm() {
  const [inputType, setInputType] = useState(null);
  const appContext = useAppContext();
  const navigate = useNavigate();

  const redirectTimeOut = () =>
    setTimeout(() => {
      navigate("/forgot-password/submit-otp");
    }, 1000);

  // Clear timeout if component is unmounted
  useEffect(() => () => clearTimeout(redirectTimeOut), []);

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setError,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      username: "",
    },
  });

  const [errors, setErrors] = useState({});

  const onSubmit = async (data) => {
    const username = getValues("username");
    if (!username) {
      setErrors({ username: "Invalid phone number or email address" });
      setError("username", {
        type: "manual",
        message: "Invalid phone number or email address",
      });
      setInputType(null);
      return;
    }

    // Validate that the input is either valid email address or phone number
    if (
      !username.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      ) &&
      !validatePhoneNumber(username, appContext.country)
    ) {
      setErrors({ username: "Invalid phone number or email address" });
      setInputType(null);
      return;
    }

    // Check whether user submitted email address or phone number
    let userInputType = inputType;
    if (
      username.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      )
    ) {
      userInputType = "email_address";
    }
    if (validatePhoneNumber(username, appContext.country)) {
      userInputType = "phone_number";
    }
    setInputType(userInputType);

    // Request for password to be reset on the API
    const payload = {
      ...(userInputType ? { [userInputType]: data.username } : {}),
    };

    try {
      await getPasswordResetLink(payload);
      // We will read this in the next page
      localStorage.setItem("FP", JSON.stringify(payload));
      toast.success(
        "Password reset instructions have been sent to your email address or phone number"
      );
      redirectTimeOut();
      return;
    } catch (error) {
      console.error("Could not get password reset link: ", error);
      const message =
        error.response?.data?.detail ||
        "Could not send password reset instructions. Please try again.";
      toast.error(message);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LabelledInput
        register={register}
        name="username"
        control={control}
        errorText={errors.username}
        id="userResetPasswordInput"
        label="Enter Email Address Or Phone Number"
        type="text"
        rules={{ required: true }}
      />
      <Button type="submit" isLoading={isSubmitting} disabled={isSubmitting}>
        Next
      </Button>
    </form>
  );
}
