import React from "react";
import PropTypes from "prop-types";

function SubscriptionPlanSwitch({ billingRateSwitch, billingRate }) {
  return (
    <div className="flex flex-row justify-between items-center rounded-[6.25rem] subscription-plans">
      <button
        id="monthly"
        type="button"
        style={{ height: "34px" }}
        className={`text-sm font-semibold px-6 ${
          billingRate === "Monthly"
            ? "active-subscription-plan bg-purple-50 text-white border-2 border-white"
            : ""
        }`}
        onClick={() => billingRateSwitch("Monthly")}
      >
        Monthly
      </button>
      <button
        id="quarterly"
        type="button"
        style={{ height: "34px" }}
        className={`text-sm font-semibold px-6 ${
          billingRate === "Quarterly"
            ? "active-subscription-plan bg-purple-50 text-white border-2 border-white"
            : ""
        }`}
        onClick={() => billingRateSwitch("Quarterly")}
      >
        Quarterly
      </button>
      <button
        id="annually"
        type="button"
        style={{ height: "34px" }}
        className={`text-sm font-semibold px-6 ${
          billingRate === "Annually"
            ? "active-subscription-plan  bg-purple-50 text-white border-2 border-white"
            : ""
        }`}
        onClick={() => billingRateSwitch("Annually")}
      >
        Yearly
      </button>
    </div>
  );
}

SubscriptionPlanSwitch.propTypes = {
  billingRateSwitch: PropTypes.func.isRequired,
  billingRate: PropTypes.string.isRequired,
};
export default SubscriptionPlanSwitch;
