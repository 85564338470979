import mime from "mime";

/**
 * Convert a data url to a file. Automatically creates file extension based on mime type of dataUrl
 * https://stackoverflow.com/a/38935990
 * @param {string} dataurl - Data URL For Image
 * @param {string} filename - Filename to assign to the file. Do not include file extension.
 * @returns {File}
 */

export default function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(",");
  const mimeType = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File(
    [u8arr],
    `${filename.split(".")[0]}.${mime.getExtension(mimeType)}`,
    {
      type: mimeType,
    }
  );
}
