import React from "react";
import PropTypes from "prop-types";
import Button from "../../../components/forms/Button";

export default function UpdateBankDetailsPrompt({ setBankValidation }) {
  return (
    <div className="relative w-full ">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className="w-full h-screen flex bg-blur" />
      <div className="relative bottom-0 flex flex-col justify-start items-center space-y-5 w-full px-4 pt-[1.7rem] pb-6 bg-white rounded-2xl">
        <p className="text-xl font-semibold">Action required!</p>
        <p className="text-base font-normal text-center">
          The request payment requires that you update your bank details.
        </p>
        <div className="flex flex-col space-y-3 w-full">
          <Button
            onClick={() => setBankValidation(false)}
            href="/account/my-bank-details"
            type="button"
            className="w-full h-12 text-black text-base font-semibold bg-primary-50 flex justify-center items-center"
          >
            Update bank details
          </Button>
        </div>
      </div>
    </div>
  );
}

UpdateBankDetailsPrompt.propTypes = {
  setBankValidation: PropTypes.func.isRequired,
};
