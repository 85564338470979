import React from "react";
import PropTypes from "prop-types";

export default function InputError({ error }) {
  if (!error) return null;
  return <div className="text-xs text-[#CD3717] mt-2 font-normal">{error}</div>;
}

InputError.propTypes = {
  error: PropTypes.string.isRequired,
};
