import React from "react";
import Layout from "./layout/V2/Layout";
import Button from "./forms/Button";

export default function ErrorComponent() {
  return (
    <Layout headerTitle="Error" header>
      <div className="py-28 h-screen">
        <div className="flex flex-col items-center justify-center h-full w-full">
          <p>Oops. Something went wrong!</p>
          <Button href="/" className="w-auto md mt-4">
            Go Home
          </Button>
        </div>
      </div>
    </Layout>
  );
}
