/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import Layout from "../../components/layout/V2/Layout";
import { useAuth } from "../../utils/Contexts/V2/AuthenticationContext";
import GoogleMap from "../../components/V2/GoogleMap";
import {
  updateMerchantDetails,
  getCurrentMerchant,
} from "../../utils/requests-v2";

export default function SetLocationPage() {
  const { user, updateUser } = useAuth();

  const [updatedLocation, setUpdatedLocation] = useState(null);

  const setLocationInfo = async (locationInfo) => {
    if (locationInfo) {
      const payload = {};
      payload.longitude = locationInfo.geometry.location.lng();
      payload.latitude = locationInfo.geometry.location.lat();
      payload.name = locationInfo.name;

      setUpdatedLocation(payload);
    }
  };

  const navigate = useNavigate();

  const [isUpdatingLocation, setIsUpdatingLocation] = useState(false);

  const submitUpdatedLocation = async () => {
    if (updatedLocation) {
      setIsUpdatingLocation(true);
      await updateMerchantDetails(user.id, { location_info: updatedLocation })
        .then(async () => {
          const merchantData = await getCurrentMerchant(user.id);

          updateUser(merchantData);
          setIsUpdatingLocation(false);

          toast.success("Location successfully updated");
          setTimeout(() => {
            navigate("/account/profile");
          }, 200);
        })
        .catch((error) => {
          setIsUpdatingLocation(false);
          console.error("Could not update location : ", error);
          toast.error("Could not update location. Please try again");
        });
    }
  };

  return (
    <Layout
      header
      headerTitle={
        <div className="flex items-center justify-end w-full">
          <button
            type="button"
            style={{
              background:
                "linear-gradient(90deg, #FFAD03 0%, #FE8005 65.1%, #FD5308 100%)",
              WebkitBackgroundClip: "text",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
              textFillColor: "transparent",
            }}
            onClick={submitUpdatedLocation}
            disabled={isUpdatingLocation}
          >
            Continue{" "}
            {isUpdatingLocation ? (
              <div
                className="spinner-border animate-spin inline-block w-4 h-4 border-2 rounded-full text-primary-50"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : null}
          </button>
        </div>
      }
      backTo="/account/profile"
    >
      <div className="pt-[58px]">
        <GoogleMap
          address={user.location_info.name}
          businessName={user.business_name}
          countryCode={user.country.country_code}
          lat={parseFloat(user.location_info.latitude) || 0}
          lng={parseFloat(user.location_info.longitude) || 0}
          size="small"
          setLocationInfo={setLocationInfo}
        />
      </div>
    </Layout>
  );
}
