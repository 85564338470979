import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Transition } from "@headlessui/react";
import Menu from "../Menu";
import ShareDialogue from "../ShareDialogue";

function Footer() {
  const [showMenu, setShowMenu] = useState(false);
  const { pathname } = useLocation();
  const [showDialogue, setShowDialogue] = useState(false);
  const [operation, setOperation] = useState(null);

  function active(name) {
    if (pathname === "/" && name === "home") {
      return "fill-purple-90 active-footer";
    }
    if (pathname.includes(name)) {
      return "fill-purple-90 active-footer";
    }
    return "fill-grey-40 footer-items-text";
  }

  const showDialogueHandler = (bool, type) => {
    setShowDialogue(bool);
    setOperation(type);
  };

  return (
    <footer
      className={`relative w-full flex flex-col justify-center items-center ${
        showMenu && "bg-blur h-screen"
      }`}
    >
      <Transition
        className="fixed -bottom-0 left-0 z-20 w-full"
        show={showDialogue}
        enterFrom=" translate-y-0"
        enterTo=" -translate-y-12"
        leaveFrom=" -translate-y-12"
        leaveTo=" translate-y-0"
      >
        <ShareDialogue
          operation={operation}
          showDialogueHandler={showDialogueHandler}
        />
      </Transition>
      <Transition
        className="fixed -bottom-12 left-0 z-20 w-full"
        show={showMenu}
        enterFrom=" translate-y-0"
        enterTo=" -translate-y-12"
        leaveFrom=" -translate-y-12"
        leaveTo=" translate-y-0"
      >
        <Menu close={setShowMenu} showDialogueHandler={showDialogueHandler} />
      </Transition>
      <div className="fixed z-10 p-1.5 bottom-[2.7rem] bg-white rounded-full bg-white flex justify-center items-center">
        <button
          type="button"
          className="w-[48px] h-[48px] flex justify-center items-center rounded-full bg-primary-50"
          style={{ height: "48px", width: "48px" }}
          onClick={() => setShowMenu(true)}
        >
          <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            className="fill-white thryve:fill-base h-[1.3rem] w-[1.3rem] object-contain"
          >
            <path d="M22.4429 13.1334H13.1338V22.4425H10.0308V13.1334H0.72168V10.0304H10.0308V0.721313H13.1338V10.0304H22.4429V13.1334Z" />
          </svg>
        </button>
      </div>
      <div className="fixed bottom-0 w-full pt-3 px-1 pb-4 flex flex-row justify-between items-center bg-white border-t-[0.09rem] border-[#E7E7E7] ">
        <NavLink to="/" className="footer-items">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={active("home")}
          >
            <path d="M22 9.999V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H13V9.999H22ZM11 15.999V21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V15.999H11ZM11 3V13.999H2V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H11ZM21 3C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V7.999H13V3H21Z" />
          </svg>
          <p className={active("home")}>Home</p>
        </NavLink>
        <div>
          <NavLink to="/ads" className={`${active("ads")} footer-items`}>
            <svg
              width="19"
              height="22"
              viewBox="0 0 19 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={active("ads")}
            >
              <path
                d="M8 13V21H0C0 18.8783 0.842855 16.8434 2.34315 15.3431C3.84344 13.8429 5.87827 13 8 13ZM8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12ZM17.446 19.032L18.95 20.536L17.536 21.95L16.032 20.446C15.1933 20.9405 14.2031 21.1121 13.247 20.9288C12.2908 20.7454 11.4343 20.2196 10.8381 19.4499C10.2419 18.6802 9.94688 17.7195 10.0083 16.7479C10.0698 15.7762 10.4835 14.8604 11.1719 14.1719C11.8604 13.4835 12.7762 13.0698 13.7479 13.0083C14.7195 12.9469 15.6802 13.2419 16.4499 13.8381C17.2196 14.4343 17.7454 15.2908 17.9288 16.247C18.1121 17.2031 17.9405 18.1933 17.446 19.032ZM14 19C14.5304 19 15.0391 18.7893 15.4142 18.4142C15.7893 18.0391 16 17.5304 16 17C16 16.4696 15.7893 15.9609 15.4142 15.5858C15.0391 15.2107 14.5304 15 14 15C13.4696 15 12.9609 15.2107 12.5858 15.5858C12.2107 15.9609 12 16.4696 12 17C12 17.5304 12.2107 18.0391 12.5858 18.4142C12.9609 18.7893 13.4696 19 14 19Z"
                fill={active("ads")}
              />
            </svg>
            <p className={active("ads")}>Ad Campaigns</p>
          </NavLink>
        </div>
        <div className="footer-items">
          <NavLink
            to="/customers"
            className={`${active("customers")} footer-items`}
          >
            <svg
              width="22"
              height="20"
              viewBox="0 0 22 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={active("customers")}
            >
              <path
                d="M13.121 8.47999C12.9334 8.29252 12.6791 8.1872 12.414 8.1872C12.1488 8.1872 11.8945 8.29252 11.707 8.47999L11 9.18599C10.8155 9.37701 10.5948 9.52937 10.3508 9.63419C10.1068 9.73901 9.84432 9.79418 9.57876 9.79649C9.3132 9.7988 9.04984 9.74819 8.80405 9.64763C8.55826 9.54707 8.33496 9.39856 8.14717 9.21078C7.95938 9.02299 7.81088 8.79969 7.71032 8.5539C7.60975 8.3081 7.55915 8.04474 7.56146 7.77919C7.56377 7.51363 7.61894 7.25119 7.72376 7.00718C7.82857 6.76317 7.98094 6.54248 8.17196 6.35799L13.802 0.725989C15.1349 0.422815 16.5295 0.547587 17.7874 1.08256C19.0453 1.61754 20.1026 2.53549 20.8088 3.70588C21.5151 4.87627 21.8344 6.23953 21.7213 7.60182C21.6082 8.9641 21.0685 10.2561 20.179 11.294L18.071 13.429L13.121 8.47899V8.47999ZM2.16096 2.46799C3.19572 1.43337 4.54918 0.777968 6.00252 0.607755C7.45586 0.437542 8.92414 0.762464 10.17 1.52999L6.75696 4.94399C6.01776 5.68172 5.59639 6.67929 5.5829 7.72355C5.5694 8.76781 5.96485 9.77594 6.68474 10.5325C7.40463 11.2891 8.39187 11.7341 9.43551 11.7725C10.4792 11.8109 11.4964 11.4396 12.27 10.738L12.414 10.601L16.657 14.843L12.414 19.086C12.0389 19.4609 11.5303 19.6716 11 19.6716C10.4696 19.6716 9.96102 19.4609 9.58596 19.086L2.15996 11.66C0.941092 10.441 0.256348 8.7878 0.256348 7.06399C0.256348 5.34018 0.941092 3.68696 2.15996 2.46799H2.16096Z"
                fill={active("customers")}
              />
            </svg>
            Customers
          </NavLink>
        </div>
        <div>
          <NavLink
            to="/account"
            className={`${active("account")} footer-items`}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={active("account")}
            >
              <path
                d="M11.625 0.375C5.20312 0.375 0 5.57812 0 12C0 18.4219 5.20312 23.625 11.625 23.625C18.0469 23.625 23.25 18.4219 23.25 12C23.25 5.57812 18.0469 0.375 11.625 0.375ZM11.625 4.875C13.9031 4.875 15.75 6.72188 15.75 9C15.75 11.2781 13.9031 13.125 11.625 13.125C9.34688 13.125 7.5 11.2781 7.5 9C7.5 6.72188 9.34688 4.875 11.625 4.875ZM11.625 21C8.87344 21 6.40781 19.7531 4.75781 17.8031C5.63906 16.1437 7.36406 15 9.375 15C9.4875 15 9.6 15.0188 9.70781 15.0516C10.3172 15.2484 10.9547 15.375 11.625 15.375C12.2953 15.375 12.9375 15.2484 13.5422 15.0516C13.65 15.0188 13.7625 15 13.875 15C15.8859 15 17.6109 16.1437 18.4922 17.8031C16.8422 19.7531 14.3766 21 11.625 21Z"
                fill={active("account")}
              />
            </svg>
            Account
          </NavLink>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
