import React, { createContext, useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import mpseaIcon from "../../assets/icons/mpsea.svg";
import promocodeIcon from "../../assets/icons/promocode.svg";
import { useAuth } from "./AuthenticationContext";

const PAYMENT_METHOD = [
  {
    name: "mpesa",
    text: "Pay with M-Pesa",
    span: "",
    logo: mpseaIcon,
    country: "Kenya",
  },
  {
    name: "promoCode",
    text: "Apply Promo code",
    span: "",
    logo: promocodeIcon,
    country: "Kenya",
  },
  {
    name: "promoCode",
    text: "Apply Promo code",
    span: "",
    logo: promocodeIcon,
    country: "Nigeria",
  },
];

const PaymentContext = createContext(null);

function usePaymentContext() {
  return useContext(PaymentContext);
}

function PaymentProvider({ children }) {
  const { user } = useAuth();
  const country = user?.country;
  const currency = user?.merchant.country_name.currency;
  const tathminiId = user?.merchant.tathmini_account_id;
  const msisdn = user?.phone_number;
  const [flutterwavePaymentDetails, setFlutterwavePaymentDetails] =
    useState(null);
  const [promoCode, setPromoCode] = useState(null);
  const [mpesaPayPhoneNumber, setMpesaPayPhoneNumber] = useState(null);
  const [inputError, setInputError] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState({
    mpesa: true,
    promoCode: false,
    flutter: false,
  });
  const [selectedMpesaPayment, setSelectedMpesaPayment] = useState({
    phone_number: false,
    pay_bill: false,
  });

  const selectedPaymentHandler = (e) => {
    if (e.target.id === "mpesa") {
      setSelectedPayment({ promoCode: false, mpesa: true, flutterwave: false });
    } else if (e.target.id === "flutterwave") {
      setSelectedPayment({ promoCode: false, mpesa: false, flutterwave: true });
    } else {
      setSelectedPayment({ promoCode: true, mpesa: false, flutterwave: false });
    }
  };

  const setPromoCodeHandler = (e) => {
    if (e.target.value) {
      setInputError(false);
      setPromoCode(e.target.value);
    } else {
      setInputError(true);
    }
  };

  const selectedMpesaPaymentHandler = (e) => {
    if (e.target.id === "phone_number") {
      setSelectedMpesaPayment({ phone_number: true, pay_bill: false });
    } else {
      setSelectedMpesaPayment({ phone_number: false, pay_bill: true });
    }
  };

  const mpesaPayPhoneNumberHandler = (e) => {
    if (e.target.value && e.target.value.length === 9) {
      setInputError(false);
      setMpesaPayPhoneNumber(`+254${e.target.value}`);
    } else {
      setInputError(true);
    }
  };

  const setReferenceNumberHandler = (e) => {
    if (e.target.value && e.target.value.length === 10) {
      setInputError(false);
      setReferenceNumber(e.target.value);
    } else {
      setInputError(true);
    }
  };

  const value = useMemo(() => ({
    PAYMENT_METHOD,
    country,
    currency,
    tathminiId,
    promoCode,
    flutterwavePaymentDetails,
    selectedPayment,
    setFlutterwavePaymentDetails,
    selectedMpesaPayment,
    mpesaPayPhoneNumber,
    msisdn,
    inputError,
    setInputError,
    referenceNumber,
    setReferenceNumberHandler,
    selectedPaymentHandler,
    setPromoCodeHandler,
    selectedMpesaPaymentHandler,
    mpesaPayPhoneNumberHandler,
  }));

  return (
    <PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>
  );
}

export { usePaymentContext, PaymentProvider };

PaymentProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
