import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Transition } from "@headlessui/react";
import Layout from "../components/layout/V2/Layout";
import { useSubscriptionContext } from "../utils/Contexts/V2/SubscriptionContext";
import Button from "../components/forms/Button";
import { useWalletContext } from "../utils/Contexts/V2/WalletContext";
import { usePaymentContext } from "../utils/Contexts/V2/PaymentContext";

function SubscriptionSelectPaymentMethod() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    currency,
    selectedMpesaPaymentHandler,
    selectedMpesaPayment,
    mpesaPayPhoneNumberHandler,
    inputError,
    tathminiId,
    setReferenceNumberHandler,
  } = usePaymentContext();
  const { mpesaPay, showError, confirmPaybillPaymentStatus } =
    pathname.includes("subscription")
      ? useSubscriptionContext()
      : useWalletContext();
  const { isLoadingWalletData, topUpAmount } = useWalletContext();
  const { isLoading, selectedPlan } = useSubscriptionContext();

  const loaderState = () => {
    if (pathname.includes("subscription")) {
      return isLoading;
    }
    return isLoadingWalletData;
  };

  return (
    <Layout header headerTitle="Select Payment Method">
      <div className="relative flex flex-col items-start justify-between w-full h-screen">
        <Transition
          className="fixed left-0 right-0 -bottom-8 z-20 w-full flex justify-center items-center "
          show={showError}
          enterFrom=" translate-y-0"
          enterTo=" -translate-y-12"
          leaveFrom=" -translate-y-12"
          leaveTo=" translate-y-0"
        >
          <p className="py-2.5 px-4 text-red-50 text-sm font-bold w-60 text-center bg-white/[.8] rounded-[6.25rem]">
            An error just occurred. Please try again
          </p>
        </Transition>
        <div className="flex flex-col items-center space-y-6 w-full px-4 pt-20 ">
          <div className="flex flex-col items-center justify-start space-y-2">
            <p className="text-sm font-medium">You are about to pay</p>
            <h1 className="text-xl font-semibold text-[#1A1A1A]">
              {currency}{" "}
              {pathname.includes("subscription")
                ? selectedPlan.amount
                : topUpAmount}
            </h1>
          </div>
          <div className="flex flex-col justify-start items-center space-y-4 w-full">
            <div
              className={`flex flex-col justify-start items-center w-full  space-y-3 ${
                selectedMpesaPayment.phone_number
                  ? "border-[0.089rem] border-purple-50"
                  : "border border-solid"
              }  py-6 px-4 rounded-[0.87rem]`}
            >
              <div className="flex flex-row items-center justify-between space-x-3.5 w-full">
                <p className="text-[0.9rem] font-semibold">
                  Enter phone number to pay
                </p>
                <input
                  className="form-check-input appearance-none rounded-full h-[1.4rem] w-[1.4rem] border border-gray-300 bg-white checked:bg-purple-50 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="radio"
                  name="flexRadioDefault"
                  id="phone_number"
                  onChange={selectedMpesaPaymentHandler}
                />
              </div>
              {selectedMpesaPayment.phone_number && (
                <div className="flex flex-col justify-start items-start w-full space-y-3">
                  <p className="pt-2 px-4 pb-3 bg-[#E7E7E7] text-[#566370] font-semibold text-[0.81rem]">
                    Enter your M-Pesa mobile number and click on the Confirm
                    button below.
                  </p>
                  <div
                    className={`flex flex-row justify-start items-center w-full py-5 px-4 border border-solid rounded-lg space-x-2 ${
                      inputError && "border-[0.099rem] border-red-50"
                    }`}
                  >
                    <p className="text-[#1A1A1A] font-semibold text-sm">+254</p>
                    <svg
                      width="1"
                      height="24"
                      viewBox="0 0 1 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="1" height="24" fill="#E7E7E7" />
                    </svg>
                    <input
                      className="h-full w-full outline-none text-[#566370] text-sm font-semibold"
                      type="number"
                      onBlur={mpesaPayPhoneNumberHandler}
                      maxLength={9}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              )}
            </div>
            <div
              className={`flex flex-col justify-start items-center w-full  space-y-3 ${
                selectedMpesaPayment.pay_bill
                  ? "border-[0.089rem] border-purple-50"
                  : "border border-solid"
              }  py-6 px-4 rounded-[0.87rem]`}
            >
              <div className="flex flex-row items-center justify-between space-x-3.5 w-full">
                <p className="text-[0.9rem] font-semibold">Pay via Paybill</p>
                <input
                  className="form-check-input appearance-none rounded-full h-[1.4rem] w-[1.4rem] border border-gray-300 bg-white checked:bg-purple-50 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="radio"
                  name="flexRadioDefault"
                  id="pay_bill"
                  onChange={selectedMpesaPaymentHandler}
                />
              </div>
              {selectedMpesaPayment.pay_bill && (
                <div className="flex flex-col justify-start items-start w-full space-y-3">
                  <p className="pt-2 px-4 pb-3 bg-[#E7E7E7] text-[#566370] font-semibold text-[0.81rem]">
                    Follow the steps below. Once you receive a successful reply
                    from Mpesa, enter the reference number and click on the
                    Confirm button below.
                  </p>
                  <div className="flex flex-row justify-start items-center w-full py-5 px-4 border border-solid space-x-2">
                    <ul className="h-full outline-none text-[#1A1A1A] text-sm font-normal list-disc ml-3 leading-6">
                      <li>Go to M-PESA on your phone</li>
                      <li>Select Pay Bill option</li>
                      <li>
                        Enter Business no. <b>4114987</b>
                      </li>
                      <li>
                        Enter Account no. <b>SUB{tathminiId}</b>
                      </li>
                      <li>
                        Enter the Amount.{" "}
                        <b>
                          KES{" "}
                          {pathname.includes("subscription")
                            ? selectedPlan.amount
                            : topUpAmount}
                        </b>
                      </li>
                      <li>Enter your M-PESA PIN and Send</li>
                      <li>You will receive a confirmation SMS from M-PESA</li>
                    </ul>
                  </div>
                  <div className="flex flex-col justify-start items-start w-full space-y-3">
                    <div
                      className={`flex flex-row justify-start items-center w-full py-5 px-4 border border-solid rounded-lg space-x-2 ${
                        inputError && "border-[0.099rem] border-red-50"
                      }`}
                    >
                      <p className="text-[#1A1A1A] font-semibold text-sm">
                        Ref no.
                      </p>
                      <svg
                        width="1"
                        height="24"
                        viewBox="0 0 1 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect width="1" height="24" fill="#E7E7E7" />
                      </svg>
                      <input
                        className="h-full w-[65%] outline-none text-[#566370] text-sm font-semibold "
                        type="text"
                        maxLength={10}
                        disabled={loaderState()}
                        onBlur={setReferenceNumberHandler}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="relative p-4 w-full shadow-[0_-2px_8px_rgba(0, 0, 0, 0.3)] border border-t-solid border-t-[#D1D9DD]">
          <Button
            className="w-full h-12 text-white text-base text-center font-semibold rounded-xl bg-purple-50 justify-center items-center"
            onClick={() =>
              selectedMpesaPayment.phone_number
                ? mpesaPay(navigate)
                : confirmPaybillPaymentStatus(navigate)
            }
          >
            {!loaderState() ? "Confirm" : "Processing payment..."}
          </Button>
        </div>
      </div>
    </Layout>
  );
}

export default SubscriptionSelectPaymentMethod;
